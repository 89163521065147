import * as THREE from "three";
import * as APP from "../../script";
import * as GAMESTATE from "../gamestate";
import { loadFile } from "../managers/loadingmanagers";
import * as SELECTIONMANAGER from "../managers/selectionmanager";

export function CreateMagicShape(index) {
  let model;
  if (index === 1) {
    const geometry = new THREE.BoxBufferGeometry(4, 4, 4);
    const material = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      transparent: true,
      alphaTest: 0.1,
      opacity: 0.1,
    });
    const cube = new THREE.Mesh(geometry, material);
    cube.position.set(-1000, -1000, -1000);
    model = cube;
  }
  if (index === 2) {
    const geometry = new THREE.SphereBufferGeometry(4, 32, 16);
    const material = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      transparent: true,
      alphaTest: 0.1,
      opacity: 0.1,
    });
    const cube = new THREE.Mesh(geometry, material);
    cube.position.set(-1000, -1000, -1000);
    model = cube;
  }
  model.name = "magic" + index;
  APP.scene.add(model);
  SELECTIONMANAGER.MoveSelectedObject(model, false, false);
  GAMESTATE.AddMagicToGame(model);
}

export function GetModel(modelID) {
  let assetPaths = [
    "./models/magic/cone_a.gltf",
    "./models/magic/cone_b.gltf",
    "./models/magic/cone_c.gltf",
    "./models/magic/square_a.gltf",
    "./models/magic/square_b.gltf",
    "./models/magic/square_c.gltf",
    "./models/magic/circle_a.gltf",
    "./models/magic/circle_b.gltf",
    "./models/magic/circle_c.gltf",
  ];

  for (let i = 0; i < assetPaths.length; i++) {
    if (assetPaths[i].includes(modelID)) {
      return assetPaths[i];
    }
  }
}

export async function GetModelMaterial(model) {
  let mat;
  let texturePaths = [
    "./models/magic/textures/square_a.png",
    "./models/magic/textures/square_b.jpg",
    "./models/magic/textures/square_c.jpg",
    "./models/magic/textures/T_ArcaneArrow.png",
    "./models/magic/textures/T_ArcaneShield.png",
    "./models/magic/textures/T_Fireball.png",
    "./models/magic/textures/T_HealingSwirl.png",
    "./models/magic/textures/T_IceSpikes.png",
    "./models/magic/textures/T_SnowRibbon.png",
  ];

  let tex;

  if (model.name === "cone_a") {
    tex = await loadFile(".jpg", texturePaths[5]);
  }
  if (model.name === "cone_b") {
    tex = await loadFile(".jpg", texturePaths[3]);
  }
  if (model.name === "cone_c") {
    tex = await loadFile(".jpg", texturePaths[7]);
  }
  if (model.name === "square_a") {
    tex = await loadFile(".jpg", texturePaths[0]);
  }
  if (model.name === "square_b") {
    tex = await loadFile(".jpg", texturePaths[1]);
  }
  if (model.name === "square_c") {
    tex = await loadFile(".jpg", texturePaths[2]);
  }
  if (model.name === "circle_a") {
    tex = await loadFile(".jpg", texturePaths[4]);
  }
  if (model.name === "circle_b") {
    tex = await loadFile(".jpg", texturePaths[6]);
  }
  if (model.name === "circle_c") {
    tex = await loadFile(".jpg", texturePaths[8]);
  }

  tex.flipY = false;
  tex.wrapS = THREE.RepeatWrapping;
  tex.wrapT = THREE.RepeatWrapping;
  tex.repeat.set(1, 1);

  mat = new THREE.MeshStandardMaterial({
    map: tex,
  });
  return mat;
}
