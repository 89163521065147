import * as ASSETLOADER from "../assetLoader";
import * as THREE from "three";
import { saveMap } from "../campaign";
export function LoadGameToTable(array, table) {
  for (let i = 0; i < array.length; i++) {
    let id = array[i].id;
    let modelType = ASSETLOADER.GetModelType(array[i].id);
    let position = new THREE.Vector3(array[i].x, array[i].y, array[i].z);
    let rotation = array[i].rot;
    let scale = array[i].scale;

    if (!modelType) {
      //Probably a custom model
      id = "./models/basic/triangle.gltf";
      modelType = "basic";
    }

    ASSETLOADER.LoadModel(
      id,
      modelType,
      position,
      rotation,
      scale,
      table,
      true,
      scale
    );
  }
}
