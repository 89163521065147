export function GetModel(modelID) {
  let assetPaths = [
    "./models/ships/rowing-boat.glb",
    "./models/ships/sailboat-b.glb",
    "./models/ships/pirate-ship.glb",
    "./models/ships/whale-ship.glb",
    "./models/ships/ghost-ship.glb",
  ];
  for (let i = 0; i < assetPaths.length; i++) {
    if (assetPaths[i].includes(modelID)) {
      return assetPaths[i];
    }
  }
}
