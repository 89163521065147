import * as NPC from "./randomnpc";

export function GetRandomCharacter() {
  return (
    " is a " +
    GetAlignment() +
    " " +
    GetRace() +
    " " +
    GetClass() +
    ", with a " +
    GetBackground() +
    " background. They " +
    NPC.GetNPCTrait() +
    ", " +
    NPC.GetNPCPersonality() +
    " and " +
    NPC.GetNPCQuirk() +
    "\n\n" +
    NPC.GetMainMotivation() +
    " " +
    NPC.CurrentDrivingForce() +
    " " +
    GetStats()
  );
}

function GetStats() {
  let lvl = GetRandomInt(1, 12);

  return (
    "\n\n Stats: Level " +
    lvl +
    ", STR: " +
    GetRandomInt(8, 16) +
    ", DEX: " +
    GetRandomInt(8, 16) +
    ",\nCON: " +
    GetRandomInt(8, 16) +
    ", WIS: " +
    GetRandomInt(8, 16) +
    ", INT: " +
    GetRandomInt(8, 16) +
    ", CHA: " +
    GetRandomInt(8, 16)
  );
}

function GetRace() {
  let races = [
    "dwarf",
    "elf",
    "halfling",
    "human",
    "gnome",
    "half-elf",
    "half-orc",
  ];

  return races[GetRandomInt(0, races.length - 1)];
}

function GetClass() {
  let classes = [
    "barbarian",
    "bard",
    "cleric",
    "druid",
    "fighter",
    "monk",
    "paladin",
    "ranger",
    "rogue",
    "sorcerer",
    "warlock",
    "wizard",
  ];

  return classes[GetRandomInt(0, classes.length - 1)];
}

function GetBackground() {
  let backgrounds = [
    "acolyte",
    "anthropologist",
    "archaeologist",
    "double agent",
    "legionnaire",
    "caravan guard",
    "charlatan",
    "city watch",
    "refugee",
    "courtier",
    "criminal",
    "dissenter",
    "miner",
    "entertainer",
    "faction agent",
    "failed merchant",
    "traveler",
    "gambler",
    "gladiator",
    "hermit",
    "smuggler",
    "inquisitor",
    "investigator",
    "bandit",
    "engineer",
    "knight",
    "mercenary",
    "veteran",
    "noble",
    "pirate",
    "sage",
    "sailor",
    "spy",
    "scientist",
    "prisoner",
    "nomad",
    "urchin",
    "bounty hunter",
    "tribe member",
  ];

  return backgrounds[GetRandomInt(0, backgrounds.length - 1)];
}

function GetAlignment() {
  let alignments = [
    "lawful good",
    "neutral good",
    "chaotic good",
    "lawful neutral",
    "true neutral",
    "chaotic neutral",
    "lawful evil",
    "neutral evil",
    "chaotic evil",
  ];

  return alignments[GetRandomInt(0, alignments.length - 1)];
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}