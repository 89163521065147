import * as THREE from "three";
import gsap from "gsap";
import { scene, camera } from "../../script";

let loader = new THREE.TextureLoader();
let rayParticles = [];
const rayTextures = ["./textures/effects/god-rays.webp"];
const sizeLimit = 15;
let colours = [
  new THREE.Color(0xe9fbff),
  new THREE.Color(0xe9fbff),
  new THREE.Color(0xe9fbff),
];
export const loadray = () => {
  let rayGeo = new THREE.PlaneBufferGeometry(25, 40);
  let rayMaterial = new THREE.MeshLambertMaterial({
    map: loader.load(rayTextures[0]),
    alphaTest: 0.001,
    transparent: true,
    color: 0xffffff,
  });

  for (let p = 0; p < 2; p++) {
    let ray = new THREE.Mesh(rayGeo, rayMaterial);
    ray.position.set(
      GetRandomInt(-20, 20),
      18,
      GetRandomInt(-20, 20)
      // Math.random() * 20,
      // Math.random() * 5,
      // Math.random() * 20
    );

    // ray.rotation.x = Math.random() * 5;
    // ray.rotation.y = Math.random();
    // ray.rotation.z = Math.random() * 360;
    ray.material.opacity = Math.random();
    scene.add(ray);
    rayParticles.push(ray);
    GoRandomPlace(ray);
  }
};
const tick = () => {
  if (rayParticles.length > 0) {
    rayParticles.forEach((p) => {
      p.rotation.z -= 0.002;
      p.lookAt(camera.position);
    });
  }

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const GoRandomPlace = (gobject) => {
  setTimeout(() => {
    let randX = gobject.position.x + GetRandomInt(-5, 5);
    let randY = gobject.position.y; //+ GetRandomInt(-5, 5);
    let randZ = gobject.position.z + GetRandomInt(-5, 5);

    let opacity = 1;

    let randRoll = GetRandomInt(0, 20);
    if (randRoll > 10) opacity = 1;
    if (gobject.position.x > sizeLimit || gobject.position.x < sizeLimit)
      randX = GetRandomInt(-sizeLimit, sizeLimit);
    if (gobject.position.z > sizeLimit || gobject.position.z < sizeLimit)
      randZ = GetRandomInt(-sizeLimit, sizeLimit);
    const duration = GetRandomInt(sizeLimit, sizeLimit * 2);
    gsap.to(gobject.position, {
      x: randX,
      y: randY,
      z: randZ,
      duration: duration,
      ease: "none",

      onComplete: () => {
        GoRandomPlace(gobject);
      },
    });
    // gsap.to(gobject.material, { opacity: opacity });
  }, GetRandomInt(1, 6));
};

export const removeRays = () => {
  for (let i = 0; i < rayParticles.length; i++) {
    scene.remove(rayParticles[i]);
  }
};
