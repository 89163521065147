export function GetModel(modelID) {
  let assetPaths = [
    "./models/figurines/painted/female_archer.glb",
    "./models/figurines/painted/orc_a.glb",
    "./models/figurines/painted/orc_b.glb",
    "./models/figurines/painted/orc_c.glb",
    "./models/figurines/painted/orc_d.glb",
    "./models/figurines/painted/orc_e.glb",
    "./models/figurines/painted/dwarf_a.glb",
    "./models/figurines/painted/knight_a.glb",
    "./models/figurines/painted/knight_b.glb",
    "./models/figurines/painted/demon_a.glb",
    "./models/figurines/painted/red_knight_a.glb",
    "./models/figurines/painted/barbarian_a.glb",
    "./models/figurines/painted/old_knight_a.glb",
    "./models/figurines/painted/urakai_a.glb",
    "./models/figurines/painted/urakai_b.glb",
    "./models/figurines/painted/urakai_c.glb",
    "./models/figurines/painted/urakai_d.glb",
    "./models/figurines/painted/urakai_e.glb",
    "./models/figurines/painted/goblin_a.glb",
    "./models/figurines/painted/goblin_b.glb",
    "./models/figurines/painted/goblin_c.glb",
    "./models/figurines/painted/goblin_d.glb",
    "./models/figurines/painted/goblin_e.glb",
    "./models/figurines/painted/bear_a.glb",
    "./models/figurines/painted/bat_a.glb",
    "./models/figurines/painted/boar_a.glb",
    "./models/figurines/painted/cat_a.glb",
    "./models/figurines/painted/chicken_a.glb",
    "./models/figurines/painted/cow_a.glb",
    "./models/figurines/painted/crocodile_a.glb",
    "./models/figurines/painted/eagle_a.glb",
    "./models/figurines/painted/goat_a.glb",
    "./models/figurines/painted/wolf_a.glb",
    "./models/figurines/painted/ent_a.glb",
    "./models/figurines/painted/black_dragon.glb",
    "./models/figurines/painted/soldier_a.glb",
    "./models/figurines/painted/soldier_b.glb",
    "./models/figurines/painted/soldier_c.glb",
    "./models/figurines/painted/soldier_d.glb",
  ];
  for (let i = 0; i < assetPaths.length; i++) {
    if (assetPaths[i].includes(modelID)) {
      return assetPaths[i];
    }
  }
}
