export function GetModel(modelID) {
  return [
    `barrel1.glb`,
    `barrel2.glb`,
    `crate1.glb`,
    `crate4.glb`,
    `crate5.glb`,
    `hearth1.glb`,
    `stool1.glb`,
    `table1.glb`,
    `table2.glb`,
    `table3.glb`,
    `table4.glb`,
    `tavernbox1.glb`,
    `tavernbox2.glb`,
    `tavernbox3.glb`,
    `ground1.glb`,
    `ground2.glb`,
    `ground3.glb`,
    `ground4.glb`,
    `ground5.glb`,
    `trapdoor1a.glb`,
    `Wall1a.glb`,
    `Wall1b.glb`,
    `Wall1c.glb`,
    `Wall2a.glb`,
    `Wall2b.glb`,
    `Wall2c.glb`,
    `Wall3a.glb`,
    `Wall3b.glb`,
    `Wall3c.glb`,
    `room1.glb`,
    `room2.glb`,
    `room3.glb`,
    `room4.glb`,
    `cellar1.glb`,
    `dining1.glb`,
    `landing1.glb`,
    `reception1.glb`,
    `tavern1.glb`,
  ]
    .map((item) => `./models/topdowninteriors/${item}`)
    .find((item) => item.includes(modelID));
}
