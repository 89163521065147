import * as THREE from "three";


export function GetModel(modelID) {
    
    let assetPaths = [
        "./models/basic/cube.gltf",
        "./models/basic/sphere.gltf",
        "./models/basic/triangle.gltf",
    ];
    for (let i = 0; i < assetPaths.length; i++) {
        if (assetPaths[i].includes(modelID)) {
            
            return assetPaths[i];
        }
    }
}

export function GetModelMaterial(model) {
    let color = new THREE.Color(0xffffff);
    color.setHex(Math.random() * 0xffffff);
    return new THREE.MeshStandardMaterial({
        color: color
    })
}