export function setUpDonateUI() {
  const donateWindow = document.getElementsByClassName("donate-page")[0];
  const donateButton = document.getElementsByClassName("donateButton")[0];

  const donateClose = document.getElementsByClassName("donate-back-button")[0];

  donateWindow.style.display = "none";

  donateButton.addEventListener("click", () => {
    donateWindow.style.display = "block";
    // document.body.style.overflowY = "scroll";
  });

  donateClose.addEventListener("click", () => {
    donateWindow.style.display = "none";
    document.body.style.overflow = "hidden";
  });
}
$(".meter > span").each(function () {
  $(this)
    .data("origWidth", $(this).width())
    .width(100)
    .animate(
      {
        width: $(this).data("origWidth"),
      },
      1200
    );
});
