import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { getRandomString } from "../../utils/getRandomString";
import { _getAuth } from "./firebaseConfig";
import {
  ref,
  set,
  get,
  push,
  onDisconnect,
  child,
  onValue,
  serverTimestamp,
  orderByChild,
  once,
  endAt,
  limitToLast,
  query,
} from "firebase/database";
import { getDB } from "./firebaseConfig";
import { getGameID } from "./roomData";
import { getUserData, setUserData } from "./userData";
import { showChatMessage } from "./chatbox";
import { GetAllActiveObjects } from "../gamestate";
import { getSun } from "../lights";
import { SetUpListener } from "./listenToData";

let numberOfUsers = 0;

export async function signInAnon(
  randPlayerID,
  username,
  avatarPath,
  gameId,
  color
) {
  signInAnonymously(_getAuth())
    .then(() => {
      // Signed in..

      onAuthStateChanged(_getAuth(), (user) => {
        if (user) {
          set(ref(getDB(), gameId + "/game/users/" + username), {
            playerID: randPlayerID,
            username: username,
            avatarPath: avatarPath,
            roomID: gameId,
            color: color,
            uid: user.uid,
            connectionStatus: "connected",
          });

          set(ref(getDB(), gameId + "/game/gameData"), { gameID: gameId });
          set(ref(getDB(), gameId + "/game/dice"), "");
          set(ref(getDB(), gameId + "/game/sun/"), getSun());
          let allObjects = GetAllActiveObjects();

          for (let i = 0; i < allObjects.length; i++) {
            let id = allObjects[i].userData.id;
            let position = allObjects[i].position;
            let rotation = allObjects[i].rotation;
            let scale = allObjects[i].scale;

            set(ref(getDB(), gameId + "/game/map/" + id), {
              id: id,
              name: allObjects[i].name,
              posX: Math.round(position.x * 100) / 100,
              posY: Math.round(position.y * 100) / 100,
              posZ: Math.round(position.z * 100) / 100,
              rotY: Math.round(rotation.y * 100) / 100,
              scaleY: Math.round(scale.y * 100) / 100,
            });
          }

          const userList = ref(getDB(), getGameID() + "/game/users");

          onValue(userList, (snapshot) => {
            const data = snapshot.val();

            if (data) {
              numberOfUsers = Object.keys(data).length;

              showChatMessage("There are " + numberOfUsers + " users");
            } else {
              numberOfUsers = 0;
            }
          });

          const presenceRef = ref(getDB(), gameId + "/game/users/" + username);
          onDisconnect(presenceRef).set(null);
          setUserData(username, randPlayerID, avatarPath, gameId, color);
          SetUpListener();
          return user; //<< this in undefined
        } else {
          // User is signed out
          // ...
        }
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });

  get(ref(getDB()))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const gameData = [];
        Object.values(data).forEach((val) => gameData.push(val));

        if (gameData.length > 0) {
          gameData.forEach((element) => {
            if (element.game.gameData) {
              if (
                !element.game.users &&
                element.game.gameData.gameID != gameId
              ) {
                set(ref(getDB(), element.game.gameData.gameID), null);
              }

              const now = Date.now();
              var cutoff = now - 2 * 60 * 60 * 1000;
              if (element.timestamp < cutoff) {
                set(ref(getDB(), element.game.gameData.gameID), null);
              }
            }
          });
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });

  const gameRef = ref(getDB(), gameId + "/timestamp");
  const updateTimestamp = await set(gameRef, serverTimestamp());
}
