import { loadFile } from "./managers/loadingmanagers";
import * as THREE from "three";
import * as APP from "../script";
import * as TREES from "./assets/trees";
import * as ROCKS from "./assets/rocks";
import * as ANIMATIONS from "./animations";
import * as GAMESTATE from "./gamestate";
import * as SELETIONMANAGER from "./managers/selectionmanager";
import { InAMultiplayerSession } from "./multiplayer/status";
let objectPool = [];
let loadedObjectLibrary = [];
export let paintTreesEnabled = false;
export let paintTreesTriggered = false;
let currentModelType;
let interval = 0;

export function PaintTrees(location) {
  interval += 0.3;

  if (interval >= 10) {
    let newTree = objectPool[GetRandomInt(0, objectPool.length - 1)].clone();
    newTree.userData.id = (Math.random() + 1).toString(36).substring(2);
    newTree.userData.cardTitle = "none";
    newTree.userData.cardBody = "none";
    newTree.userData.cardImage = "none";
    newTree.userData.cardColor = "none";
    newTree.userData.cardType = "none";
    APP.scene.add(newTree);

    newTree.position.set(
      location.x + GetRandomFloat(-1, 1),
      location.y,
      location.z + GetRandomFloat(-1, 1)
    );

    let currentlySelectedObject;
    if (SELETIONMANAGER.selectedObject != null) {
      currentlySelectedObject = SELETIONMANAGER.selectedObject;
    }

    if (currentModelType == "plantTrees") {
      if (!InAMultiplayerSession()) {
        ANIMATIONS.GrowaAnimation(
          newTree,
          currentlySelectedObject.scale.x + GetRandomFloat(-0.2, 0.2)
        );
      }

      GAMESTATE.AddTreeToGame(newTree);
      TREES.SetModelMaterial(newTree);
    }

    if (currentModelType == "rocks") {
      if (!InAMultiplayerSession()) {
        ANIMATIONS.GrowaAnimation(
          newTree,
          currentlySelectedObject.scale.x + GetRandomFloat(-0.3, 0.3)
        );
      }

      GAMESTATE.AddRockToGame(newTree);
    }

    newTree.rotation.y = GetRandomFloat(0, 3);

    interval = 0;
  }
}
export function EnablePainting() {
  paintTreesEnabled = true;
  APP.DisableControls();
  //tick();
}

export function DisablePainting() {
  paintTreesEnabled = false;
  paintTreesTriggered = false;
  APP.EnableControls();
}

export function FillObjectPool(objectPoolSize, modelType) {
  objectPool = [];

  currentModelType = modelType;
  if (objectPool.length < 10) {
    for (let i = 0; i < objectPoolSize; i++) {
      PutRandomObjectInPool(modelType);
    }
  }

  setTimeout(() => {
    paintTreesTriggered = true;
  }, 500);
}

async function PutRandomObjectInPool(modelType) {
  let selectedURL;
  if (modelType === "plantTrees")
    selectedURL = TREES.GetPaintingModel("random");
  if (modelType === "rocks") selectedURL = ROCKS.GetModel("random");

  let newClone = await loadFile(".gltf", selectedURL);

  newClone.scene.traverse(function (child) {
    if (child.isMesh) {
      child.castShadow = true; //default is false
      child.receiveShadow = true;
      if (modelType === "plantTrees") TREES.SetModelMaterial(child);
    }
  });

  newClone.scene.name = selectedURL;
  loadedObjectLibrary.push(newClone.scene);
  objectPool.push(newClone.scene.clone());
}

function GetRandomFloat(min, max) {
  if (max == null) {
    max = min == null ? Number.MAX_VALUE : min;
    min = 0.0;
  }

  if (min >= max) {
    throw new Error("Incorrect arguments.");
  }

  return min + (max - min) * randomizeValue();
}

function randomizeValue() {
  var value = (1 + 10e-16) * Math.random();

  if (value > 1.0) {
    return 1.0;
  }

  return value;
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
