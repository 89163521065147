import * as THREE from "three";
import gsap from "gsap";
import * as GAMESTATE from "../gamestate";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { LoadTable } from "../assets/scenetables";
import { LoadMap } from "../premades/premades";
import { LoadingManager } from "three";

const manager = new THREE.LoadingManager();

let loadIndex = 0;
manager.onStart = function (url, itemsLoaded, itemsTotal) {
  //
  //   "Started loading file: " +
  //     url +
  //     ".\nLoaded " +
  //     itemsLoaded +
  //     " of " +
  //     itemsTotal +
  //     " files."
  // );
  document.body.style.cursor = "wait";
};

manager.onLoad = function () {
  document.body.style.cursor = "default";
};

manager.onProgress = async function (url, itemsLoaded, itemsTotal) {
  // // loadIndex++;
  // // console.log(loadIndex);
  // // if ((loadIndex / 10) * 100 == 100) {
  // //   gsap.to(document.getElementById("loader-fill"), {
  // //     scaleY: loadIndex / 38,
  // //     onComplete: async () => {
  // //       // const table = await LoadTable("wizard", new THREE.Vector3(0, 0, 0));
  // //       // LoadMap(0);
  // //     },
  // //   });
  // }
};
gsap.set(document.getElementById("loader-fill"), {
  scaleY: 0,
});
gsap.to(document.getElementById("loader-fill"), {
  scaleY: 1,
  duration: 2,
  onComplete: async () => {
    const table = await LoadTable("wizard", new THREE.Vector3(0, 0, 0));
    LoadMap(0);
  },
});
manager.onError = function (url) {
  console.log("There was an error loading " + url);
};

const gltfLoader = new GLTFLoader(manager);
const textureLoader = new THREE.TextureLoader(manager);
const fbxLoader = new FBXLoader(manager);

export async function loadFile(fileType, path) {
  let loader = gltfLoader;
  if (fileType === ".fbx") loader = fbxLoader;
  if (
    fileType === ".png" ||
    fileType === ".png" ||
    fileType === ".jpeg" ||
    fileType === ".jpg" ||
    fileType === ".webp"
  )
    loader = textureLoader;

  return new Promise((resolve) => {
    loader.load(path, (file) => resolve(file));
  });
}
