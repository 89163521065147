export function GetMaleAvatar() {
  const maleAvatars = MaleAvatars();
  return maleAvatars[GetRandomInt(0, maleAvatars.length - 1)];
}

export function GetFemaleAvatar() {
  const femaleAvatars = FemaleAvatars();
  return femaleAvatars[GetRandomInt(0, femaleAvatars.length - 1)];
}

export function GetRandomAvatar() {
  const random = GetRandomInt(0, 1);
  if (random === 0) {
    return GetMaleAvatar();
  } else {
    return GetFemaleAvatar();
  }
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function MaleAvatars() {
  let maleAvatars = [
    "/textures/ui/cards/avatars/Gigant_02_old_titan.webp",
    "/textures/ui/cards/avatars/Gigant_03_yeti.webp",
    "/textures/ui/cards/avatars/Gigant_07_ogre_mage.webp",
    "/textures/ui/cards/avatars/Human_01.webp",
    "/textures/ui/cards/avatars/Human_01_archer.webp",
    "/textures/ui/cards/avatars/Human_03_cavalryman.webp",
    "/textures/ui/cards/avatars/Human_04_knight.webp",
    "/textures/ui/cards/avatars/Human_06_Priest.webp",
    "/textures/ui/cards/avatars/Human_07_girl.webp",
    "/textures/ui/cards/avatars/Human_09.webp",
    "/textures/ui/cards/avatars/Human_09_tamplier.webp",
    "/textures/ui/cards/avatars/Human_10.webp",
    "/textures/ui/cards/avatars/Human_11_lord.webp",
    "/textures/ui/cards/avatars/Human_14_knight.webp",
    "/textures/ui/cards/avatars/Human_16.webp",
    "/textures/ui/cards/avatars/Human_17_archer.webp",
    "/textures/ui/cards/avatars/Human_18_Heavycavalry.webp",
    "/textures/ui/cards/avatars/Human_19.webp",
    "/textures/ui/cards/avatars/Human_19_Jarl.webp",
    "/textures/ui/cards/avatars/Human_21.webp",
    "/textures/ui/cards/avatars/Human_22.webp",
    "/textures/ui/cards/avatars/Human_22_shinobi.webp",
    "/textures/ui/cards/avatars/Human_23.webp",
    "/textures/ui/cards/avatars/Human_23_rogue.webp",
    "/textures/ui/cards/avatars/Human_24.webp",
    "/textures/ui/cards/avatars/Human_24_ronin.webp",
    "/textures/ui/cards/avatars/Human_25.webp",
    "/textures/ui/cards/avatars/Human_25_barbarian.webp",
    "/textures/ui/cards/avatars/Human_26.webp",
    "/textures/ui/cards/avatars/Human_26_barbarian.webp",
    "/textures/ui/cards/avatars/Human_27.webp",
    "/textures/ui/cards/avatars/Human_27_alchemyst.webp",
    "/textures/ui/cards/avatars/Human_28.webp",
    "/textures/ui/cards/avatars/Human_28_thug.webp",
    "/textures/ui/cards/avatars/Human_29.webp",
    "/textures/ui/cards/avatars/Human_31.webp",
    "/textures/ui/cards/avatars/Human_32.webp",
    "/textures/ui/cards/avatars/Human_32_scout.webp",
    "/textures/ui/cards/avatars/Human_33.webp",
    "/textures/ui/cards/avatars/Human_33_warrior.webp",
    "/textures/ui/cards/avatars/Human_34.webp",
    "/textures/ui/cards/avatars/Human_34_Javelinmen.webp",
    "/textures/ui/cards/avatars/Human_35.webp",
    "/textures/ui/cards/avatars/Human_35_Halberdier.webp",
    "/textures/ui/cards/avatars/Human_36.webp",
    "/textures/ui/cards/avatars/Human_36_Slayer_of_knights.webp",
    "/textures/ui/cards/avatars/Human_37.webp",
    "/textures/ui/cards/avatars/Human_38.webp",
    "/textures/ui/cards/avatars/Human_40.webp",
    "/textures/ui/cards/avatars/Human_40_aztec_shaman.webp",
    "/textures/ui/cards/avatars/Human_41.webp",
    "/textures/ui/cards/avatars/Human_41_conquistador.webp",
    "/textures/ui/cards/avatars/Human_42.webp",
    "/textures/ui/cards/avatars/Human_43.webp",
    "/textures/ui/cards/avatars/Human_44.webp",
    "/textures/ui/cards/avatars/Human_45.webp",
    "/textures/ui/cards/avatars/Human_46.webp",
    "/textures/ui/cards/avatars/Human_46_sage.webp",
    "/textures/ui/cards/avatars/Human_47_convict.webp",
    "/textures/ui/cards/avatars/Human_49.webp",
    "/textures/ui/cards/avatars/Human_49_mangudai.webp",
    "/textures/ui/cards/avatars/Human_53.webp",
    "/textures/ui/cards/avatars/Human_54.webp",
    "/textures/ui/cards/avatars/Human_55.webp",
    "/textures/ui/cards/avatars/Human_57.webp",
    "/textures/ui/cards/avatars/Human_58.webp",
    "/textures/ui/cards/avatars/Human_60.webp",
    "/textures/ui/cards/avatars/Human_61.webp",
    "/textures/ui/cards/avatars/Human_62.webp",
    "/textures/ui/cards/avatars/Human_64.webp",
    "/textures/ui/cards/avatars/Human_66.webp",
  ];

  return maleAvatars;
}

export function FemaleAvatars() {
  let femaleAvatars = [
    "/textures/ui/cards/avatars/Elf_01.webp",
    "/textures/ui/cards/avatars/Elf_02.webp",
    "/textures/ui/cards/avatars/Elf_03.webp",
    "/textures/ui/cards/avatars/Elf_04_hunter.webp",
    "/textures/ui/cards/avatars/Human_02.webp",
    "/textures/ui/cards/avatars/Human_02_archer.webp",
    "/textures/ui/cards/avatars/Human_03.webp",
    "/textures/ui/cards/avatars/Human_04.webp",
    "/textures/ui/cards/avatars/Human_05.webp",
    "/textures/ui/cards/avatars/Human_05_woman_knight.webp",
    "/textures/ui/cards/avatars/Human_06.webp",
    "/textures/ui/cards/avatars/Human_07.webp",
    "/textures/ui/cards/avatars/Human_08.webp",
    "/textures/ui/cards/avatars/Human_07_girl.webp",
    "/textures/ui/cards/avatars/Human_08_warrior.webp",
    "/textures/ui/cards/avatars/Human_10_boy.webp",
    "/textures/ui/cards/avatars/Human_11.webp",
    "/textures/ui/cards/avatars/Human_12.webp",
    "/textures/ui/cards/avatars/Human_12_lord.webp",
    "/textures/ui/cards/avatars/Human_13.webp",
    "/textures/ui/cards/avatars/Human_13_capitan.webp",
    "/textures/ui/cards/avatars/Human_14.webp",
    "/textures/ui/cards/avatars/Human_15.webp",
    "/textures/ui/cards/avatars/Human_15_woman.webp",
    "/textures/ui/cards/avatars/Human_16_girl.webp",
    "/textures/ui/cards/avatars/Human_17.webp",
    "/textures/ui/cards/avatars/Human_18.webp",
    "/textures/ui/cards/avatars/Human_20.webp",
    "/textures/ui/cards/avatars/Human_30.webp",
    "/textures/ui/cards/avatars/Human_30_witch.webp",
    "/textures/ui/cards/avatars/Human_31_witch.webp",
    "/textures/ui/cards/avatars/Human_39.webp",
    "/textures/ui/cards/avatars/Human_42_queen.webp",
    "/textures/ui/cards/avatars/Human_43_queen.webp",
    "/textures/ui/cards/avatars/Human_47.webp",
    "/textures/ui/cards/avatars/Human_48.webp",
    "/textures/ui/cards/avatars/Human_48_priest.webp",
    "/textures/ui/cards/avatars/Human_50.webp",
    "/textures/ui/cards/avatars/Human_50_amazon_warrior.webp",
    "/textures/ui/cards/avatars/Human_51.webp",
    "/textures/ui/cards/avatars/Human_52.webp",
    "/textures/ui/cards/avatars/Human_56.webp",
    "/textures/ui/cards/avatars/Human_59.webp",
    "/textures/ui/cards/avatars/Human_65.webp",
  ];
  return femaleAvatars;
}

export const getAllAvatars = () => {
  let avatars = [...MaleAvatars(), ...FemaleAvatars()];
  return avatars;
};

export const getSpecifcAvatar = (url) => {
  const allImages = [
    "/textures/ui/cards/avatars/Gigant_02_old_titan.webp",
    "/textures/ui/cards/avatars/Gigant_03_yeti.webp",
    "/textures/ui/cards/avatars/Gigant_07_ogre_mage.webp",
    "/textures/ui/cards/avatars/Human_01.webp",
    "/textures/ui/cards/avatars/Human_01_archer.webp",
    "/textures/ui/cards/avatars/Human_03_cavalryman.webp",
    "/textures/ui/cards/avatars/Human_04_knight.webp",
    "/textures/ui/cards/avatars/Human_06_Priest.webp",
    "/textures/ui/cards/avatars/Human_07_girl.webp",
    "/textures/ui/cards/avatars/Human_09.webp",
    "/textures/ui/cards/avatars/Human_09_tamplier.webp",
    "/textures/ui/cards/avatars/Human_10.webp",
    "/textures/ui/cards/avatars/Human_11_lord.webp",
    "/textures/ui/cards/avatars/Human_14_knight.webp",
    "/textures/ui/cards/avatars/Human_16.webp",
    "/textures/ui/cards/avatars/Human_17_archer.webp",
    "/textures/ui/cards/avatars/Human_18_Heavycavalry.webp",
    "/textures/ui/cards/avatars/Human_19.webp",
    "/textures/ui/cards/avatars/Human_19_Jarl.webp",
    "/textures/ui/cards/avatars/Human_21.webp",
    "/textures/ui/cards/avatars/Human_22.webp",
    "/textures/ui/cards/avatars/Human_22_shinobi.webp",
    "/textures/ui/cards/avatars/Human_23.webp",
    "/textures/ui/cards/avatars/Human_23_rogue.webp",
    "/textures/ui/cards/avatars/Human_24.webp",
    "/textures/ui/cards/avatars/Human_24_ronin.webp",
    "/textures/ui/cards/avatars/Human_25.webp",
    "/textures/ui/cards/avatars/Human_25_barbarian.webp",
    "/textures/ui/cards/avatars/Human_26.webp",
    "/textures/ui/cards/avatars/Human_26_barbarian.webp",
    "/textures/ui/cards/avatars/Human_27.webp",
    "/textures/ui/cards/avatars/Human_27_alchemyst.webp",
    "/textures/ui/cards/avatars/Human_28.webp",
    "/textures/ui/cards/avatars/Human_28_thug.webp",
    "/textures/ui/cards/avatars/Human_29.webp",
    "/textures/ui/cards/avatars/Human_31.webp",
    "/textures/ui/cards/avatars/Human_32.webp",
    "/textures/ui/cards/avatars/Human_32_scout.webp",
    "/textures/ui/cards/avatars/Human_33.webp",
    "/textures/ui/cards/avatars/Human_33_warrior.webp",
    "/textures/ui/cards/avatars/Human_34.webp",
    "/textures/ui/cards/avatars/Human_34_Javelinmen.webp",
    "/textures/ui/cards/avatars/Human_35.webp",
    "/textures/ui/cards/avatars/Human_35_Halberdier.webp",
    "/textures/ui/cards/avatars/Human_36.webp",
    "/textures/ui/cards/avatars/Human_36_Slayer_of_knights.webp",
    "/textures/ui/cards/avatars/Human_37.webp",
    "/textures/ui/cards/avatars/Human_38.webp",
    "/textures/ui/cards/avatars/Human_40.webp",
    "/textures/ui/cards/avatars/Human_40_aztec_shaman.webp",
    "/textures/ui/cards/avatars/Human_41.webp",
    "/textures/ui/cards/avatars/Human_41_conquistador.webp",
    "/textures/ui/cards/avatars/Human_42.webp",
    "/textures/ui/cards/avatars/Human_43.webp",
    "/textures/ui/cards/avatars/Human_44.webp",
    "/textures/ui/cards/avatars/Human_45.webp",
    "/textures/ui/cards/avatars/Human_46.webp",
    "/textures/ui/cards/avatars/Human_46_sage.webp",
    "/textures/ui/cards/avatars/Human_47_convict.webp",
    "/textures/ui/cards/avatars/Human_49.webp",
    "/textures/ui/cards/avatars/Human_49_mangudai.webp",
    "/textures/ui/cards/avatars/Human_53.webp",
    "/textures/ui/cards/avatars/Human_54.webp",
    "/textures/ui/cards/avatars/Human_55.webp",
    "/textures/ui/cards/avatars/Human_57.webp",
    "/textures/ui/cards/avatars/Human_58.webp",
    "/textures/ui/cards/avatars/Human_60.webp",
    "/textures/ui/cards/avatars/Human_61.webp",
    "/textures/ui/cards/avatars/Human_62.webp",
    "/textures/ui/cards/avatars/Human_64.webp",
    "/textures/ui/cards/avatars/Human_66.webp",
    "/textures/ui/cards/avatars/Elf_01.webp",
    "/textures/ui/cards/avatars/Elf_02.webp",
    "/textures/ui/cards/avatars/Elf_03.webp",
    "/textures/ui/cards/avatars/Elf_04_hunter.webp",
    "/textures/ui/cards/avatars/Human_02.webp",
    "/textures/ui/cards/avatars/Human_02_archer.webp",
    "/textures/ui/cards/avatars/Human_03.webp",
    "/textures/ui/cards/avatars/Human_04.webp",
    "/textures/ui/cards/avatars/Human_05.webp",
    "/textures/ui/cards/avatars/Human_05_woman_knight.webp",
    "/textures/ui/cards/avatars/Human_06.webp",
    "/textures/ui/cards/avatars/Human_07.webp",
    "/textures/ui/cards/avatars/Human_08.webp",
    "/textures/ui/cards/avatars/Human_07_girl.webp",
    "/textures/ui/cards/avatars/Human_08_warrior.webp",
    "/textures/ui/cards/avatars/Human_10_boy.webp",
    "/textures/ui/cards/avatars/Human_11.webp",
    "/textures/ui/cards/avatars/Human_12.webp",
    "/textures/ui/cards/avatars/Human_12_lord.webp",
    "/textures/ui/cards/avatars/Human_13.webp",
    "/textures/ui/cards/avatars/Human_13_capitan.webp",
    "/textures/ui/cards/avatars/Human_14.webp",
    "/textures/ui/cards/avatars/Human_15.webp",
    "/textures/ui/cards/avatars/Human_15_woman.webp",
    "/textures/ui/cards/avatars/Human_16_girl.webp",
    "/textures/ui/cards/avatars/Human_17.webp",
    "/textures/ui/cards/avatars/Human_18.webp",
    "/textures/ui/cards/avatars/Human_20.webp",
    "/textures/ui/cards/avatars/Human_30.webp",
    "/textures/ui/cards/avatars/Human_30_witch.webp",
    "/textures/ui/cards/avatars/Human_31_witch.webp",
    "/textures/ui/cards/avatars/Human_39.webp",
    "/textures/ui/cards/avatars/Human_42_queen.webp",
    "/textures/ui/cards/avatars/Human_43_queen.webp",
    "/textures/ui/cards/avatars/Human_47.webp",
    "/textures/ui/cards/avatars/Human_48.webp",
    "/textures/ui/cards/avatars/Human_48_priest.webp",
    "/textures/ui/cards/avatars/Human_50.webp",
    "/textures/ui/cards/avatars/Human_50_amazon_warrior.webp",
    "/textures/ui/cards/avatars/Human_51.webp",
    "/textures/ui/cards/avatars/Human_52.webp",
    "/textures/ui/cards/avatars/Human_56.webp",
    "/textures/ui/cards/avatars/Human_59.webp",
    "/textures/ui/cards/avatars/Human_65.webp",
    "/textures/ui/cards/landscapes/naturea.webp",
    "/textures/ui/cards/landscapes/natureb.webp",
    "/textures/ui/cards/landscapes/naturec.webp",
    "/textures/ui/cards/landscapes/natured.webp",
    "/textures/ui/cards/landscapes/naturee.webp",
    "/textures/ui/cards/landscapes/naturef.webp",
    "/textures/ui/cards/landscapes/natureg.webp",
    "/textures/ui/cards/landscapes/natureh.webp",
    "/textures/ui/cards/landscapes/naturei.webp",
    "/textures/ui/cards/landscapes/naturej.webp",
    "/textures/ui/cards/landscapes/naturek.webp",
    "/textures/ui/cards/landscapes/naturel.webp",
    "/textures/ui/cards/landscapes/naturem.webp",
    "/textures/ui/cards/landscapes/naturen.webp",
    "/textures/ui/cards/landscapes/natureo.webp",
    "/textures/ui/cards/landscapes/naturep.webp",
    "/textures/ui/cards/landscapes/natureq.webp",
    "/textures/ui/cards/landscapes/towna.webp",
    "/textures/ui/cards/landscapes/townb.webp",
    "/textures/ui/cards/landscapes/townc.webp",
    "/textures/ui/cards/landscapes/townd.webp",
    "/textures/ui/cards/landscapes/towne.webp",
    "/textures/ui/cards/landscapes/discoverya.webp",
    "/textures/ui/cards/landscapes/discoveryb.webp",
    "/textures/ui/cards/landscapes/discoveryc.webp",
    "/textures/ui/cards/landscapes/discoveryd.webp",
    "/textures/ui/cards/landscapes/discoverye.webp",
    "/textures/ui/cards/landscapes/dungeona.webp",
    "/textures/ui/cards/landscapes/factiona.webp",
    "/textures/ui/cards/landscapes/factionb.webp",
  ];
  for (let i = 0; i < allImages.length; i++) {
    if (allImages[i] === url) {
      return allImages[i];
    }
  }
};
