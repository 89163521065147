import { SimpleDropzone } from "simple-dropzone";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { scene } from "../script";
import gsap from "gsap";
import * as GAMESTATE from "./gamestate";
import * as SELECTIONMANAGER from "./managers/selectionmanager";
import { SideBarButtonBounce, CloseBottomNav, CloseRightSideNav } from "./ui";
import * as THREE from "three";
import { GetCurrentPlayMat } from "./assets/scenetables";

let isCharacter = false;
let isBuilding = false;
let isTexture = false;
const button_upload = document.getElementById("button_uploadModel");
const wrapper = document.getElementById("dropzoneWrapper");
const uploadWindow = document.getElementById("dropzone");
const uploadModelButton = document.getElementById("uploadModelSelect");
const uploadBuildingButton = document.getElementById("uploadBuildingSelect");
const uploadTextureButton = document.getElementById("uploadTextureSelect");
const buttonClose = document.getElementById("dropzoneCloseButton");
const uploadLabel = document.getElementById("uploadLabel");
let loaderIndex = 0;
export function setLoaderIndex(index) {
  loaderIndex = index;
}
//gsap.set(uploadWindow, { scale: 0 });

uploadModelButton.onclick = function () {
  isCharacter = false;
  isBuilding = false;
  isTexture = false;
  gsap.to(uploadModelButton, { scale: 0, duration: 0.2 });
  gsap.to(uploadBuildingButton, { scale: 0, duration: 0.2 });
  gsap.to(uploadTextureButton, { scale: 0, duration: 0.2 });
  gsap.set(uploadWindow, { scale: 0 });
  uploadWindow.style.visibility = "visible";
  gsap.to(uploadWindow, { scale: 1, duration: 0.2, delay: 0.2 });
  loaderIndex = 0;
  isCharacter = true;
  uploadLabel.innerHTML = "Drag in a .GLTF or click to browse";
};

uploadBuildingButton.onclick = function () {
  isCharacter = false;
  isBuilding = false;
  isTexture = false;
  gsap.to(uploadModelButton, { scale: 0, duration: 0.2 });
  gsap.to(uploadBuildingButton, { scale: 0, duration: 0.2 });
  gsap.to(uploadTextureButton, { scale: 0, duration: 0.2 });
  gsap.set(uploadWindow, { scale: 0 });
  uploadWindow.style.visibility = "visible";
  gsap.to(uploadWindow, { scale: 1, duration: 0.2, delay: 0.2 });
  loaderIndex = 0;
  isBuilding = true;
  uploadLabel.innerHTML =
    "Drag in a .GLTF or click to browse. Buildings cannot be moved after being placed!";
};

uploadTextureButton.onclick = function () {
  isCharacter = false;
  isBuilding = false;
  isTexture = false;
  gsap.to(uploadModelButton, { scale: 0, duration: 0.2 });
  gsap.to(uploadBuildingButton, { scale: 0, duration: 0.2 });
  gsap.to(uploadTextureButton, { scale: 0, duration: 0.2 });
  gsap.set(uploadWindow, { scale: 0 });
  uploadWindow.style.visibility = "visible";
  gsap.to(uploadWindow, { scale: 1, duration: 0.2, delay: 0.2 });
  loaderIndex = 0;
  isTexture = true;
  uploadLabel.innerHTML = "Drag in a JPG or PNG or click to browse.";
};
// uploadMapButton.onclick = function () {
//   gsap.to(uploadModelButton, { scale: 0, duration: 0.2 });
//   gsap.to(uploadMapButton, { scale: 0, duration: 0.2 });
//   gsap.set(uploadWindow, { scale: 0 });
//   uploadWindow.style.visibility = "visible";
//   gsap.to(uploadWindow, { scale: 1, duration: 0.2, delay: 0.2 });
//   loaderIndex = 1;
// };
buttonClose.onclick = function () {
  CloseDropzone();
};

function CloseDropzone() {
  gsap.to(wrapper, { scale: 0, duration: 0.2 });
  gsap.set(uploadModelButton, { scale: 1, duration: 0.2, delay: 0.4 });
  gsap.set(uploadBuildingButton, { scale: 1, duration: 0.2, delay: 0.4 });
  gsap.set(uploadTextureButton, { scale: 1, duration: 0.2, delay: 0.4 });
  gsap.to(uploadWindow, { scale: 0, duration: 0.2, delay: 0.2 });
}
gsap.set(wrapper, { scale: 0, duration: 0.2 });
button_upload.onclick = function () {
  SideBarButtonBounce(button_upload);
  CloseBottomNav();
  CloseRightSideNav();
  gsap.to(wrapper, { scale: 1, duration: 0.2 });
};

const arrayBufferToString = (buffer, callback) => {
  const blob = new Blob([buffer], { type: "text/plain" });
  const reader = new FileReader();
  reader.onload = function (evt) {
    if (evt.target) callback(evt.target.result);
  };
  reader.readAsText(blob, "utf-8");
};

export default function uploadFile() {
  const dropEl = document.querySelector(".dropzone");
  const inputEl = document.querySelector(".input");
  const dropzone = new SimpleDropzone(dropEl, inputEl);

  const loader = new GLTFLoader();

  dropzone.on("drop", async ({ files }) => {
    const [file] = files;
    const [, fileNode] = file;
    CloseDropzone();

    if (isTexture) {
      const reader = new FileReader();
      reader.onload = function (event) {
        var image = document.createElement("img");
        image.src = event.target.result;
        var texture = new THREE.Texture(image);
        texture.needsUpdate = true;
        texture.flipY = false;
        const mat = GetCurrentPlayMat();
        mat.material.map = texture;
      };
      reader.readAsDataURL(fileNode);
    }

    if (!isTexture) {
      const reader = new FileReader();
      reader.onabort = () => console.error("file reading was aborted");
      reader.onerror = () => console.error("file reading has failed");
      reader.onload = async () => {
        const data = reader.result;

        if (data) {
          arrayBufferToString(data, async (buf) => {
            if (buf) {
              const result = await new Promise((resolve, reject) =>
                loader.parse(buf, "", resolve, reject)
              );

              scene.add(result.scene);

              result.scene.traverse(function (child) {
                if (child.isMesh) {
                  child.castShadow = true;
                  child.receiveShadow = true;
                }
              });
              if (isCharacter) {
                GAMESTATE.AddCharacterToGame(result.scene);
                SELECTIONMANAGER.MoveSelectedObject(
                  result.scene,
                  false,
                  true,
                  "figurine"
                );
              }
              if (isBuilding) {
                GAMESTATE.AddLevelObjectToGame(result.scene);
                SELECTIONMANAGER.MoveSelectedObject(
                  result.scene,
                  false,
                  true,
                  "medievalExterior"
                );
              }

              SELECTIONMANAGER.CreatingNewObject();
              result.scene.rotation.y = SELECTIONMANAGER.currentrotation;
            }
          });
        }
      };

      if (!isTexture) reader.readAsArrayBuffer(fileNode);
      else {
        reader.readAsDataURL(fileNode);
      }
    }
  });

  dropzone.on("droperror", ({ message }) => {
    alert(`Error: ${message}`);
  });
}

async function GetMaterial(src) {
  var material = new THREE.MeshStandardMaterial();
  var loader = new THREE.TextureLoader();

  loader.crossOrigin = "";

  loader.load(
    // resource URL
    src,
    // Function when resource is loaded
    function (texture) {
      // do something with the texture

      material.map = texture; // set the material's map when when the texture is loaded

      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.flipY = false;
      material.map = texture;
      texture.needsUpdate = true;
    },
    // Function called when download progresses
    function (xhr) {},
    // Function called when download errors
    function (xhr) {}
  );
  material.needsUpdate = true;
  return material;
}

async function GetTexture(src) {
  var loader = new THREE.TextureLoader();

  loader.crossOrigin = "";
  let tex;
  loader.load(
    // resource URL
    src,
    // Function when resource is loaded
    function (texture) {
      // do something with the texture

      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.flipY = false;
      texture.needsUpdate = true;
      tex = texture;
    },
    // Function called when download progresses
    function (xhr) {},
    // Function called when download errors
    function (xhr) {}
  );

  return tex;
}
