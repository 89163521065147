import gsap from "gsap";
import * as ASSETLOADER from "./assetLoader";
import * as ERASER from "./eraser";
import * as LIGHTS from "./lights";
import * as CARDS from "./cards";
import * as DICE from "./dice";
import * as TREEPAINTER from "./treepainter";
import * as GAMESTATE from "./gamestate";
import * as MAGIC from "./assets/magic";
import * as SELECTIONMANAGER from "./managers/selectionmanager";
import { SetplayMat } from "./settings";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { saveAsImage } from "./snapshot";
import { loadCampaign, newMap } from "./campaign";
import "simplebar"; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import "simplebar/dist/simplebar.css";
import { ApplyAllSettings } from "./settings";
import { showHint } from "./hints";
import {
  AllowUpdateDatabase,
  setAllowUpdateDatabase,
} from "./multiplayer/status";
import {
  activateMeasuringStick,
  deactivateMeasuringStick,
  getMeasuringStickActive,
} from "./measuringStick";
// // ***
// // Grab HTML elements from document
// // ***
gsap.registerPlugin(ScrollToPlugin);
// // Menus

document.body.onclick = (e) => {
  if (
    e.target.tagName != "CANVAS" &&
    e.target.id != "eraser_icon" &&
    e.target.id != "button_eraser"
  )
    ERASER.DeactivateEraser();

  if (e.target.tagName != "CANVAS") DICE.TempDisableDiceRoller();
  //
  //
  //
};

// const sideNav = document.getElementById("mySidenav");
// const myBottomnav = document.getElementById("myBottomnav");
// const myBuildMenu = document.getElementById("myBuildMenu");
// const myCharacterMenu = document.getElementById("myCharacterMenu");
const myLayoutWindow = document.getElementById("myLayoutWindow");
const myBuildingWindow = document.getElementById("myBuildingWindow");
const myGroundWindow = document.getElementById("myGroundWindow");
const myWallWindow = document.getElementById("myWallWindow");
const myPropWindow = document.getElementById("myPropWindow");
const myCharacterWindow = document.getElementById("myCharacterWindow");
const myMonsterWindow = document.getElementById("myMonsterWindow");
const myCreatureWindow = document.getElementById("myCreatureWindow");
const myFigurineWindow = document.getElementById("myFigurineWindow");
const myDiceRollerWindow = document.getElementById("myDiceRollerWindow");
const mySettingsWindow = document.getElementById("mySettingsWindow");
const myNatureLayoutWindow = document.getElementById("myNatureLayoutWindow");
const myNatureTilesWindow = document.getElementById("myNatureTilesWindow");
const myPlantsWindow = document.getElementById("myPlantsWindow");
const myRocksWindow = document.getElementById("myRocksWindow");
const myMagicWindow = document.getElementById("myMagicWindow");
const myClearMapWindow = document.getElementById("myClearMapWindow");

let rightSideMenuIsOpen = false;
let currentOpenRightSideWindow;

// // Side Nav Buttons

const button_architect = document.getElementById("button_architect");
const button_eraser = document.getElementById("button_eraser");
const button_sunlight = document.getElementById("button_sunlight");
const button_cards = document.getElementById("button_cards");
// const button_snapToGrid = document.getElementById("button_snapToGrid");
const button_measuringStick = document.getElementById("button_measuringStick");
const button_worldSettings = document.getElementById("button_worldSettings");
const button_clearMap = document.getElementById("button_clearMap");
const button_clearMapClose = document.getElementById("clearMapCloseButton");
const button_snapshot = document.getElementById("button_snapshot");
const button_tooltips = document.getElementById("button_tooltips");
const button_campaign = document.getElementById("button_campaign");
const button_clearMapConfirm = document.getElementById("clearMapConfirmButton");
const button_worldSettingsClose = document.getElementById(
  "settingsCloseButton"
);
const button_upload = document.getElementById("button_uploadModel");
const button_diceroller = document.getElementById("button_diceroller");
const button_dicerollerclose = document.getElementById(
  "button_dicerollerclose"
);

const button_multiplayer = document.getElementById("multiplayerButton");
// // Architect Buttons

const button_characters = document.getElementById("button_characters");
const button_build = document.getElementById("button_build");
const button_wild = document.getElementById("button_wild");
const button_magic = document.getElementById("button_magic");
const button_light = document.getElementById("button_light");
const architectButtons = [
  button_characters,
  button_build,
  button_wild,
  button_magic,
  button_light,
];

// // Card buttons

const button_card_character = document.getElementById("button_character");
const button_card_NPC = document.getElementById("button_NPC");
const button_card_location = document.getElementById("button_location");
const button_card_settlement = document.getElementById("button_settlement");

const button_card_urbandiscovery = document.getElementById(
  "button_urbandiscovery"
);
const button_card_wilddiscovery = document.getElementById(
  "button_wilddiscovery"
);
const button_card_faction = document.getElementById("button_faction");
const button_card_building = document.getElementById("button_building");
const button_card_tavern = document.getElementById("button_tavern");
const button_card_fatewords = document.getElementById("button_fatewords");
const button_card_questwords = document.getElementById("button_questwords");
const button_card_scrollleft = document.getElementById("button_cardscrollLeft");
const button_card_scrollright = document.getElementById(
  "button_cardscrollRight"
);
const cardScrollArea = document.getElementById("cardScrollArea");

let cardButtons = [
  button_card_scrollleft,
  button_card_character,
  button_card_NPC,
  button_card_location,
  button_card_settlement,
  button_card_wilddiscovery,
  button_card_urbandiscovery,
  button_card_scrollright,
  button_card_faction,
  button_card_building,
  button_card_tavern,
  button_card_fatewords,
  button_card_questwords,
];

let cardMenuScrollIndex = 0;

gsap.to(button_card_scrollleft, {
  duration: 0.3,
  opacity: 0.3,
});

button_card_scrollleft.onclick = function () {
  gsap.to(cardScrollArea, {
    duration: 0.3,
    scrollTo: {
      y: 0,
      x: 0,
    },
  });
  gsap.to(button_card_scrollleft, {
    duration: 0.3,
    opacity: 0.3,
  });
  gsap.to(button_card_scrollright, {
    duration: 0.3,
    opacity: 1,
  });
  cardMenuScrollIndex = 0;
  cardButtons = [
    button_card_scrollleft,
    button_card_character,
    button_card_NPC,
    button_card_location,
    button_card_settlement,
    button_card_wilddiscovery,
    button_card_urbandiscovery,
    button_card_scrollright,
    button_card_faction,
    button_card_building,
    button_card_tavern,
    button_card_fatewords,
    button_card_questwords,
  ];
};
button_card_scrollright.onclick = function () {
  gsap.to(cardScrollArea, {
    duration: 0.3,
    scrollTo: {
      y: 0,
      x: 900,
    },
  });
  gsap.to(button_card_scrollleft, {
    duration: 0.3,
    opacity: 1,
  });
  gsap.to(button_card_scrollright, {
    duration: 0.3,
    opacity: 0.3,
  });
  cardMenuScrollIndex = 1;

  cardButtons = [
    button_card_scrollleft,

    button_card_urbandiscovery,
    button_card_faction,
    button_card_building,
    button_card_tavern,
    button_card_questwords,
    button_card_fatewords,
    button_card_scrollright,
    button_card_character,
    button_card_NPC,
    button_card_location,
    button_card_settlement,
    button_card_wilddiscovery,
  ];
};

// // Light Buttons
const button_light_back = document.getElementById("button_light_back");
const button_light1 = document.getElementById("button_light1");
const button_light2 = document.getElementById("button_light2");
const button_light3 = document.getElementById("button_light3");
const button_light4 = document.getElementById("button_light4");
const button_light5 = document.getElementById("button_light5");
const lightButtons = [
  button_light_back,
  button_light1,
  button_light2,
  button_light3,
  button_light4,
  button_light5,
];

// // Character Buttons

const button_character_back = document.getElementById("button_character_back");
const button_figurines = document.getElementById("button_figurines");
const button_heroes = document.getElementById("button_heroes");
const button_monsters = document.getElementById("button_monsters");
const button_creatures = document.getElementById("button_creatures");
const characterButtons = [
  button_character_back,
  button_figurines,
  button_heroes,
  button_monsters,
  button_creatures,
];

// // Building Buttons

const button_build_back = document.getElementById("button_build_back");
const button_layouts = document.getElementById("button_layouts");
const button_buildings = document.getElementById("button_buildings");
const button_floors = document.getElementById("button_floors");
const button_props = document.getElementById("button_props");
const button_walls = document.getElementById("button_walls");
const buildingButtons = [
  button_build_back,
  button_layouts,
  button_buildings,
  button_floors,
  button_walls,
  button_props,
];

// // Wild Buttons

const button_wild_back = document.getElementById("button_wild_back");
const button_wild_layouts = document.getElementById("button_wild_layouts");
const button_tiles = document.getElementById("button_tiles");
const button_plants = document.getElementById("button_plants");
const button_rocks = document.getElementById("button_rocks");
const wildButtons = [
  button_wild_back,
  button_wild_layouts,
  button_tiles,
  button_plants,
  button_rocks,
];

// // Magic Buttons

const button_magic_back = document.getElementById("button_magic_back");
const button_portals = document.getElementById("button_portals");
const button_elements = document.getElementById("button_elements");
const button_orbs = document.getElementById("button_orbs");
const button_explosions = document.getElementById("button_explosions");
const magicButtons = [
  button_magic_back,
  button_portals,
  button_elements,
  button_orbs,
  button_explosions,
];

// // ***
// // Add click events to buttons
// // ***

// // Side bar events

let architectMenuOpen = false;
button_architect.onclick = function () {
  setAllowUpdateDatabase(true);
  let timeout = 0;

  if (cardMenuIsOpen) timeout = 500;
  AnimateBottomNavMenu(cardButtons, 300, 1, 0.4);

  cardMenuIsOpen = false;
  setTimeout(() => {
    if (!architectMenuOpen) {
      AnimateBottomNavMenu(architectButtons, 0, 1, 0.4);

      architectMenuOpen = true;
    } else {
      CloseRightSideNav();
      CloseBottomNav();

      architectMenuOpen = false;
    }
  }, timeout);

  SideBarButtonBounce(button_architect);
};

// button_snapToGrid.onclick = function () {
//   if (!SELECTIONMANAGER.snapToGrid) SELECTIONMANAGER.EnableSnapToGrid();
//   else SELECTIONMANAGER.DisableSnapToGrid();

//   SideBarButtonBounce(button_snapToGrid);
// };

button_eraser.onclick = function () {
  if (!ERASER.eraserActive) {
    ERASER.ActivateEraser();
    showHint(1);
  } else {
    ERASER.DeactivateEraser();
  }

  SideBarButtonBounce(button_eraser);
};

button_measuringStick.onclick = function () {
  if (!getMeasuringStickActive()) {
    activateMeasuringStick();
    showHint(6);
  } else {
    deactivateMeasuringStick();
  }

  SideBarButtonBounce(button_measuringStick);
};

button_sunlight.onclick = function () {
  LIGHTS.ToggleSun();

  SideBarButtonBounce(button_sunlight);
};

button_worldSettings.onclick = function () {
  var tl = gsap.timeline();

  tl.to(mySettingsWindow, {
    scaleX: 1,
    scaleY: 1,
    duration: 0.2,
    delay: 0,
  });

  SideBarButtonBounce(button_worldSettings);
};

button_worldSettingsClose.onclick = function () {
  var tl = gsap.timeline();
  tl.to(mySettingsWindow, {
    scaleX: 0,
    scaleY: 0,
    duration: 0.1,
  });
  ApplyAllSettings();
};

var tl = gsap.timeline();
tl.to(mySettingsWindow, {
  scaleX: 0,
  scaleY: 0,
  duration: 0.2,
});

button_clearMap.onclick = function () {
  var tl = gsap.timeline();
  tl.set(myClearMapWindow, {
    scaleX: 0,
    scaleY: 0,
    duration: 0,
  });
  tl.to(myClearMapWindow, {
    scaleX: 1,
    scaleY: 1,
    duration: 0.2,
    delay: 0,
  });

  SideBarButtonBounce(button_clearMap);
};
button_clearMapClose.onclick = function () {
  var tl = gsap.timeline();
  tl.to(myClearMapWindow, {
    scaleX: 0,
    scaleY: 0,
    duration: 0.2,
  });
};

button_clearMapConfirm.onclick = function () {
  var tl = gsap.timeline();
  tl.to(myClearMapWindow, {
    scaleX: 0,
    scaleY: 0,
    duration: 0.2,
  });

  GAMESTATE.ClearGame(true, true);
};

button_snapshot.onclick = function () {
  saveAsImage();
  SideBarButtonBounce(button_snapshot);
};

var tlb = gsap.timeline();
tlb.to(myClearMapWindow, {
  scaleX: 0,
  scaleY: 0,
  duration: 0.2,
});
button_diceroller.onclick = function () {
  DICE.LoadDice();
  var tl = gsap.timeline();
  tl.set(myDiceRollerWindow, {
    scaleX: 0,
    scaleY: 0,
    duration: 0,
  });
  tl.to(myDiceRollerWindow, {
    scaleX: 1,
    scaleY: 1,
    duration: 0.3,
    delay: 0,
  });
  DiceRollerMultipliers(1);
  SideBarButtonBounce(button_diceroller);
  CloseBottomNav();
  CloseRightSideNav();
  showHint(2);
};

closeDiceRoller();
function closeDiceRoller() {
  var tl = gsap.timeline();
  tl.to(myDiceRollerWindow, {
    scaleX: 0,
    scaleY: 0,
    duration: 0.1,
  });
}
button_dicerollerclose.onclick = function () {
  var tl = gsap.timeline();
  tl.to(myDiceRollerWindow, {
    scaleX: 0,
    scaleY: 0,
    duration: 0.1,
  });
  DICE.DeleteActiveDice();
};

const diceButtonD4 = document.getElementById("diceButtonD4");

diceButtonD4.onclick = function () {
  DICE.SelectDiceToRoll("d4");
  SetDiceButtonHighlight(1, false);
  DICE.EnableDiceRoller();
};

const diceButtonD6 = document.getElementById("diceButtonD6");
diceButtonD6.onclick = function () {
  DICE.SelectDiceToRoll("d6");
  SetDiceButtonHighlight(2, false);
  DICE.EnableDiceRoller();
};
const diceButtonD8 = document.getElementById("diceButtonD8");
diceButtonD8.onclick = function () {
  DICE.SelectDiceToRoll("d8");
  SetDiceButtonHighlight(3, false);
  DICE.EnableDiceRoller();
};
const diceButtonD10 = document.getElementById("diceButtonD10");
diceButtonD10.onclick = function () {
  DICE.SelectDiceToRoll("d10");
  SetDiceButtonHighlight(4, false);
  DICE.EnableDiceRoller();
};
const diceButtonD12 = document.getElementById("diceButtonD12");
diceButtonD12.onclick = function () {
  DICE.SelectDiceToRoll("d12");
  SetDiceButtonHighlight(5, false);
  DICE.EnableDiceRoller();
};
const diceButtonD20 = document.getElementById("diceButtonD20");
diceButtonD20.onclick = function () {
  DICE.SelectDiceToRoll("d20");
  SetDiceButtonHighlight(6, false);
  DICE.EnableDiceRoller();
};

export function SetResultText(result) {
  var tl = gsap.timeline();

  const diceResultText = document.getElementById("diceResultText");
  tl.to(diceResultText, {
    opacity: 0,
    duration: 0.5,
    delay: 0,
  }).to(diceResultText, {
    opacity: 1,
    duration: 2,
    delay: 1,
  });

  setTimeout(() => {
    diceResultText.innerHTML = String(result);
  }, 500);
}
export function SetDiceButtonHighlight(value, disableAll) {
  let diceButtons = [
    diceButtonD4,
    diceButtonD6,
    diceButtonD8,
    diceButtonD10,
    diceButtonD12,
    diceButtonD20,
  ];
  for (let i = 0; i < diceButtons.length; i++) {
    diceButtons[i].style.backgroundImage = "url('./textures/ui/button_b.webp";
  }
  if (!disableAll)
    diceButtons[value - 1].style.backgroundImage =
      "url('./textures/ui/button_b_highlight.webp";
}

const diceMultiplier1 = document.getElementById("diceMultiplierButton1");
diceMultiplier1.onclick = function () {
  DiceRollerMultipliers(1);
};
const diceMultiplier2 = document.getElementById("diceMultiplierButton2");
diceMultiplier2.onclick = function () {
  DiceRollerMultipliers(2);
};
const diceMultiplier3 = document.getElementById("diceMultiplierButton3");
diceMultiplier3.onclick = function () {
  DiceRollerMultipliers(3);
};
const diceMultiplier4 = document.getElementById("diceMultiplierButton4");
diceMultiplier4.onclick = function () {
  DiceRollerMultipliers(4);
};
const diceMultiplier5 = document.getElementById("diceMultiplierButton5");
diceMultiplier5.onclick = function () {
  DiceRollerMultipliers(5);
};
const diceMultiplier6 = document.getElementById("diceMultiplierButton6");
diceMultiplier6.onclick = function () {
  DiceRollerMultipliers(6);
};
const diceMultiplier7 = document.getElementById("diceMultiplierButton7");
diceMultiplier7.onclick = function () {
  DiceRollerMultipliers(7);
};
const diceMultiplier8 = document.getElementById("diceMultiplierButton8");
diceMultiplier8.onclick = function () {
  DiceRollerMultipliers(8);
};
const diceMultiplier9 = document.getElementById("diceMultiplierButton9");
diceMultiplier9.onclick = function () {
  DiceRollerMultipliers(9);
};
const diceMultiplier10 = document.getElementById("diceMultiplierButton10");
diceMultiplier10.onclick = function () {
  DiceRollerMultipliers(10);
};
const diceMultiplier11 = document.getElementById("diceMultiplierButton11");
diceMultiplier11.onclick = function () {
  DiceRollerMultipliers(11);
};
const diceMultiplier12 = document.getElementById("diceMultiplierButton12");
diceMultiplier12.onclick = function () {
  DiceRollerMultipliers(12);
};

function DiceRollerMultipliers(value) {
  let RollerIcons = [
    diceMultiplier1,
    diceMultiplier2,
    diceMultiplier3,
    diceMultiplier4,
    diceMultiplier5,
    diceMultiplier6,
    diceMultiplier7,
    diceMultiplier8,
    diceMultiplier9,
    diceMultiplier10,
    diceMultiplier11,
    diceMultiplier12,
  ];
  for (let i = 0; i < RollerIcons.length; i++) {
    RollerIcons[i].style.backgroundImage = "url('./textures/ui/button_d.webp";
  }
  RollerIcons[value - 1].style.backgroundImage =
    "url('./textures/ui/button_d_highlight.webp";

  DICE.SelectCurrentMultiplier(value);
}

let cardMenuIsOpen = false;

button_cards.onclick = function () {
  let timeout = 0;

  if (architectMenuOpen) timeout = 500;

  AnimateBottomNavMenu(architectButtons, 300, 0, 0.4);
  architectMenuOpen = false;
  CloseRightSideNav();
  setTimeout(() => {
    if (!cardMenuIsOpen) {
      AnimateBottomNavMenu(cardButtons, 0, 1, 0.3);

      cardMenuIsOpen = true;
    } else {
      AnimateBottomNavMenu(cardButtons, 300, 0, 0.3);
      //CloseRightSideNav();
      cardMenuIsOpen = false;
    }
  }, timeout);

  AnimateBottomNavMenu(characterButtons, 200, 0, 0);
  AnimateBottomNavMenu(buildingButtons, 200, 0, 0);
  AnimateBottomNavMenu(wildButtons, 200, 0, 0);
  AnimateBottomNavMenu(lightButtons, 200, 0, 0);
  SideBarButtonBounce(button_cards);
};

export function SideBarButtonBounce(buttonToBounce) {
  var tl = gsap.timeline();

  tl.to(buttonToBounce, {
    scale: 1.1,
    duration: 0.1,
    delay: 0,
  }).to(buttonToBounce, {
    scale: 1,
    duration: 0.1,
    delay: 0.1,
  });
}

// // Character button events

button_characters.onclick = function () {
  AnimateBottomNavMenu(architectButtons, 200, 0, 0.4);

  setTimeout(() => {
    AnimateBottomNavMenu(characterButtons, 0, 1, 0.4);
  }, 500);
};

button_character_back.onclick = function () {
  AnimateBottomNavMenu(characterButtons, 200, 0, 0.4);
  CloseRightSideNav();
  setTimeout(() => {
    AnimateBottomNavMenu(architectButtons, 0, 1, 0.4);
  }, 500);
};

button_figurines.onclick = function () {
  CloseRightSideNav(myFigurineWindow);
  currentOpenRightSideWindow = myFigurineWindow;
  showHint(0);
};
button_heroes.onclick = function () {
  CloseRightSideNav(myCharacterWindow);
  currentOpenRightSideWindow = myCharacterWindow;
  showHint(0);
};
button_monsters.onclick = function () {
  CloseRightSideNav(myMonsterWindow);
  currentOpenRightSideWindow = myMonsterWindow;
  showHint(0);
};
button_creatures.onclick = function () {
  CloseRightSideNav(myCreatureWindow);
  currentOpenRightSideWindow = myCreatureWindow;
  showHint(0);
};

// // Building Button events

button_build.onclick = function () {
  AnimateBottomNavMenu(architectButtons, 200, 0, 0.4);

  setTimeout(() => {
    AnimateBottomNavMenu(buildingButtons, 0, 1, 0.4);
  }, 500);
};

button_build_back.onclick = function () {
  AnimateBottomNavMenu(buildingButtons, 200, 0, 0.4);
  CloseRightSideNav();
  setTimeout(() => {
    AnimateBottomNavMenu(architectButtons, 0, 1, 0.4);
  }, 500);
};

button_layouts.onclick = function () {
  CloseRightSideNav(myLayoutWindow);
  currentOpenRightSideWindow = myLayoutWindow;
  showHint(0);
};
button_props.onclick = function () {
  CloseRightSideNav(myPropWindow);
  currentOpenRightSideWindow = myPropWindow;
  showHint(0);
};
button_buildings.onclick = function () {
  CloseRightSideNav(myBuildingWindow);
  currentOpenRightSideWindow = myBuildingWindow;
  showHint(0);
};
button_floors.onclick = function () {
  CloseRightSideNav(myGroundWindow);
  currentOpenRightSideWindow = myGroundWindow;
  showHint(0);
};
button_walls.onclick = function () {
  CloseRightSideNav(myWallWindow);
  currentOpenRightSideWindow = myWallWindow;
  showHint(0);
};
//button_floors.onclick = function () {};

// // Wild button events

button_wild.onclick = function () {
  AnimateBottomNavMenu(architectButtons, 200, 0, 0.4);

  setTimeout(() => {
    AnimateBottomNavMenu(wildButtons, 0, 1, 0.4);
  }, 500);
};

button_wild_back.onclick = function () {
  AnimateBottomNavMenu(wildButtons, 200, 0, 0.4);
  CloseRightSideNav();
  setTimeout(() => {
    AnimateBottomNavMenu(architectButtons, 0, 1, 0.4);
  }, 500);
};

button_wild_layouts.onclick = function () {
  CloseRightSideNav(myNatureLayoutWindow);
  currentOpenRightSideWindow = myNatureLayoutWindow;
};

button_tiles.onclick = function () {
  CloseRightSideNav(myNatureTilesWindow);
  currentOpenRightSideWindow = myNatureTilesWindow;
  showHint(0);
};

button_plants.onclick = function () {
  CloseRightSideNav(myPlantsWindow);
  currentOpenRightSideWindow = myPlantsWindow;
  showHint(3);
};

button_rocks.onclick = function () {
  CloseRightSideNav(myRocksWindow);
  currentOpenRightSideWindow = myRocksWindow;
  showHint(3);
};

// // Magic button events

button_magic.onclick = function () {
  CloseRightSideNav(myMagicWindow);
  currentOpenRightSideWindow = myMagicWindow;
  showHint(0);
};

button_portals.onclick = function () {};
button_elements.onclick = function () {};
button_orbs.onclick = function () {};
button_explosions.onclick = function () {};

// // Light button events

button_light.onclick = function () {
  AnimateBottomNavMenu(architectButtons, 200, 0, 0.4);

  SetRightMenuInactive();
  setTimeout(() => {
    AnimateBottomNavMenu(lightButtons, 0, 1, 0.4);
  }, 500);

  showHint(4);
};
button_light_back.onclick = function () {
  AnimateBottomNavMenu(lightButtons, 200, 0, 0.4);

  CloseRightSideNav();
  setTimeout(() => {
    AnimateBottomNavMenu(architectButtons, 0, 1, 0.4);
  }, 500);
};

// // Card button events

button_card_character.onclick = function () {
  CARDS.Createcard("Character");
};
button_card_NPC.onclick = function () {
  CARDS.Createcard("NPC");
};
button_card_location.onclick = function () {
  CARDS.Createcard("Region");
};
button_card_settlement.onclick = function () {
  CARDS.Createcard("Settlement");
};
button_card_wilddiscovery.onclick = function () {
  CARDS.Createcard("WildDiscovery");
};
button_card_urbandiscovery.onclick = function () {
  CARDS.Createcard("UrbanDiscovery");
};
button_card_faction.onclick = function () {
  CARDS.Createcard("Faction");
};
button_card_building.onclick = function () {
  CARDS.Createcard("Building");
};
button_card_tavern.onclick = function () {
  CARDS.Createcard("Tavern");
};
button_card_fatewords.onclick = function () {
  CARDS.Createcard("Fate");
};
button_card_questwords.onclick = function () {
  CARDS.Createcard("Quest");
};

// // ***
// // Reset all UI animations to default start
// // ***

CloseRightSideNav();
CloseBottomNav();

closeDiceRoller();
export function InitializeUI() {
  AnimateSideBar(1, 1);
}

const sideBarButtons = [
  button_architect,
  button_eraser,
  button_sunlight,
  button_cards,
  button_diceroller,
  button_worldSettings,
  // button_snapToGrid,
  button_measuringStick,
  button_upload,
  button_snapshot,
  button_clearMap,
  button_tooltips,
  button_campaign,
  button_multiplayer,
];

export let GetSideBarButtons = () => {
  return sideBarButtons;
};

for (let i = 0; i < sideBarButtons.length; i++) {
  sideBarButtons[i].style.opacity = 0;
}
export function AnimateSideBar(op, dur) {
  gsap.to(sideBarButtons, { opacity: op, duration: dur });
}

function AnimateBottomNavMenu(buttonArray, yPos, opacity, duration) {
  var tl = new gsap.timeline();

  for (let i = 0; i < buttonArray.length; i++) {
    tl.to(
      buttonArray[i],
      {
        y: yPos,
        duration: duration,
        ease: "elastic.out(1, 0.9)",
      },
      "<25%"
    );
  }
}

function AnimationRightSideMenu(menu, xPos, opacity, duration) {
  var tl = new gsap.timeline();
  tl.to(menu, {
    x: xPos,
    duration: duration,
    ease: "power2",
  });
}

export function CloseBottomNav() {
  AnimateBottomNavMenu(architectButtons, 200, 0, 0.1);
  AnimateBottomNavMenu(lightButtons, 200, 0, 0.1);
  AnimateBottomNavMenu(characterButtons, 200, 0, 0.1);
  AnimateBottomNavMenu(buildingButtons, 200, 0, 0.1);
  AnimateBottomNavMenu(wildButtons, 200, 0, 0.1);
  AnimateBottomNavMenu(magicButtons, 200, 0, 0.1);
  AnimateBottomNavMenu(cardButtons, 300, 0, 0.1);
  AnimationRightSideMenu(myMagicWindow, 480, 1, 0.2);
  architectMenuOpen = false;
}

export function CloseRightSideNav(windowToOpen) {
  if (windowToOpen != null) {
    if (currentOpenRightSideWindow != windowToOpen) {
      CloseRightSideNav();

      if (windowToOpen != null) {
        setTimeout(() => {
          AnimationRightSideMenu(windowToOpen, 0, 1, 0.2);
        }, 500);
      }
    }
  } else {
    AnimationRightSideMenu(myLayoutWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myCharacterWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myMonsterWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myCreatureWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myFigurineWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myBuildingWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myPropWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myGroundWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myWallWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myNatureTilesWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myNatureLayoutWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myPlantsWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myRocksWindow, 480, 1, 0.2);
    AnimationRightSideMenu(myMagicWindow, 480, 1, 0.2);

    currentOpenRightSideWindow = null;
  }
}

// // UI asset buttons

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Interior Layouts ///////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const LayoutBackButton = document.getElementById("layoutBackButton");
const layoutButton1 = document.getElementById("button_layout1");
const layoutButton2 = document.getElementById("button_layout2");
const layoutButton3 = document.getElementById("button_layout3");
const layoutButton4 = document.getElementById("button_layout4");
const layoutButton5 = document.getElementById("button_layout5");
const layoutButton6 = document.getElementById("button_layout6");
const layoutButton7 = document.getElementById("button_layout7");
const layoutButton8 = document.getElementById("button_layout8");
const layoutButton9 = document.getElementById("button_layout9");
const layoutButton10 = document.getElementById("button_layout10");
const layoutButton11 = document.getElementById("button_layout11");
const layoutButton12 = document.getElementById("button_layout12");
const layoutButton13 = document.getElementById("button_layout13");
const layoutButton14 = document.getElementById("button_layout14");
const layoutButton15 = document.getElementById("button_layout15");
const layoutButton16 = document.getElementById("button_layout16");
const layoutButton17 = document.getElementById("button_layout17");
const layoutButton18 = document.getElementById("button_layout18");
const layoutButton19 = document.getElementById("button_layout19");
const layoutButton20 = document.getElementById("button_layout20");
const layoutButton21 = document.getElementById("button_layout21");
const layoutButton22 = document.getElementById("button_layout22");
const layoutButton23 = document.getElementById("button_layout23");
const layoutButton24 = document.getElementById("button_layout24");
const layoutButton25 = document.getElementById("button_layout25");
const layoutButton26 = document.getElementById("button_layout26");
const layoutButton27 = document.getElementById("button_layout27");
const layoutButton28 = document.getElementById("button_layout28");
const layoutButton29 = document.getElementById("button_layout29");
const layoutButton30 = document.getElementById("button_layout30");
const layoutButton31 = document.getElementById("button_layout31");
const layoutButton32 = document.getElementById("button_layout32");
const layoutButton33 = document.getElementById("button_layout33");
const layoutButton34 = document.getElementById("button_layout34");
const layoutButton35 = document.getElementById("button_layout35");
const layoutButton36 = document.getElementById("button_layout36");

layoutButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("room1", "topDownInterior");
};

layoutButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("room2", "topDownInterior");
};
layoutButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("room3", "topDownInterior");
};
layoutButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("room4", "topDownInterior");
};
layoutButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cellar1", "topDownInterior");
};
layoutButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("dining1", "topDownInterior");
};
layoutButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("landing1", "topDownInterior");
};
layoutButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("reception1", "topDownInterior");
};
layoutButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tavern1", "topDownInterior");
};
layoutButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Courner1A", "topdownDungeon");
};
layoutButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Courner1B", "topdownDungeon");
};
layoutButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Courner1C", "topdownDungeon");
};
layoutButton13.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Courner2A", "topdownDungeon");
};
layoutButton14.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Courner2B", "topdownDungeon");
};
layoutButton15.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Courner2C", "topdownDungeon");
};
layoutButton16.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module1A", "topdownDungeon");
};
layoutButton17.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module1B", "topdownDungeon");
};
layoutButton18.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module1C", "topdownDungeon");
};
layoutButton19.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module1D", "topdownDungeon");
};
layoutButton20.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module2A", "topdownDungeon");
};
layoutButton21.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module2B", "topdownDungeon");
};
layoutButton22.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module3A", "topdownDungeon");
};
layoutButton23.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module3B", "topdownDungeon");
};
layoutButton24.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module3C", "topdownDungeon");
};
layoutButton25.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Dungeons_Module3D", "topdownDungeon");
};
layoutButton26.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Level_edge", "topdownDungeon");
};
layoutButton27.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Level_Enter1", "topdownDungeon");
};
layoutButton27.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Level_Enter2", "topdownDungeon");
};
layoutButton28.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room_down1", "topdownDungeon");
};
layoutButton28.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room_down2", "topdownDungeon");
};
layoutButton29.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room_down3", "topdownDungeon");
};
layoutButton30.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room_down4", "topdownDungeon");
};
layoutButton31.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room1", "topdownDungeon");
};
layoutButton32.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room2", "topdownDungeon");
};
layoutButton33.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room3A", "topdownDungeon");
};
layoutButton34.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room3B", "topdownDungeon");
};
layoutButton35.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room3C", "topdownDungeon");
};
layoutButton36.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Room4", "topdownDungeon");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Exterior Buildings /////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const BuildingBackButton = document.getElementById("buildingBackButton");
const buildingButton1 = document.getElementById("button_building1");
const buildingButton2 = document.getElementById("button_building2");
const buildingButton3 = document.getElementById("button_building3");
const buildingButton4 = document.getElementById("button_building4");
const buildingButton5 = document.getElementById("button_building5");
const buildingButton6 = document.getElementById("button_building6");
const buildingButton7 = document.getElementById("button_building7");
const buildingButton8 = document.getElementById("button_building8");
const buildingButton9 = document.getElementById("button_building9");
const buildingButton10 = document.getElementById("button_building10");
const buildingButton11 = document.getElementById("button_building11");
const buildingButton12 = document.getElementById("button_building12");
const buildingButton13 = document.getElementById("button_building13");
const buildingButton14 = document.getElementById("button_building14");
const buildingButton15 = document.getElementById("button_building15");
const buildingButton16 = document.getElementById("button_building16");
const buildingButton17 = document.getElementById("button_building17");
const buildingButton18 = document.getElementById("button_building18");
const buildingButton19 = document.getElementById("button_building19");
const buildingButton20 = document.getElementById("button_building20");
const buildingButton21 = document.getElementById("button_building21");
const buildingButton22 = document.getElementById("button_building22");
const buildingButton23 = document.getElementById("button_building23");
const buildingButton24 = document.getElementById("button_building24");
const buildingButton25 = document.getElementById("button_building25");
const buildingButton26 = document.getElementById("button_building26");
const buildingButton27 = document.getElementById("button_building27");
const buildingButton28 = document.getElementById("button_building28");
const buildingButton29 = document.getElementById("button_building29");
const buildingButton30 = document.getElementById("button_building30");
const buildingButton31 = document.getElementById("button_building31");
const buildingButton32 = document.getElementById("button_building32");
const buildingButton33 = document.getElementById("button_building33");
const buildingButton34 = document.getElementById("button_building34");

buildingButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_blacksmith1", "medievalExterior");
};

buildingButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_blacksmith2", "medievalExterior");
};
buildingButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_bridge1", "medievalExterior");
};
buildingButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_castlecourt1", "medievalExterior");
};
buildingButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_castlecourt2", "medievalExterior");
};
buildingButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_castletower1", "medievalExterior");
};
buildingButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_castletower2", "medievalExterior");
};
buildingButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_cemetary1", "medievalExterior");
};
buildingButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_church1", "medievalExterior");
};
buildingButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_churchruins1", "medievalExterior");
};
buildingButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_churchruins2", "medievalExterior");
};
buildingButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_house1", "medievalExterior");
};
buildingButton13.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_house2", "medievalExterior");
};
buildingButton14.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_house3", "medievalExterior");
};
buildingButton15.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_house4", "medievalExterior");
};
buildingButton16.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_house5", "medievalExterior");
};
buildingButton17.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_house6", "medievalExterior");
};
buildingButton18.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_house7", "medievalExterior");
};
buildingButton19.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_house8", "medievalExterior");
};
buildingButton20.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_housetower1", "medievalExterior");
};
buildingButton21.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_manufactory1", "medievalExterior");
};
buildingButton22.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_ruin1", "medievalExterior");
};
buildingButton23.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_ruin2", "medievalExterior");
};
buildingButton24.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_ruin3", "medievalExterior");
};
buildingButton25.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_tower1", "medievalExterior");
};
buildingButton26.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_tower2", "medievalExterior");
};
buildingButton27.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_tower3", "medievalExterior");
};
buildingButton28.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_tower4", "medievalExterior");
};
buildingButton29.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_tower5", "medievalExterior");
};
buildingButton30.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_tower6", "medievalExterior");
};
buildingButton31.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_tower7", "medievalExterior");
};
buildingButton32.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_well1", "medievalExterior");
};
buildingButton33.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_windmill1", "medievalExterior");
};
buildingButton34.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("exterior_windmill2", "medievalExterior");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Ground Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const groundBackButton = document.getElementById("groundBackButton");
const groundButton1 = document.getElementById("button_ground1");
const groundButton2 = document.getElementById("button_ground2");
const groundButton3 = document.getElementById("button_ground3");
const groundButton4 = document.getElementById("button_ground4");
const groundButton5 = document.getElementById("button_ground5");
const groundButton6 = document.getElementById("button_ground6");
const groundButton7 = document.getElementById("button_ground7");
const groundButton8 = document.getElementById("button_ground8");
const groundButton9 = document.getElementById("button_ground9");
const groundButton10 = document.getElementById("button_ground10");
const groundButton11 = document.getElementById("button_ground11");
const groundButton12 = document.getElementById("button_ground12");

groundButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("ground1", "topDownInterior");
};

groundButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("ground2", "topDownInterior");
};

groundButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("ground3", "topDownInterior");
};

groundButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("ground4", "topDownInterior");
};

groundButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("ground5", "topDownInterior");
};

groundButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Ground1", "topdownDungeon");
};

groundButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Ground1b", "topdownDungeon");
};

groundButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Ground1c", "topdownDungeon");
};
groundButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Ground2a", "topdownDungeon");
};
groundButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Ground3a", "topdownDungeon");
};
groundButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Ground3b", "topdownDungeon");
};
groundButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Ground3c", "topdownDungeon");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Wall Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const wallBackButton = document.getElementById("wallBackButton");
const wallButton1 = document.getElementById("button_wall1");
const wallButton2 = document.getElementById("button_wall2");
const wallButton3 = document.getElementById("button_wall3");
const wallButton4 = document.getElementById("button_wall4");
const wallButton5 = document.getElementById("button_wall5");
const wallButton6 = document.getElementById("button_wall6");
const wallButton7 = document.getElementById("button_wall7");
const wallButton8 = document.getElementById("button_wall8");
const wallButton9 = document.getElementById("button_wall9");

wallButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Wall1a", "topDownInterior");
};

wallButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Wall1b", "topDownInterior");
};

wallButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Wall1c", "topDownInterior");
};

wallButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Wall2a", "topDownInterior");
};

wallButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Wall2b", "topDownInterior");
};

wallButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Wall2c", "topDownInterior");
};

wallButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Wall3a", "topDownInterior");
};

wallButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Wall3b", "topDownInterior");
};
wallButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("Wall3c", "topDownInterior");
};
////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Prop Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const PropBackButton = document.getElementById("propBackButton");
const propButton1 = document.getElementById("button_prop1");
const propButton2 = document.getElementById("button_prop2");
const propButton3 = document.getElementById("button_prop3");
const propButton4 = document.getElementById("button_prop4");
const propButton5 = document.getElementById("button_prop5");
const propButton6 = document.getElementById("button_prop6");
const propButton7 = document.getElementById("button_prop7");
const propButton8 = document.getElementById("button_prop8");
const propButton9 = document.getElementById("button_prop9");
const propButton10 = document.getElementById("button_prop10");
const propButton11 = document.getElementById("button_prop11");
const propButton12 = document.getElementById("button_prop12");
const propButton13 = document.getElementById("button_prop13");
const propButton14 = document.getElementById("button_prop14");
const propButton15 = document.getElementById("button_prop15");
const propButton16 = document.getElementById("button_prop16");
const propButton17 = document.getElementById("button_prop17");
const propButton18 = document.getElementById("button_prop18");
const propButton19 = document.getElementById("button_prop19");
const propButton20 = document.getElementById("button_prop20");
const propButton21 = document.getElementById("button_prop21");
const propButton22 = document.getElementById("button_prop22");
const propButton23 = document.getElementById("button_prop23");
const propButton24 = document.getElementById("button_prop24");
const propButton25 = document.getElementById("button_prop25");
propButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("barrel1", "topDownInterior");
};

propButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("barrel2", "topDownInterior");
};
propButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("crate1", "topDownInterior");
};
propButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("crate4", "topDownInterior");
};
propButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("crate5", "topDownInterior");
};
propButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("hearth1", "topDownInterior");
};
propButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("stool1", "topDownInterior");
};
propButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("table1", "topDownInterior");
};
propButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("table2", "topDownInterior");
};
propButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("table3", "topDownInterior");
};
propButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("table4", "topDownInterior");
};
propButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tavernbox1", "topDownInterior");
};
propButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tavernbox2", "topDownInterior");
};
propButton13.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("market_a", "topdownCity");
};
propButton14.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("market_b", "topdownCity");
};
propButton15.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("shed_a", "topdownCity");
};
propButton16.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("well_a", "topdownCity");
};
propButton17.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("bridge_a", "topdownCity");
};
propButton18.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cart_a", "topdownCity");
};
propButton19.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cargo_a", "topdownCity");
};
propButton20.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cargo_b", "topdownCity");
};
propButton21.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rowing-boat", "ships");
};
propButton22.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("sailboat-b", "ships");
};
propButton23.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("pirate-ship", "ships");
};
propButton24.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("whale-ship", "ships");
};
propButton25.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("ghost-ship", "ships");
};
////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Figurine Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const figurineBackButton = document.getElementById("figurineBackButton");
const figurineButton1 = document.getElementById("button_figurine1");
const figurineButton2 = document.getElementById("button_figurine2");
const figurineButton3 = document.getElementById("button_figurine3");
const figurineButton4 = document.getElementById("button_figurine4");
const figurineButton5 = document.getElementById("button_figurine5");
const figurineButton6 = document.getElementById("button_figurine6");
const figurineButton7 = document.getElementById("button_figurine7");
const figurineButton8 = document.getElementById("button_figurine8");
const figurineButton9 = document.getElementById("button_figurine9");
const figurineButton10 = document.getElementById("button_figurine10");
const figurineButton11 = document.getElementById("button_figurine11");
const figurineButton12 = document.getElementById("button_figurine12");
const figurineButton13 = document.getElementById("button_figurine13");
const figurineButton14 = document.getElementById("button_figurine14");
const figurineButton15 = document.getElementById("button_figurine15");
const figurineButton16 = document.getElementById("button_figurine16");
const figurineButton17 = document.getElementById("button_figurine17");
const figurineButton18 = document.getElementById("button_figurine18");
const figurineButton19 = document.getElementById("button_figurine19");
const figurineButton20 = document.getElementById("button_figurine20");
const figurineButton21 = document.getElementById("button_figurine21");
const figurineButton22 = document.getElementById("button_figurine22");
const figurineButton23 = document.getElementById("button_figurine23");
const figurineButton24 = document.getElementById("button_figurine24");
const figurineButton25 = document.getElementById("button_figurine25");
const figurineButton26 = document.getElementById("button_figurine26");
const figurineButton27 = document.getElementById("button_figurine27");
const figurineButton28 = document.getElementById("button_figurine28");
const figurineButton29 = document.getElementById("button_figurine29");
const figurineButton30 = document.getElementById("button_figurine30");
const figurineButton31 = document.getElementById("button_figurine31");
const figurineButton32 = document.getElementById("button_figurine32");
const figurineButton33 = document.getElementById("button_figurine33");
const figurineButton34 = document.getElementById("button_figurine34");
const figurineButton35 = document.getElementById("button_figurine35");
const figurineButton36 = document.getElementById("button_figurine36");
const figurineButton37 = document.getElementById("button_figurine37");
const figurineButton38 = document.getElementById("button_figurine38");
const figurineButton39 = document.getElementById("button_figurine39");
const figurineButton40 = document.getElementById("button_figurine40");
const figurineButton41 = document.getElementById("button_figurine41");
const figurineButton42 = document.getElementById("button_figurine42");

figurineButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("bandit_a.glb", "figurine");
};
figurineButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("bandit_b.glb", "figurine");
};
figurineButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("dragonborndruid.glb", "figurine");
};
figurineButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("dwarfbarbarian.glb", "figurine");
};
figurineButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("dwarfdruid.glb", "figurine");
};
figurineButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("dwarffighter_a.glb", "figurine");
};
figurineButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("dwarffighter_b.glb", "figurine");
};
figurineButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("eldritchknight.glb", "figurine");
};
figurineButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("elfbarbarian.glb", "figurine");
};
figurineButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("elfbard.glb", "figurine");
};
figurineButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("elfdruid_a.glb", "figurine");
};
figurineButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("elfdruid_b.glb", "figurine");
};
figurineButton13.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("elfpaladin.glb", "figurine");
};
figurineButton14.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("femalefighter.glb", "figurine");
};
figurineButton15.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("femalemonk.glb", "figurine");
};
figurineButton16.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("femaleranger.glb", "figurine");
};
figurineButton17.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("gnollarcher.glb", "figurine");
};
figurineButton18.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("gnollskirmisher.glb", "figurine");
};
figurineButton19.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("gnomebard.glb", "figurine");
};
figurineButton20.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goblinarcher.glb", "figurine");
};
figurineButton21.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goblinchief.glb", "figurine");
};
figurineButton22.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goblinking.glb", "figurine");
};
figurineButton23.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goblinsoldier.glb", "figurine");
};
figurineButton24.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("halflingranger.glb", "figurine");
};
figurineButton25.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("hillgiant.glb", "figurine");
};
figurineButton26.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("humanfighter.glb", "figurine");
};
figurineButton27.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("malebard.glb", "figurine");
};
figurineButton28.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("malecleric.glb", "figurine");
};
figurineButton29.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("maleranger_a.glb", "figurine");
};
figurineButton30.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("maleranger_b.glb", "figurine");
};
figurineButton31.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("ogre_b.glb", "figurine");
};
figurineButton32.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("ogre_a.glb", "figurine");
};
figurineButton33.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orc_a_fig.glb", "figurine");
};
figurineButton34.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orc_b_fig.glb", "figurine");
};
figurineButton35.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orc_c_fig.glb", "figurine");
};
figurineButton36.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orcbabarian_a.glb", "figurine");
};
figurineButton37.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orcbarbarian_b.glb", "figurine");
};
figurineButton38.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orcfighter.glb", "figurine");
};
figurineButton39.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("owlbeer_a.glb", "figurine");
};
figurineButton40.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("skeleton_a.glb", "figurine");
};
figurineButton41.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("troll_a.glb", "figurine");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Hero Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const characterBackButton = document.getElementById("characterBackButton");
const characterButton1 = document.getElementById("button_character1");
const characterButton2 = document.getElementById("button_character2");
const characterButton3 = document.getElementById("button_character3");
const characterButton4 = document.getElementById("button_character4");
const characterButton5 = document.getElementById("button_character5");
const characterButton6 = document.getElementById("button_character6");
const characterButton7 = document.getElementById("button_character7");
const characterButton8 = document.getElementById("button_character8");
const characterButton9 = document.getElementById("button_character9");
const characterButton10 = document.getElementById("button_character10");
const characterButton11 = document.getElementById("button_character11");
const characterButton12 = document.getElementById("button_character12");
const characterButton13 = document.getElementById("button_character13");

characterButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("female_archer.glb", "hero");
};

characterButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("dwarf_a.glb", "hero");
};

characterButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("knight_a.glb", "hero");
};

characterButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("knight_b.glb", "hero");
};

characterButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("demon_a.glb", "hero");
};

characterButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("red_knight_a.glb", "hero");
};

characterButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("barbarian_a.glb", "hero");
};

characterButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("old_knight_a.glb", "hero");
};

characterButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("ent_a.glb", "hero");
};

characterButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("soldier_a.glb", "hero");
};

characterButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("soldier_b.glb", "hero");
};

characterButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("soldier_c.glb", "hero");
};

characterButton13.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("soldier_d.glb", "hero");
};
////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Monster Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const MonsterBackButton = document.getElementById("monsterBackButton");
const monsterButton1 = document.getElementById("button_monster1");
const monsterButton2 = document.getElementById("button_monster2");
const monsterButton3 = document.getElementById("button_monster3");
const monsterButton4 = document.getElementById("button_monster4");
const monsterButton5 = document.getElementById("button_monster5");
const monsterButton6 = document.getElementById("button_monster6");
const monsterButton7 = document.getElementById("button_monster7");
const monsterButton8 = document.getElementById("button_monster8");
const monsterButton9 = document.getElementById("button_monster9");
const monsterButton10 = document.getElementById("button_monster10");
const monsterButton11 = document.getElementById("button_monster11");
const monsterButton12 = document.getElementById("button_monster12");
const monsterButton13 = document.getElementById("button_monster13");
const monsterButton14 = document.getElementById("button_monster14");
const monsterButton15 = document.getElementById("button_monster15");
const monsterButton16 = document.getElementById("button_monster16");

monsterButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orc_a.glb", "hero");
};
monsterButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orc_b.glb", "hero");
};
monsterButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orc_c.glb", "hero");
};
monsterButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orc_d.glb", "hero");
};
monsterButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("orc_e.glb", "hero");
};
monsterButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("urakai_a.glb", "hero");
};
monsterButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("urakai_b.glb", "hero");
};
monsterButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("urakai_c.glb", "hero");
};

monsterButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("urakai_d.glb", "hero");
};
monsterButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("urakai_e.glb", "hero");
};
monsterButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goblin_a.glb", "hero");
};
monsterButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goblin_b.glb", "hero");
};
monsterButton13.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goblin_c.glb", "hero");
};
monsterButton14.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goblin_d.glb", "hero");
};
monsterButton15.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goblin_e.glb", "hero");
};
monsterButton16.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("black_dragon.glb", "hero");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// creature Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const creatureBackButton = document.getElementById("creatureBackButton");
const creatureButton1 = document.getElementById("button_creature1");
const creatureButton2 = document.getElementById("button_creature2");
const creatureButton3 = document.getElementById("button_creature3");
const creatureButton4 = document.getElementById("button_creature4");
const creatureButton5 = document.getElementById("button_creature5");
const creatureButton6 = document.getElementById("button_creature6");
const creatureButton7 = document.getElementById("button_creature7");
const creatureButton8 = document.getElementById("button_creature8");
const creatureButton9 = document.getElementById("button_creature9");
const creatureButton10 = document.getElementById("button_creature10");

creatureButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("bear_a.glb", "hero");
};
creatureButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("bat_a.glb", "hero");
};
creatureButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("boar_a.glb", "hero");
};
creatureButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cat_a.glb", "hero");
};
creatureButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("chicken_a.glb", "hero");
};
creatureButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cow_a.glb", "hero");
};
creatureButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("crocodile_a.glb", "hero");
};
creatureButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("eagle_a.glb", "hero");
};

creatureButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("goat_a.glb", "hero");
};
creatureButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("wolf_a.glb", "hero");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Nature Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const natureLayoutBackButton = document.getElementById(
  "naturelayoutBackButton"
);
const natureLayoutButton1 = document.getElementById("button_naturelayout1");
const natureLayoutButton2 = document.getElementById("button_naturelayout2");
const natureLayoutButton3 = document.getElementById("button_naturelayout3");
const natureLayoutButton4 = document.getElementById("button_naturelayout4");
const natureLayoutButton5 = document.getElementById("button_naturelayout5");
const natureLayoutButton6 = document.getElementById("button_naturelayout6");
const natureLayoutButton7 = document.getElementById("button_naturelayout7");
const natureLayoutButton8 = document.getElementById("button_naturelayout8");
const natureLayoutButton9 = document.getElementById("button_naturelayout9");
const natureLayoutButton10 = document.getElementById("button_naturelayout10");
const natureLayoutButton11 = document.getElementById("button_naturelayout11");
const natureLayoutButton12 = document.getElementById("button_naturelayout12");
const natureLayoutButton13 = document.getElementById("button_naturelayout13");
const natureLayoutButton14 = document.getElementById("button_naturelayout14");
const natureLayoutButton15 = document.getElementById("button_naturelayout15");
const natureLayoutButton16 = document.getElementById("button_naturelayout16");
const natureLayoutButton17 = document.getElementById("button_naturelayout17");
const natureLayoutButton18 = document.getElementById("button_naturelayout18");
const natureLayoutButton19 = document.getElementById("button_naturelayout19");
const natureLayoutButton20 = document.getElementById("button_naturelayout20");
const natureLayoutButton21 = document.getElementById("button_naturelayout21");
const natureLayoutButton22 = document.getElementById("button_naturelayout22");
natureLayoutButton1.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_r");
};
natureLayoutButton2.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_s_lrg");
};
natureLayoutButton3.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_c");
};
natureLayoutButton4.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_d");
};
natureLayoutButton5.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_e");
};
natureLayoutButton6.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_f");
};
natureLayoutButton7.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_g");
};
natureLayoutButton8.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_h");
};
natureLayoutButton9.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_i");
};
natureLayoutButton10.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_j");
};
natureLayoutButton11.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_k");
};
natureLayoutButton12.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_l");
};
natureLayoutButton13.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_m");
};
natureLayoutButton14.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_n");
};
natureLayoutButton15.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_o");
};
natureLayoutButton16.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_p");
};
natureLayoutButton17.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_q");
};
natureLayoutButton18.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("Felt-min");
};
natureLayoutButton19.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("Felt_b");
};
natureLayoutButton20.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("Felt_c");
};
natureLayoutButton21.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_snow_a");
};
natureLayoutButton22.onclick = function () {
  SetRightMenuInactive();
  SetplayMat("layout_ocean");
};

const natureTilesBackButton = document.getElementById("naturetilesBackButton");
const natureTilesButton1 = document.getElementById("button_naturetiles1");
const natureTilesButton2 = document.getElementById("button_naturetiles2");
const natureTilesButton3 = document.getElementById("button_naturetiles3");
const natureTilesButton4 = document.getElementById("button_naturetiles4");
const natureTilesButton5 = document.getElementById("button_naturetiles5");
const natureTilesButton6 = document.getElementById("button_naturetiles6");
const natureTilesButton7 = document.getElementById("button_naturetiles7");
const natureTilesButton8 = document.getElementById("button_naturetiles8");
const natureTilesButton9 = document.getElementById("button_naturetiles9");
const natureTilesButton10 = document.getElementById("button_naturetiles10");
const natureTilesButton11 = document.getElementById("button_naturetiles11");
const natureTilesButton12 = document.getElementById("button_naturetiles12");
const natureTilesButton13 = document.getElementById("button_naturetiles13");
const natureTilesButton14 = document.getElementById("button_naturetiles14");
const natureTilesButton15 = document.getElementById("button_naturetiles15");
const natureTilesButton16 = document.getElementById("button_naturetiles16");
const natureTilesButton17 = document.getElementById("button_naturetiles17");
const natureTilesButton18 = document.getElementById("button_naturetiles18");
const natureTilesButton19 = document.getElementById("button_naturetiles19");
const natureTilesButton20 = document.getElementById("button_naturetiles20");

natureTilesButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile1", "naturetiles");
};
natureTilesButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile2", "naturetiles");
};
natureTilesButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile3", "naturetiles");
};
natureTilesButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile4", "naturetiles");
};
natureTilesButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile5", "naturetiles");
};
natureTilesButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile6", "naturetiles");
};
natureTilesButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile7", "naturetiles");
};
natureTilesButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile8", "naturetiles");
};
natureTilesButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile9", "naturetiles");
};
natureTilesButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile10", "naturetiles");
};
natureTilesButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile11", "naturetiles");
};
natureTilesButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile12", "naturetiles");
};
natureTilesButton13.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile13", "naturetiles");
};
natureTilesButton14.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile14", "naturetiles");
};
natureTilesButton15.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile15", "naturetiles");
};
natureTilesButton16.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile16", "naturetiles");
};
natureTilesButton17.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile17", "naturetiles");
};
natureTilesButton18.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile18", "naturetiles");
};
natureTilesButton19.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile19", "naturetiles");
};
natureTilesButton20.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("naturetile20", "naturetiles");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Plant Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const plantsBackButton = document.getElementById("plantsBackButton");
const plantsButton1 = document.getElementById("button_plants1");
const plantsButton2 = document.getElementById("button_plants2");
const plantsButton3 = document.getElementById("button_plants3");
const plantsButton4 = document.getElementById("button_plants4");
const plantsButton5 = document.getElementById("button_plants5");
const plantsButton6 = document.getElementById("button_plants6");
const plantsButton7 = document.getElementById("button_plants7");
const plantsButton8 = document.getElementById("button_plants8");
const plantsButton9 = document.getElementById("button_plants9");
const plantsButton10 = document.getElementById("button_plants10");
const plantsButton11 = document.getElementById("button_plants11");
const plantsButton12 = document.getElementById("button_plants12");
const plantsButton13 = document.getElementById("button_plants13");
const plantsButton14 = document.getElementById("button_plants14");
const plantsButton15 = document.getElementById("button_plants15");
const plantsButton16 = document.getElementById("button_plants16");
const plantsButton17 = document.getElementById("button_plants17");
const plantsButton18 = document.getElementById("button_plants18");
const plantsButton19 = document.getElementById("button_plants19");
const plantsButton20 = document.getElementById("button_plants20");
const plantsButton21 = document.getElementById("button_plants21");
const plantsButton22 = document.getElementById("button_plants22");
const plantsButton23 = document.getElementById("button_plants23");
plantsButton1.onclick = function () {
  SetRightMenuInactive();
  TREEPAINTER.FillObjectPool(10, "plantTrees");

  ASSETLOADER.LoadModel("tree6", "plantTrees");
};

plantsButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("bush1", "plantTrees");
};

plantsButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("bush2", "plantTrees");
};

plantsButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("bush3", "plantTrees");
};

plantsButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("bush4", "plantTrees");
};
plantsButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("stump_a", "plantTrees");
};
plantsButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree1a", "plantTrees");
};
plantsButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree1b", "plantTrees");
};
plantsButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree1c", "plantTrees");
};
plantsButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree2a", "plantTrees");
};
plantsButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree2b", "plantTrees");
};
plantsButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree3a", "plantTrees");
};
plantsButton13.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree3b", "plantTrees");
};
plantsButton14.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree4a", "plantTrees");
};
plantsButton15.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree4b", "plantTrees");
};
plantsButton16.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree5a", "plantTrees");
};
plantsButton17.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree5b", "plantTrees");
};
plantsButton18.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree6", "plantTrees");
};
plantsButton19.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree7", "plantTrees");
};
plantsButton20.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree10", "plantTrees");
};
plantsButton21.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree11", "plantTrees");
};
plantsButton22.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("tree12", "plantTrees");
};
plantsButton23.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("old_tree_rock", "topdownCity");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Rock Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const rocksBackButton = document.getElementById("rocksBackButton");
const rocksButton1 = document.getElementById("button_rocks1");
const rocksButton2 = document.getElementById("button_rocks2");
const rocksButton3 = document.getElementById("button_rocks3");
const rocksButton4 = document.getElementById("button_rocks4");
const rocksButton5 = document.getElementById("button_rocks5");
const rocksButton6 = document.getElementById("button_rocks6");
const rocksButton7 = document.getElementById("button_rocks7");
const rocksButton8 = document.getElementById("button_rocks8");
const rocksButton9 = document.getElementById("button_rocks9");
const rocksButton10 = document.getElementById("button_rocks10");

const rocksButton11 = document.getElementById("button_rocks11");
const rocksButton12 = document.getElementById("button_rocks12");
const rocksButton13 = document.getElementById("button_rocks13");
const rocksButton14 = document.getElementById("button_rocks14");
const rocksButton15 = document.getElementById("button_rocks15");
const rocksButton16 = document.getElementById("button_rocks16");
const rocksButton17 = document.getElementById("button_rocks17");
const rocksButton18 = document.getElementById("button_rocks18");
const rocksButton19 = document.getElementById("button_rocks19");
const rocksButton20 = document.getElementById("button_rocks20");

const rocksButton21 = document.getElementById("button_rocks21");
const rocksButton22 = document.getElementById("button_rocks22");
const rocksButton23 = document.getElementById("button_rocks23");
const rocksButton24 = document.getElementById("button_rocks24");
const rocksButton25 = document.getElementById("button_rocks25");
const rocksButton26 = document.getElementById("button_rocks26");
const rocksButton27 = document.getElementById("button_rocks27");
const rocksButton28 = document.getElementById("button_rocks28");
const rocksButton29 = document.getElementById("button_rocks29");
const rocksButton30 = document.getElementById("button_rocks30");

const rocksButton31 = document.getElementById("button_rocks31");
const rocksButton32 = document.getElementById("button_rocks32");
const rocksButton33 = document.getElementById("button_rocks33");
const rocksButton34 = document.getElementById("button_rocks34");
const rocksButton35 = document.getElementById("button_rocks35");
const rocksButton36 = document.getElementById("button_rocks36");

rocksButton1.onclick = function () {
  SetRightMenuInactive();
  TREEPAINTER.FillObjectPool(10, "rocks");

  ASSETLOADER.LoadModel("random", "rocks");
};
rocksButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("RockB_1_00", "rocks");
};
rocksButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("RockB_1_01", "rocks");
};
rocksButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("RockB_1_02", "rocks");
};
rocksButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("RockB_1_03", "rocks");
};
rocksButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("RockB_1_04", "rocks");
};
rocksButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("RockB_1_05", "rocks");
};
rocksButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("RockB_1_06", "rocks");
};
rocksButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("RockB_1_07", "rocks");
};
rocksButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("RockB_1_08", "rocks");
};
rocksButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_1_grey.glb", "rocks");
};
rocksButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_2_grey.glb", "rocks");
};
rocksButton13.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_3_grey.glb", "rocks");
};
rocksButton14.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_4_grey.glb", "rocks");
};
rocksButton15.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_5_grey.glb", "rocks");
};
rocksButton16.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_6_grey.glb", "rocks");
};

rocksButton17.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_1_grey.glb", "rocks");
};
rocksButton18.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_2_grey.glb", "rocks");
};
rocksButton19.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_3_grey.glb", "rocks");
};
rocksButton20.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_4_grey.glb", "rocks");
};
rocksButton21.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_5_grey.glb", "rocks");
};
rocksButton22.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_6_grey.glb", "rocks");
};
rocksButton23.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_7_grey.glb", "rocks");
};

rocksButton24.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_1_snow.glb", "rocks");
};
rocksButton25.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_2_snow.glb", "rocks");
};
rocksButton26.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_3_snow.glb", "rocks");
};
rocksButton27.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_4_snow.glb", "rocks");
};
rocksButton28.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_5_snow.glb", "rocks");
};
rocksButton29.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("rock_6_snow.glb", "rocks");
};

rocksButton30.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_1_snow.glb", "rocks");
};
rocksButton31.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_2_snow.glb", "rocks");
};
rocksButton32.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_3_snow.glb", "rocks");
};
rocksButton33.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_4_snow.glb", "rocks");
};
rocksButton34.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_5_snow.glb", "rocks");
};
rocksButton35.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_6_snow.glb", "rocks");
};
rocksButton36.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("vjr_rock_7_snow.glb", "rocks");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Magic Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const magicBackButton = document.getElementById("magicBackButton");
const magicButton1 = document.getElementById("button_magic1");
const magicButton2 = document.getElementById("button_magic2");
const magicButton3 = document.getElementById("button_magic3");
const magicButton4 = document.getElementById("button_magic4");
const magicButton5 = document.getElementById("button_magic5");
const magicButton6 = document.getElementById("button_magic6");
const magicButton7 = document.getElementById("button_magic7");
const magicButton8 = document.getElementById("button_magic8");
const magicButton9 = document.getElementById("button_magic9");
const magicButton10 = document.getElementById("button_magic10");
const magicButton11 = document.getElementById("button_magic11");
const magicButton12 = document.getElementById("button_magic12");

magicButton1.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("square_a", "magic");
};

magicButton2.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("square_b", "magic");
};
magicButton3.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("square_c", "magic");
};
magicButton4.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("circle_a", "magic");
};
magicButton5.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("circle_b", "magic");
};
magicButton6.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("circle_c", "magic");
};
magicButton7.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cone_a", "magic");
};
magicButton8.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cone_b", "magic");
};
magicButton9.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cone_c", "magic");
};
magicButton10.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("cube", "basic");
};
magicButton11.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("sphere", "basic");
};
magicButton12.onclick = function () {
  SetRightMenuInactive();
  ASSETLOADER.LoadModel("triangle", "basic");
};

////////////////////////////////////////////////////////////////////////////////
/////////////////////////// Light Objects /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

button_light1.onclick = function () {
  // SetRightMenuInactive();
  LIGHTS.CreateLight("#ffffff");
};
button_light2.onclick = function () {
  // SetRightMenuInactive();
  LIGHTS.CreateLight("#ff0000");
};
button_light3.onclick = function () {
  // SetRightMenuInactive();
  LIGHTS.CreateLight("#fc00ff");
};
button_light4.onclick = function () {
  // SetRightMenuInactive();
  LIGHTS.CreateLight("#00deff");
};
button_light5.onclick = function () {
  // SetRightMenuInactive();
  LIGHTS.CreateLight("#ffa200");
};

export function SetRightMenuActive(windowToActivate) {
  gsap.to(myLayoutWindow, {
    opacity: 1,
    duration: 0.2,
  });
  gsap.to(myMonsterWindow, {
    opacity: 1,
    duration: 0.2,
  });
}

function SetRightMenuInactive() {
  gsap.to(myLayoutWindow, {
    opacity: 0.3,
    duration: 0.2,
  });
  gsap.to(myMonsterWindow, {
    opacity: 0.3,
    duration: 0.2,
  });
}

LayoutBackButton.onclick = function () {
  AnimationRightSideMenu(myLayoutWindow, 480, 0.5, 0.5);
  AnimateBottomNavMenu(buildingButtons, 0, 1, 0.1);
  currentOpenRightSideWindow = null;
};
BuildingBackButton.onclick = function () {
  AnimationRightSideMenu(myBuildingWindow, 480, 0.5, 0.5);
  AnimateBottomNavMenu(buildingButtons, 0, 1, 0.1);
  currentOpenRightSideWindow = null;
};
groundBackButton.onclick = function () {
  AnimationRightSideMenu(myGroundWindow, 480, 0.5, 0.5);
  AnimateBottomNavMenu(buildingButtons, 0, 1, 0.1);
  currentOpenRightSideWindow = null;
};
wallBackButton.onclick = function () {
  AnimationRightSideMenu(myWallWindow, 480, 0.5, 0.5);
  AnimateBottomNavMenu(buildingButtons, 0, 1, 0.1);
  currentOpenRightSideWindow = null;
};
PropBackButton.onclick = function () {
  AnimationRightSideMenu(myPropWindow, 480, 0.5, 0.5);
  AnimateBottomNavMenu(buildingButtons, 0, 1, 0.1);
  currentOpenRightSideWindow = null;
};
characterBackButton.onclick = function () {
  AnimationRightSideMenu(myCharacterWindow, 480, 0.5, 0.5);
  AnimateBottomNavMenu(characterButtons, 0, 1, 0.1);
  currentOpenRightSideWindow = null;
};
MonsterBackButton.onclick = function () {
  AnimationRightSideMenu(myMonsterWindow, 480, 0.5, 0.5);
  AnimateBottomNavMenu(characterButtons, 0, 1, 0.1);
  currentOpenRightSideWindow = null;
};
figurineBackButton.onclick = function () {
  AnimationRightSideMenu(myFigurineWindow, 480, 0.5, 0.5);
  AnimateBottomNavMenu(characterButtons, 0, 1, 0.1);
  currentOpenRightSideWindow = null;
};
creatureBackButton.onclick = function () {
  AnimationRightSideMenu(myCreatureWindow, 480, 0.5, 0.5);
  AnimateBottomNavMenu(characterButtons, 0, 1, 0.1);
  currentOpenRightSideWindow = null;
};
natureLayoutBackButton.onclick = function () {
  CloseRightSideNav();
};
magicBackButton.onclick = function () {
  CloseRightSideNav();
};
plantsBackButton.onclick = function () {
  CloseRightSideNav();
};
natureTilesBackButton.onclick = function () {
  CloseRightSideNav();
};
rocksBackButton.onclick = function () {
  CloseRightSideNav();
};

///////////////// Campaign Chuff /////////////////

button_campaign.onclick = function () {
  AnimateSideBar(0, 0.2);
  CloseRightSideNav();
  CloseBottomNav();
  architectMenuOpen = false;

  for (let i = 0; i < sideBarButtons.length; i++) {
    sideBarButtons[i].style.pointerEvents = "none";
  }

  const campaignPanel = document.getElementById("campaignList");
  loadCampaign();
  gsap.to(campaignPanel, { y: 0, duration: 1 });
};
const campaignPanel = document.getElementById("campaignList");
gsap.set(campaignPanel, { y: -250, duration: 1 });

const campaignCloseButton = document.getElementById("campaignCloseButton");

campaignCloseButton.onclick = function () {
  gsap.to(campaignPanel, { y: -250, duration: 0.5 });

  AnimateSideBar(1, 1);
  for (let i = 0; i < sideBarButtons.length; i++) {
    sideBarButtons[i].style.pointerEvents = "all";
  }
};

const newMapButton = document.getElementById("newCampaignItem");
newMapButton.onclick = function () {
  newMap();
};
