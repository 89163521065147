import * as THREE from "three";
import { loadFile } from "../managers/loadingmanagers";

export function GetModel(modelID) {
  if (modelID)
    if (modelID.includes("naturetile"))
      return "./models/naturetiles/naturetilea.gltf";
    else return null;
}

export async function GetModelMaterial(modelID) {
  let mat;

  if (modelID.includes("naturetile1")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/1 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile2")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/8 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile3")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/9 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile4")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/18 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile5")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/25 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile6")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/26 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile7")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/27 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile8")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/28 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile9")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/33 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile10")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/34 - Grass.webp"),
    });
  }
  if (modelID.includes("naturetile11")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/35 - Road.webp"),
    });
  }
  if (modelID.includes("naturetile12")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/36 - Road.webp"),
    });
  }
  if (modelID.includes("naturetile13")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/37 - Road.webp"),
    });
  }
  if (modelID.includes("naturetile14")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/38 - Road.webp"),
    });
  }
  if (modelID.includes("naturetile15")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/39 - Road.webp"),
    });
  }
  if (modelID.includes("naturetile16")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/40 - Road.webp"),
    });
  }
  if (modelID.includes("naturetile17")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/41 - Water.webp"),
    });
  }
  if (modelID.includes("naturetile18")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/42 - Water.webp"),
    });
  }
  if (modelID.includes("naturetile19")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/43 - Water.webp"),
    });
  }
  if (modelID.includes("naturetile20")) {
    mat = new THREE.MeshStandardMaterial({
      map: await loadFile(".webp", "./textures/tiles/44 - Water.webp"),
    });
  }

  mat.map.name = modelID;
  return mat;
}
