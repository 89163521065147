import gsap from "gsap";
import * as THREE from "three";
import { ref, set, get, once } from "firebase/database";
import { getDB } from "./firebaseConfig";
import * as GAMESTATE from "../gamestate";
import { setAllowUpdateDatabase, setInAMultiplayerSession } from "./status";
import { setGameId } from "./roomData";
import { switchMultiplayerButtonsOn } from "./uiMultiplayer";
import { SetUpListener } from "./listenToData";
import copyTextToClipboard from "../../utils/copyTextToClipboard.js";
import { setUserData } from "./userData";
import { showChatMessage } from "./chatbox";
import { signInAnon } from "./signIn";
import showErrorMessage from "./errorMessage";
export async function JoinRoom(username, roomId, avatarPath) {
  GAMESTATE.ClearGame(true, false);

  get(ref(getDB(), roomId + "/game/"))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        const users = [];
        Object.values(snapshot.val().users).forEach((val) => users.push(val));

        if (users.length <= 6) {
          setInAMultiplayerSession(true);
          setGameId(roomId);
          setAllowUpdateDatabase(false);

          const randPlayerID = (Math.random() + 1).toString(36).substring(2);
          const roomInfo = document.getElementsByClassName(
            "multiplayer-room-info"
          )[0];
          let color = Math.floor(Math.random() * 16777215).toString(16);
          roomInfo.style.display = "block";
          roomInfo.children[0].innerHTML = username;
          roomInfo.children[1].innerHTML = "Room ID: " + roomId;

          roomInfo.onclick = () => {
            copyTextToClipboard(roomId);
          };

          gsap.to(document.getElementById("myJoinRoomWindow"), {
            scale: 0,
            duration: 0.2,
          });
          switchMultiplayerButtonsOn();
          SetUpListener();
          showChatMessage("You have joine the room! See info below.");

          signInAnon(randPlayerID, username, avatarPath, roomId, color);
        } else {
          showErrorMessage("Room is full! (Maximum of 6)");
        }
      } else {
        showErrorMessage("Room does not exist!");
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
