import gsap from "gsap";
import { getUserData } from "./userData";

let tl;
export function showChatMessage(msg, username) {
  const chatbox = document.getElementsByClassName("chat-box");
  const template = document.getElementById("chat-box-template");
  const clone = template.cloneNode(true);
  template.parentNode.appendChild(clone);
  clone.style.display = "block";
  clone.innerHTML = msg;

  if (username) msg = username + " " + msg;

  tl = gsap.timeline();
  tl.to(chatbox, { opacity: 1, duration: 0.5 }).to(chatbox, {
    opacity: 0,
    duration: 0.5,
    delay: 10,
  });
}
