export function GetPaths(path) {
  let texturePaths = [
    "./textures/tiles/layouts/layout_r.webp",
    "./textures/tiles/layouts/layout_s_lrg.webp",
    "./textures/tiles/layouts/layout_c.webp",
    "./textures/tiles/layouts/layout_d.webp",
    "./textures/tiles/layouts/layout_e.webp",
    "./textures/tiles/layouts/layout_f.webp",
    "./textures/tiles/layouts/layout_g.webp",
    "./textures/tiles/layouts/layout_h.webp",
    "./textures/tiles/layouts/layout_i.webp",
    "./textures/tiles/layouts/layout_j.webp",
    "./textures/tiles/layouts/layout_k.webp",
    "./textures/tiles/layouts/layout_l.webp",
    "./textures/tiles/layouts/layout_m.webp",
    "./textures/tiles/layouts/layout_n.webp",
    "./textures/tiles/layouts/layout_o.webp",
    "./textures/tiles/layouts/layout_p.webp",
    "./textures/tiles/layouts/layout_q.webp",
    "./models/tables/Felt-min.webp",
    "./models/tables/Felt_b.webp",
    "./models/tables/Felt_c.webp",
    "./textures/tiles/layouts/layout_snow_a.webp",
    "./textures/tiles/layouts/layout_ocean.webp",
  ];

  let tex;
  for (let i = 0; i < texturePaths.length; i++) {
    if (texturePaths[i].includes(path)) {
      tex = texturePaths[i];
    }
  }

  return tex;
}
