function GetEnemyThreatType() {
    let magicTypes = ["divination", "enchantment", "evocation", "illusion", "necromancy", "summoning"]

    return magicTypes[GetRandomInt(0, magicTypes.length - 1)];
}

function GetSizeOfLocationDiscovery() {
    let sizes = ["small", "modestly-sized", "medium-sized", "large", "massive"]

    return sizes[GetRandomInt(0, sizes.length - 1)];
}

function GetMagicType() {
    let magicTypes = [
        "divination",
        "enchantment",
        "evocation",
        "illusion",
        "necromancy",
        "summoning"
    ]

    return magicTypes[GetRandomInt(0, magicTypes.length - 1)];
}

function GetAgeOfLocationDiscovery() {
    let ages = ["very old", "fairly old", "fairly new", "new"]

    return ages[GetRandomInt(0, ages.length - 1)];
}

function GetMerchantType() {
    let merchantTypes = [

        "general goods", "raw materials", "grain", "livestock", "ale", "wine", "clothing", "jewellery", "weapons", "armor",
        "spices", "tobacco", "slave", "books", "scrolls", "magical supplies and items"
    ]
    return merchantTypes[GetRandomInt(0, merchantTypes.length - 1)];
}

export function GetUrbanLocationDiscovery() {
    let urbanDiscoveries = [
        "You witness a crime! " + GetUrbanCrime() + ".",
        "There is a stampede of " + GetRandomAnimal() + ".",
        "A hooded figure watches you from across the street. As soon as you see them they disappear into the crowd.",
        "A guided tour proceeds past, the guide dropping a piece of knowledge of the city.",
        "A shady figure beckons to you from a nearby alley. They have under-the-counter goods to sell.",
        "Two men are arguing in the street. Does it break into a fist fight?",
        "You come across a note pinned to a post.",
        "A card shark has gathered a small crowd around himself and is performing magic for coin",
        "Thugs are intimidating a nearby" + GetMerchantType() + " merchant for protection money.",
        "Smoke billows out of the windows of a house on the corner. Flames lick up from under the doors.",
        "Several feral dogs have a man cornered in an alley. They look as though they are about to tear him apart!",
        "An explosion rocks the ground as nearby onlookers scream and tumble off their feet.",
        "Thugs have an urchin cornered in an alleyway as you walk past.",
        "You see signs pinned to shop fronts telling of a street party being planned for tomorrow.",
        "A set of gallows have been erected in a market square nearby. A crowd is gathered to witness this execution.",
        "A chamber pot is emptied from a window above and it splashes down onto the street.",
        "A woman leaning over a balcony above the street blows you a kiss.",
        "A man leaning over a balcony above the street winks in your direction.",
        "A pair of guards stare you down. They approach and ask you what your business is.",
        "A bard stands in a doorway singing songs about the local political class. They are getting heckled.",
        "A beggar notices you and offers to give you a guided tour in exchange for coin.",
        "A bar fight bursts out of the front of a local tavern and spills out onto the street.",
        "A drunkard stumbles out of a nearby bar and tries to get your attention.",
        "You notice a sewer gate which is slightly ajar suddenly close. A shadow moves behind the bars.",
        "A man tumbles out of a first floor window and crashes onto the street.",
        "A nearby horse gets spooked and careens into the street, crashing into people and rearing up on it's hind legs.",
        "A storyteller stands on a soapbox and regails bystanders with local legends.",
        "You notice a pickpocket slip their hand into a womans cloak ahead.",
        "You notice a dice game taking place in an alleyway nearby.",
        "There are sounds of a struggle taking place in an alleyway nearby.",
        "A disturbance in a Blacksmith's shop nearby draws your attention. The Blacksmith is complaining of sabotage, as all of his weapons are coming out of the fire rusted.",
        "There's a figure in a black cloak following you.",
        "There's a merchant strike in the road up ahead. They are protesting high taxes levied on their wares.",
        "A group of guards are searching people at a checkpoint up ahead, looking for contraband.",
        "A Captain and his guardsmen are questioning people about a wanted criminal. The Captain's eyes rest on you as you pass.",
        "A wizard has lost control of their magic and created a portal in the doorway of a nearby building.",
        "A group of wizards are complaining loudly to a pair of guards. Someone has been buying up all their components, which are now selling at a premium.",
        "A huge arcane fire has erupted in a cluster of nearby buildings. A wizard is beating his apprentice in the street, who apparently is at fault.",
        "A pair of guards are questioning locals about the cases of disappearing corpses from the city morgue.",
        "A prominent politician has passed away and a procession is taking place up ahead.",
        "There's a shortage of general goods and food as there have been an increase in hijackings taking place outside town.",
        "A group of distraught jewelers are giving their statement to a Captain and his guardsmen as a robbery seems to have taken place.",
        "A prominent local noble has been murdered. The guardsmen are investigating the scene of the murder in a nearby building.",
        "A wagon passes you and seems to be being escorted by heavily armed thugs, hiding their weapons beneath their cloaks.",
        "A Captain and their guardsmen are investigating a tunnel that has been unearthed in a building nearby. Apparently there's a network of them.",
        "High-ranking nobles are being escorted by guardsmen and being shown the sites by a local politician. The visitors are being boo'd by locals.",
        "A warrant has been issued for the arrest of a notorious thief. Guardsmen are nailing the thief's likeness on posters to shop fronts.",
        "A group of acolytes are reporting hate crimes to a Captain and his guardsmen. Apparently the attacks on their church ave increased over the last month.",
        "A well known temple has had their idols stolen throughout the city. Acolytes are asking passers-by for information.",
        "A disease has run rampant through this part of town, causing the Clerics to be overwhelmed with cases and patients are being treated out on the street.",
        "A playwright is performing their one-act play on a street corner nearby.",
        "A bard is playing rowdy drinking songs in a nearby tavern. A group of guardsmen gather outside and seem to be planning on shutting the place down.",
        "A nearby tavern is holding a drinking competition out on the street and there's a prize! Several contestants are already sat, ready to take part.",
        "There's rumours of a potent new drug which is spreading like wildfire through the city.",
        "The number of street urchins in this area is much higher than before. They look like pickpockets.",
        "A drunk is talking to a local person about the rumour of a local gangster's son being kidnapped earlier that month. He still hasn't been found.",
        "A notorious criminal is being led through the streets in chains.",
        "You overhear a conversation. The spiders of the Prison of Kas the Bloody are powerful spellcasters.",
        "Turning into an empty alleyway you follow it into an oval courtyard. Overgrown and unmaintained the pathway around is covered in weeds and ivy. A stone statue of a woman stands in the centre. Clearing away the creepers that tangle her you see a message inscribed at the base. ‘Enter’.",
        "A cart passes you on the cobbled road pulling with it a cage full of captives. You catch the eye of a halfling crushed against the bars. He holds out his hand and the brief illusion of ‘HELP US’ appears to float from his fingers before he is carted away",
        "Across the tavern you see a table of gnomes drinking and laughing noisily. Intrigued by you one beckons you over to join them for a drink and slides a glass towards you whilst filling it magically. They smile knowingly as you consume and your eyes close heavily",
        "You hear a commotion as you pass the stables. ‘You know he cannot handle his drink!’ you hear stable boys arguing as a knight sleeps restfully in the straw. ‘We cannot forfeit this tournament, you know what the stakes are!’ They turn and catch you staring.",
        "A town elder approaches you ‘Excuse me’ he says with more annoyance than urgency ‘You couldn’t go and take a look at the aqueduct? Ive sent engineers and heard nothing back. Whatever the problem its disrupting my baths!’",
        "A horse gallops towards you up the street, encouraging gasps from the people as it passes. The reason becomes clear. It drags its rider behind it, probably a knight by the Armor he wears. 3 black shafted arrows protrude from his torso.",
        "A homeless man beckons you as you pass. ‘Please, you have to help us! My friends are going missing one by one during the night. The town guard don’t care as we are nothing but pests to them. Please im begging you.",
        "Walking through the city you start to see units of guards rushing around in formation clearly looking for something. The residents start to clear the streets a bell rings. You overhear a conversation as a couple pass you ‘An escape from the jail? Surely not!?’",
    ]

    return urbanDiscoveries[GetRandomInt(0, urbanDiscoveries.length - 1)];
}

function GetUrbanCrime() {
    let urbanCrimes = [
        "An urchin steals a loaf of bread from a passing woman's basket."
    ]

    return urbanCrimes[GetRandomInt(0, urbanCrimes.length - 1)];
}

function GetRandomAnimal() {
    let animals = ["pigs", "rats", "vermin", "cattle", "horses", "chickens", "geese"]

    return animals[GetRandomInt(0, animals.length - 1)];
}

export function GetWildnernessLocationDiscovery() {
    let wildernessLocationDiscoveries = [
        "You see a " + GetAgeOfLocationDiscovery() + ", " + GetSizeOfLocationDiscovery() + " barn up ahead.",
        "There is a " + GetSizeOfLocationDiscovery() + " Keep here. It looks " + GetAgeOfLocationDiscovery() + ".",
        "A " + GetSizeOfLocationDiscovery() + "tower house is built here. It looks " + GetAgeOfLocationDiscovery() + ".",
        "There is a mansion up ahead. It is " + GetSizeOfLocationDiscovery() + " and seems to be " + GetAgeOfLocationDiscovery() + ".",
        "You approach a " + GetSizeOfLocationDiscovery() + ", " + GetAgeOfLocationDiscovery() + " roadside shrine.",
        "You see a chapel up ahead. It is " + GetSizeOfLocationDiscovery() + " and seems to be " + GetAgeOfLocationDiscovery() + ".",
        "There is a temple here. It is " + GetSizeOfLocationDiscovery() + " and seems to be " + GetAgeOfLocationDiscovery() + ".",
        "There seem to be a " + GetSizeOfLocationDiscovery() + " monastic structure here. It looks " + GetAgeOfLocationDiscovery() + ".",
        "A " + GetAgeOfLocationDiscovery() + ", " + GetSizeOfLocationDiscovery() + " building is here. A milling stone tells you it is a ranary of sorts.",
        "You come across a shepherd's hut.",
        "You approach a kind of pilgrimage site. It looks " + GetAgeOfLocationDiscovery() + ".",
        "You approach a " + GetSizeOfLocationDiscovery() + " farmhouse.",
        "You come across a " + GetSizeOfLocationDiscovery() + ", partly burned signal pyre. The ash is still warm.",
        "There is a collection of " + GetRandomInt(1, 6) + " standing stones here.",
        "There are some small structures in the landscape. It seems there was formally a military outpost here.",
        "You find a small, underground structure dug for temporary habitation.",
        "A round barrow has been built here - a " + GetSizeOfLocationDiscovery() + " burial mound.",
        "You discover a narrow, long burial mound large enough to contain " + GetRandomInt(2, 15) + " unfortunate souls.",
        "You come across a " + GetSizeOfLocationDiscovery() + " stone henge with" + GetRandomInt(1, 10) + GetAgeOfLocationDiscovery() + " standing stones.",
        "This area contains a " + GetSizeOfLocationDiscovery() + " hillfort. It seems to be " + GetAgeOfLocationDiscovery() + ".",
        "You spot a " + GetSizeOfLocationDiscovery() + " campsite up ahead.",
        "There is a section of " + GetAgeOfLocationDiscovery() + " frontier wall here.",
        "There is a " + GetSizeOfLocationDiscovery() + " stable up ahead. It seems to be " + GetAgeOfLocationDiscovery() + ".",
        "A monument has been erected here. It is " + GetSizeOfLocationDiscovery() + " and looks to be " + GetAgeOfLocationDiscovery() + ".",
        "Some kind of war memorial is in this place. It is " + GetAgeOfLocationDiscovery(),
        "As you move forward you see a structure up ahead. Some kind of... prison. It is " + GetSizeOfLocationDiscovery() + " and seems to be " + GetAgeOfLocationDiscovery() + ".",
        "There seems to be a mining camp up ahead. It is " + GetSizeOfLocationDiscovery() + " and seems to be " + GetAgeOfLocationDiscovery() + ".",
        "A " + GetSizeOfLocationDiscovery() + " windmill blocks your way. It is " + GetAgeOfLocationDiscovery(),
        "Some kind of ritualistic earthworks is in this area. It is " + GetSizeOfLocationDiscovery() + " and seems to be " + GetAgeOfLocationDiscovery() + ".",
        "You come across a " + GetSizeOfLocationDiscovery() + " pit in the earth. The stench is overwhelming. It is a plague pit.",
        "There is a magical aura in this area. Some kind of " + GetAlignmentOfDiscovery() + " arcane residue is present here.",
        "There an arcane blight here. A magical infestation has afflicted this place.",
        "There is a " + GetAlignmentOfDiscovery() + " aura surrounding the place up ahead. An arcane mutation seems to have taken place.",
        "This area is swelling with magic. This place has been enchanted with " + GetAlignmentOfDiscovery() + ", arcane energy.",
        "There is a magical source here. You have discovered some kind of " + GetAlignmentOfDiscovery() + " arcane repository.",
        "A " + GetAlignmentOfDiscovery() + " distortion in the planes of existence has occured here.",
        "You have discovered what seems to be a magical gate or planar portal of some kind.",
        "There is a rift in the fabric of the planes! A " + GetAlignmentOfDiscovery() + " planar tear has occurred in this area!",
        "A " + GetAlignmentOfDiscovery() + " magical outpost is in this area.",
        "There is a sign or mark in this place. It seems to signify the Divine.",
        "The atmosphere here is thick with foreboding. This is a cursed place. Perhaps cursed by the Divine.",
        "The energy in this place is calm and seems to comfort you. This is some kind of Divine, hallowed place.",
        "There is an overwhelming feeling that this area is been watched. Can you sense the Divine?",
        "You feel it before you enter this place. There is a Divine presence here.",
        "You have discovered a lair. It is the entrance to a " + GetSizeOfLocationDiscovery() + " burrow dug into the ground here.",
        "There is an entrance to a " + GetSizeOfLocationDiscovery() + " cave here. It looks deep enough that it may lead to some tunnels.",
        "You discover a " + GetSizeOfLocationDiscovery() + " nest here.",
        "You come across what seems to be a " + GetSizeOfLocationDiscovery() + " hive of some kind.",
        "You approach a set of ruins up ahead.",
        "The ground up ahead seems to become more difficult to traverse.",
        "You approach a " + GetSizeOfLocationDiscovery() + " crevasse up ahead.",
        "There is a " + GetSizeOfLocationDiscovery() + " hole in the ground here.",
        "You discover a " + GetSizeOfLocationDiscovery() + " pit in this area.",
        "The route becomes steeper here and remains a steep slope for some time.",
        "The route slopes down at a steep angle and remains so for some time.",
        "You approach a rise in the terrain in the distance.",
        "The sound of trickling water spurs you forard as you discover a " + GetSizeOfLocationDiscovery() + " stream up ahead.",
        "The sound of bubbles breaking the surface of a pool of water leads to a nearby spring.",
        "You approach a " + GetSizeOfLocationDiscovery() + " lake up ahead.",
        "The sound of crashing water leads you to the site of a " + GetSizeOfLocationDiscovery() + " waterfall nearby.",
        "As you enter this place a " + GetSizeOfLocationDiscovery() + " , ancient tree draws your attention.",
        "You have stumbled upon a " + GetSizeOfLocationDiscovery() + " resource of wild fruit.",
        "You spot a " + GetSizeOfLocationDiscovery() + " resource of wild vegetables in this area.",
        "There seems to be an abundance of game in this area.",
        "You come across a " + GetSizeOfLocationDiscovery() + " supply of timber.",
        "You discover a " + GetSizeOfLocationDiscovery() + " supply of stone here.",
        "You notice some faint, " + GetAgeOfLocationDiscovery() + " tracks in the area. They are " + GetSizeOfLocationDiscovery() + ".",
        "There are some definite, " + GetAgeOfLocationDiscovery() + " tracks in this area.",
        "There are multiple,  " + GetSizeOfLocationDiscovery() + "  tracks here.",
        "There are signs of violence in this area.",
        "You notice a " + GetSizeOfLocationDiscovery() + ", " + GetAgeOfLocationDiscovery() + " trail of blood in this area.",
        "You discover what seems to be fragments of bones in this place.",
        "As you approach the area up ahead you see what looks to be a corpse.",
        "As you approach the area up ahead you see a carcass on the ground up ahead.",
        "There seems to be some old junk and refuse laying about here.",
        "As you continue forward you come across a " + GetAgeOfLocationDiscovery() + " path.",
        "As you approach this area you see signs that there is a " + GetAgeOfLocationDiscovery() + " mine built here.",
        "There seems to be a " + GetSizeOfLocationDiscovery() + ", " + GetAgeOfLocationDiscovery() + " quarry up ahead.",
        "You notice a " + GetSizeOfLocationDiscovery() + " campsite up ahead.",
        "You see a " + GetSizeOfLocationDiscovery() + " hut nearby.",
        "The air becomes thick with dread. there is a a blight or curse n this area",
        "A blinding mist suddenly appears out of nowhere, covering the area completely.",
        "A thick fog begins to rise up from beneath your feet, obscurring everything it touches.",
        "Your boot sinks into the earth as you find you have stumbled into a bog.",
        "The ground beneath your swells then sinks as you begin to sink. You have stepped into quicksand.",
        "The sound of flies buzzing around you and the bile rising up your throat signal to you that this place is diseased.",
        "There area ahead seems to be flooded.",
        "Your senses sharpen as the hairs stand on the back of your neck. There is an explosion of movement - you have been caught in an ambush!",
        "You come across the entrance to a " + GetSizeOfLocationDiscovery() + " dungeon.",
        "There seems to be a " + GetSizeOfLocationDiscovery() + " abandoned village here.",
        "You hear a noise up ahead - there are people on the road. They seem to be survivors of some kind of attack.",
        "You encounter a tantalizing sweet fragrance. Do you follow the scent? It is coming from a tree with long, draping vines that completely cover the trunk. It is an arcane construct a carnivorous tree that will attack anything within range and its vines are especially sticky enough to bind prey.",
        "You look ahead to see two adult wolves who look skinny and malnourished. They stare at you, mouths slavering with saliva. Behind them you see three wolf cubs.",
        "Ahead you see a young girl in a white dress, dancing alone in a clearing. She looks up at you with glowing red eyes and asks you to tell her a story.",
        "You come across the hulking corpse of an ogre. On closer inspection a pair of feet is sticking our from under its arm. Are they alive?",
        "An old woman with a crooked back approaches you from the woods. She asks for help in gathering some fruits for her food stock. You sense some dark magic about her.",
        "You come across a party of knights on horseback who seem to be searching for someone.",
        "A tremendous crashing can be heard up ahead as you see a group of ogres throwing trees and rocks at one another in anger.",
        " You come across a clearing where a heavily armored knight has pulled his sword on his possessed horse, who is trying to attack him.",
        "You find the carcass of a giant lizard-like creature beside a destroyed nest. Within the nest is one large egg. It looks to be starting to hatch.",
        "Nearing the heart of the forest you come across a solitary grave, its headstone looking as though it has been lightening struck dozens of times.",
        "You find a lone NPC lying beside the road, he has clearly been robbed.With his dying breath he asks you to recover his daughter's dowry from the thieves.",
        "Stepping into a small glade, you find that there is a pentagram scratched into the dirt, and several dead pixies lying in the center of it.",
        "Scratched into a nearby tree you see \"The Witch of the Dark Wood has sworn vengeance on the village, swearing to steal away all the children by wintersnight.\"",
        "Legend says Haddock Hill, deep in the wood, is a slumbering giant. No one really believed that...but now the hill has disappeared in the night.",
        "No one has seen a crow in the forest in a month. This is a dark omen, and the forest folk are nervous.",

    ]

    return wildernessLocationDiscoveries[GetRandomInt(0, wildernessLocationDiscoveries.length - 1)];
}

function GetAlignmentOfDiscovery() {
    let alignments = ["chaotic", "evil", "neutral", "good", "lawful"]

    return alignments[GetRandomInt(0, alignments.length - 1)];
}

function GetRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}