import { getRandomString } from "../../utils/getRandomString";

export const premadeWindmill = () => {
  return [
    {
      id: getRandomString(),
      name: "tree6",
      x: 18.73,
      y: -0.19,
      z: -17.74,
      rot: 0,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: 16.44,
      y: -0.19,
      z: -20.38,
      rot: 1.53,
      scale: 0.95,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: 14.89,
      y: -0.2,
      z: -19.91,
      rot: 0.52,
      scale: 0.87,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 14.1,
      y: -0.19,
      z: -21.42,
      rot: 1.38,
      scale: 0.81,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 11.49,
      y: -0.2,
      z: -23.35,
      rot: 0.83,
      scale: 0.87,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: 9.66,
      y: -0.2,
      z: -23.63,
      rot: 0.3,
      scale: 0.85,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 8.78,
      y: -0.19,
      z: -23.82,
      rot: 0.39,
      scale: 1.11,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: 7.91,
      y: -0.2,
      z: -23.66,
      rot: 2.48,
      scale: 1.15,
    },
    {
      id: getRandomString(),
      name: "./models/trees/stump_a.glb",
      x: 6.29,
      y: -0.2,
      z: -25.02,
      rot: 2.06,
      scale: 0.9,
    },
    {
      id: getRandomString(),
      name: "./models/trees/stump_a.glb",
      x: 2.72,
      y: -0.2,
      z: -26.66,
      rot: 0.79,
      scale: 1.14,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 1.76,
      y: -0.2,
      z: -26.1,
      rot: 1.49,
      scale: 0.98,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: 0.19,
      y: -0.2,
      z: -25.22,
      rot: 0.42,
      scale: 1.01,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: 0.02,
      y: -0.19,
      z: -26.8,
      rot: 0.55,
      scale: 0.88,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -1.9,
      y: -0.19,
      z: -26.48,
      rot: 0.88,
      scale: 1.18,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: -3.51,
      y: -0.2,
      z: -26.01,
      rot: 0.41,
      scale: 1.11,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -5.11,
      y: -0.19,
      z: -24.44,
      rot: 1.75,
      scale: 1.1,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -6.57,
      y: -0.19,
      z: -23.75,
      rot: 1.12,
      scale: 1.17,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: -8.15,
      y: -0.19,
      z: -24.22,
      rot: 1.55,
      scale: 0.96,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -7.39,
      y: -0.19,
      z: -22.77,
      rot: 0.04,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -8.83,
      y: -0.18,
      z: -21.46,
      rot: 0.24,
      scale: 0.96,
    },
    {
      id: getRandomString(),
      name: "./models/trees/stump_a.glb",
      x: -10.71,
      y: -0.18,
      z: -20.85,
      rot: 1.16,
      scale: 0.81,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: -11.14,
      y: -0.19,
      z: -19.61,
      rot: 0.68,
      scale: 1.07,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -13.01,
      y: -0.18,
      z: -19.92,
      rot: 1.29,
      scale: 0.86,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: -13.64,
      y: -0.18,
      z: -18.19,
      rot: 0.62,
      scale: 1.16,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree6.glb",
      x: -15.28,
      y: -0.19,
      z: -16.89,
      rot: 2.22,
      scale: 1.15,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: -16.05,
      y: -0.18,
      z: -17.04,
      rot: 1.19,
      scale: 0.97,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -16.92,
      y: -0.18,
      z: -16.06,
      rot: 2.33,
      scale: 1.16,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -19.9,
      y: -0.19,
      z: -16.17,
      rot: 0.15,
      scale: 0.87,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -20.94,
      y: -0.19,
      z: -15.49,
      rot: 0.47,
      scale: 1.18,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -21.26,
      y: -0.19,
      z: -12.87,
      rot: 0.32,
      scale: 0.98,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -22.66,
      y: -0.19,
      z: -11.19,
      rot: 0.73,
      scale: 0.97,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -22.44,
      y: -0.2,
      z: -11.87,
      rot: 1.81,
      scale: 1.17,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -23.97,
      y: -0.19,
      z: -10.57,
      rot: 0,
      scale: 1.04,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: -24.89,
      y: -0.19,
      z: -8.97,
      rot: 2.09,
      scale: 0.95,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -24.37,
      y: -0.2,
      z: -8.2,
      rot: 2.67,
      scale: 1.12,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -24.76,
      y: -0.2,
      z: -5.81,
      rot: 2,
      scale: 1.05,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -25.48,
      y: -0.2,
      z: -3.91,
      rot: 0.12,
      scale: 0.93,
    },
    {
      id: getRandomString(),
      name: "./models/trees/stump_a.glb",
      x: -25.63,
      y: -0.2,
      z: -3.32,
      rot: 1.26,
      scale: 1.17,
    },
    {
      id: getRandomString(),
      name: "./models/trees/stump_a.glb",
      x: -26.91,
      y: -0.2,
      z: -1.43,
      rot: 0.28,
      scale: 1.12,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -27.25,
      y: -0.2,
      z: -0.99,
      rot: 0.82,
      scale: 0.99,
    },
    {
      id: getRandomString(),
      name: "./models/trees/stump_a.glb",
      x: -26.63,
      y: -0.2,
      z: 2.38,
      rot: 1.08,
      scale: 0.99,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree6.glb",
      x: -26.48,
      y: -0.2,
      z: 3.35,
      rot: 0.49,
      scale: 1.03,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: -27.03,
      y: -0.2,
      z: 3.8,
      rot: 1.5,
      scale: 0.85,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: -26.43,
      y: -0.2,
      z: 6.8,
      rot: 0.63,
      scale: 1.16,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: -25.63,
      y: -0.2,
      z: 8.18,
      rot: 2.74,
      scale: 1.02,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -23.87,
      y: -0.2,
      z: 9.29,
      rot: 2.16,
      scale: 1.09,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -22.56,
      y: -0.19,
      z: 11.22,
      rot: 0.22,
      scale: 1.11,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -21.89,
      y: -0.19,
      z: 11.36,
      rot: 2.75,
      scale: 0.84,
    },
    {
      id: getRandomString(),
      name: "tree6",
      x: 25.95,
      y: -0.19,
      z: -6.18,
      rot: 0,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 25.84,
      y: -0.2,
      z: -5.53,
      rot: 1.46,
      scale: 0.87,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 26.05,
      y: -0.2,
      z: -4,
      rot: 2.11,
      scale: 0.81,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: 26.5,
      y: -0.2,
      z: -3.38,
      rot: 2.38,
      scale: 1.14,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 25.42,
      y: -0.2,
      z: -0.87,
      rot: 0.5,
      scale: 0.85,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: 24.91,
      y: -0.19,
      z: -0.91,
      rot: 2.26,
      scale: 0.92,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree11.glb",
      x: 25.12,
      y: -0.2,
      z: 0.89,
      rot: 1.59,
      scale: 0.94,
    },
    {
      id: getRandomString(),
      name: "tree6",
      x: 26.97,
      y: -0.2,
      z: -0.34,
      rot: 0,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 26.94,
      y: -0.21,
      z: 0.57,
      rot: 0.58,
      scale: 1.04,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree6.glb",
      x: 26.61,
      y: -0.21,
      z: 4.12,
      rot: 0.43,
      scale: 0.95,
    },
    {
      id: getRandomString(),
      name: "tree6",
      x: -14.28,
      y: -0.19,
      z: 21.68,
      rot: 0,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -12.2,
      y: -0.2,
      z: 21.78,
      rot: 0.71,
      scale: 0.97,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -12.57,
      y: -0.2,
      z: 23.32,
      rot: 2.41,
      scale: 0.82,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree6.glb",
      x: -11.3,
      y: -0.2,
      z: 23.71,
      rot: 0.9,
      scale: 1.06,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -9.72,
      y: -0.2,
      z: 25.84,
      rot: 2.2,
      scale: 0.82,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -8.29,
      y: -0.2,
      z: 26.12,
      rot: 1.15,
      scale: 0.87,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -5.71,
      y: -0.2,
      z: 25.75,
      rot: 2.95,
      scale: 1.06,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -5.78,
      y: -0.19,
      z: 25.77,
      rot: 0.1,
      scale: 0.94,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -4.66,
      y: -0.19,
      z: 24.71,
      rot: 0.57,
      scale: 0.93,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -3.62,
      y: -0.19,
      z: 24.51,
      rot: 0.93,
      scale: 1.01,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -2.26,
      y: -0.19,
      z: 24.1,
      rot: 2.98,
      scale: 1.17,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -0.55,
      y: -0.19,
      z: 24.81,
      rot: 2.04,
      scale: 0.85,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 0.01,
      y: -0.19,
      z: 25.23,
      rot: 0.22,
      scale: 0.9,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree6.glb",
      x: 2.15,
      y: -0.19,
      z: 25.26,
      rot: 2.48,
      scale: 0.98,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 2.31,
      y: -0.19,
      z: 24.29,
      rot: 2.89,
      scale: 0.85,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: 3.88,
      y: -0.19,
      z: 24.68,
      rot: 0.34,
      scale: 1.02,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: 5.53,
      y: -0.19,
      z: 23.97,
      rot: 2.42,
      scale: 1.12,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 5.2,
      y: -0.19,
      z: 25.36,
      rot: 0.85,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: 6.82,
      y: -0.19,
      z: 23.34,
      rot: 1.28,
      scale: 1.06,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree6.glb",
      x: 8.9,
      y: -0.19,
      z: 23.93,
      rot: 1.3,
      scale: 0.92,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 8.33,
      y: -0.19,
      z: 22.87,
      rot: 2.91,
      scale: 0.98,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree6.glb",
      x: 10.59,
      y: -0.19,
      z: 23.81,
      rot: 2,
      scale: 0.82,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 11.04,
      y: -0.19,
      z: 21.87,
      rot: 1.85,
      scale: 0.99,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: 12.37,
      y: -0.19,
      z: 22.46,
      rot: 0.04,
      scale: 1.05,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: 14.11,
      y: -0.19,
      z: 20.89,
      rot: 1.27,
      scale: 1.08,
    },
    {
      id: getRandomString(),
      name: "RockB_1_08",
      x: 3.47,
      y: -0.18,
      z: -24.7,
      rot: 0,
      scale: 1,
    },

    {
      id: getRandomString(),
      name: "./models/rocks/RockB_1_04.glb",
      x: 0.07,
      y: -0.18,
      z: -24.47,
      rot: 0.67,
      scale: 1.07,
    },
    {
      id: getRandomString(),
      name: "./models/rocks/RockB_1_04.glb",
      x: -2.45,
      y: -0.18,
      z: -23.22,
      rot: 1.74,
      scale: 0.88,
    },
    {
      id: getRandomString(),
      name: "./models/rocks/RockB_1_04.glb",
      x: -2.4,
      y: -0.18,
      z: -23.25,
      rot: 2.35,
      scale: 0.89,
    },

    {
      id: getRandomString(),
      name: "./models/rocks/RockB_1_05.glb",
      x: -23.96,
      y: -0.18,
      z: 2.07,
      rot: 2.81,
      scale: 1.21,
    },
    {
      id: getRandomString(),
      name: "./models/rocks/RockB_1_00.glb",
      x: -23.38,
      y: -0.18,
      z: 3.59,
      rot: 2.35,
      scale: 0.76,
    },
    {
      id: getRandomString(),
      name: "./models/rocks/RockB_1_00.glb",
      x: -21.73,
      y: -0.18,
      z: 5.17,
      rot: 2.05,
      scale: 1.11,
    },
    {
      id: getRandomString(),
      name: "./models/rocks/RockB_1_05.glb",
      x: -20.91,
      y: -0.18,
      z: 6,
      rot: 0.35,
      scale: 0.96,
    },
    {
      id: getRandomString(),
      name: "./models/rocks/RockB_1_00.glb",
      x: -20.82,
      y: -0.18,
      z: 8.04,
      rot: 1.46,
      scale: 0.73,
    },
    {
      id: getRandomString(),
      name: "exterior_windmill1",
      x: -2.33,
      y: -0.15,
      z: -0.08,
      rot: 0.9,
      scale: 1.77,
    },
    {
      id: getRandomString(),
      name: "barrel1",
      x: 2.13,
      y: -0.15,
      z: 0.22,
      rot: 0.9,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "barrel1",
      x: 1.86,
      y: -0.15,
      z: 0.37,
      rot: 0.9,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "barrel1",
      x: 1.49,
      y: -0.15,
      z: -0.81,
      rot: 0.9,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "crate4",
      x: 2.01,
      y: -0.16,
      z: -0.79,
      rot: 1.4,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "tree6",
      x: -11.04,
      y: -0.16,
      z: 0.03,
      rot: 1.4,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree6.glb",
      x: -11.86,
      y: -0.17,
      z: 0.49,
      rot: 2.01,
      scale: 0.98,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree12.glb",
      x: -10.14,
      y: -0.17,
      z: 0.76,
      rot: 1.93,
      scale: 0.89,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -9.9,
      y: -0.17,
      z: 2.93,
      rot: 1.25,
      scale: 0.83,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: -11.08,
      y: -0.17,
      z: 2.61,
      rot: 2.4,
      scale: 0.96,
    },
    {
      id: getRandomString(),
      name: "tree6",
      x: -0.47,
      y: -0.15,
      z: -6.36,
      rot: 1.4,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -1.26,
      y: -0.16,
      z: -5.29,
      rot: 1.78,
      scale: 0.83,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree10.glb",
      x: -0.42,
      y: -0.16,
      z: -5.58,
      rot: 2.42,
      scale: 1.19,
    },
    {
      id: getRandomString(),
      name: "./models/trees/tree7.glb",
      x: 1.11,
      y: -0.16,
      z: -4.89,
      rot: 2.57,
      scale: 1.15,
    },
    {
      id: getRandomString(),
      name: "bush4",
      x: -7.81,
      y: -0.17,
      z: 3.58,
      rot: 1.4,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "tree1c",
      x: 6.72,
      y: -0.17,
      z: -19.06,
      rot: 1.4,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "tree1c",
      x: -21.25,
      y: -0.17,
      z: -3.81,
      rot: -0.7,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "tree1a",
      x: 11.07,
      y: -0.17,
      z: -18.58,
      rot: -0.7,
      scale: 1,
    },
    {
      id: getRandomString(),
      name: "tree5a",
      x: -9.01,
      y: -0.17,
      z: 14.65,
      rot: -0.7,
      scale: 1,
    },
  ];
};
