let userName;
let playerID;
let avatarPath;
let roomID;
let color;
export function setUserData(un, pID, ap, rID, col) {
  userName = un;
  playerID = pID;
  avatarPath = ap;
  roomID = rID;
  color = col;
}

export function getUserData() {
  return {
    userName: userName,
    playerID: playerID,
    avatarPath: avatarPath,
    roomID: roomID,
    color: color,
  };
}
