import * as THREE from "three";
import * as LOADINGMANAGERS from "../managers/loadingmanagers";



export function GetModel(modelID) {
  let assetPaths = [
    "./models/characters/bear.fbx",
    "./models/characters/DeepSeaLizard.fbx",
    "./models/characters/DemonWarrior.fbx",
    "./models/characters/ForestDemon.fbx",
    "./models/characters/Hellguard.fbx",
    "./models/characters/Imp.fbx",
    "./models/characters/Lich.fbx",
    "./models/characters/Mushroom.fbx",
    "./models/characters/RockGolem.fbx",
    "./models/characters/SkeletonArcher.fbx",
    "./models/characters/SkeletonWarrior.fbx",
    "./models/characters/Zombie.fbx",
    "./models/characters/Hound.fbx",
    "./models/characters/DwarfWarrior.fbx",
  ];

  for (let i = 0; i < assetPaths.length; i++) {
    if (assetPaths[i].includes(modelID)) {
      return assetPaths[i];
    }
  }
}

export function GetModelMaterial(model) {

  let tex = model.material.map;

  let mat = new THREE.MeshStandardMaterial({
    // color: 0xc50000,
    map: tex
  })

  return mat
}