import * as GAMESTATE from "../gamestate";
import * as ASSETLOADER from "../assetLoader";
import * as THREE from "three";
import { SetplayMat } from "../settings";
import { premadeWindmill } from "./premade_windmill";

export function PrintCurrentMap() {
  let allObjects = GAMESTATE.GetAllActiveObjects();
  let savedObjects = [];
  for (let i = 0; i < allObjects.length; i++) {
    const newObj = {
      id: allObjects[i].userData.id,
      name: allObjects[i].name,
      x: Math.round(allObjects[i].position.x * 100) / 100,
      y: Math.round(allObjects[i].position.y * 100) / 100,
      z: Math.round(allObjects[i].position.z * 100) / 100,
      rot: Math.round(allObjects[i].rotation.y * 100) / 100,
      scale: Math.round(allObjects[i].scale.y * 100) / 100,
    };

    savedObjects.push(newObj);
  }
}

export const maps = () => {
  return [premadeWindmill()];
};

export async function LoadMap(index) {
  let array;

  array = maps()[index];
  SetplayMat("layout_s_lrg");

  for (let i = 0; i < array.length; i++) {
    let name = array[i].name;
    let id = array[i].id;
    let modelType = ASSETLOADER.GetModelType(array[i].name);
    let position = new THREE.Vector3(array[i].x, array[i].y, array[i].z);
    let rotation = array[i].rot;
    let scale = array[i].scale;

    let model = await ASSETLOADER.LoadModel(
      name,
      modelType,
      position,
      rotation,
      scale,
      null,
      true,
      scale,
      id
    );
  }
}
