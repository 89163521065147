export function GetRandomFaction() {
  return (
    "This faction are a mostly made up of " +
    GetAlignmentOfDiscovery() +
    " " +
    GetFactionRaces() +
    ", who are a group of " +
    GetFactionType() +
    ". " +
    GetFactionMotivation() +
    " and " +
    GetFactionReputation() +
    " " +
    GetFactionSizeAndInfluence()
  );
}

function GetFactionSizeAndInfluence() {
  let strings = [
    "They are a small group of fifty men and women or less.",
    "They are a group of around one-hundred individuals.",
    "They are a well-sized group of one-hundred and fifty people.",
    "There are hundreds of members throughout the region.",
    "There are thousands of members throughout the continent.",
    "There are countless members throughout the world.",
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function GetFactionReputation() {
  let strings = [
    "taking no prisoners.",
    "leaving the dead to be eaten by beasts.",
    "tattooing or branding prisoners.",
    "scalping or flaying prisoners.",
    "burning villages and fields.",
    "betraying their employers.",
    "singing bawdy songs.",
    "drinking too much ale and wine.",
    "screaming and shouting during battle.",
    "convening with ghosts and spirits.",
    "ritual animal sacrifice.",
    "ritual human sacrifice.",
    "ritualistic blood-letting.",
    "ritualistic acts.",
    "eating unusual meats.",
    "prolific amounts of drinking.",
    "never cutting their hair.",
    "shaving their heads and bodies.",
    "never bathing.",
    "bathing and perfuming their bodies.",
    "being rowdy and festive.",
    "being joyful and eager to fight.",
    "being relaxed and carefree.",
    "being frightened and suspicious.",
    "being hostile and suspicious.",
    "being hostile and eager to fight.",
  ];

  return "they are known for " + strings[GetRandomInt(0, strings.length - 1)];
}

function GetFactionMotivation() {
  let strings = [
    "the domination of the city or region's politics",
    "the domination of the city or region's trade",
    "the revenge against a rival faction in the same city or region",
    "the revenge against a rival faction in another city or region",
    "to sabotage of a group run by commoners. Either a guild, academy, religious faith, or secret society",
    "to brew rebellion against the city or region's ruling faction",
    "to arrange a marriage with a powerful allied faction",
    "to arrange a marriage with a powerful rival faction",
  ];

  return (
    "This faction's main motivation is " +
    strings[GetRandomInt(0, strings.length - 1)]
  );
}

function GetFactionRaces() {
  let strings = [
    "dwarves",
    "elves",
    "halflings",
    "humans",
    "gnomes",
    "half-elves",
    "half-orcs",
    "vampires",
    "goblins",
    "orcs",
    "undead",
    "kobolds",
    "bugbears",
    "hobgoblins",
    "ghouls",
    "dryads",
    "ogres",
    "giants",
    "eclectic mix of races",
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

export function GetFactionName() {
  let strings = ["The " + FactionNameA() + " " + FactionNameB()];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function FactionNameA() {
  let strings = [
    "Old",
    "Society of",
    "Legion of",
    "Cult of",
    "Banner of",
    "Lords of",
    "Knights of",
    "Nobles of",
    "Order of",
    "Deep",
    "Red",
    "White",
    "Black",
    "Council of",
    "Silver",
    "Huntsmen of",
    "Scarlet",
    "Knights of",
    "Order of",
    "Church of",
    "House of",
    "House of",
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function FactionNameB() {
  let strings = [
    "Wayfarers",
    "Truth",
    "Axes",
    "Fists",
    "Blades",
    "Steel",
    "Iron",
    "Gold",
    "Boots",
    "Copper",
    "Ivory",
    "Oak",
    "Maces",
    "Arrows",
    "Spears",
    "Clubs",
    "Hammers",
    "Knives",
    "Daggers",
    "Sabres",
    "Glaives",
    "Pikes",
    "Lances",
    "Cannons",
    "Bows",
    "Gauntlets",
    "Shields",
    "Serpents",
    "Spiders",
    "Vipers",
    "Assassins",
    "Dogs",
    "Wolves",
    "Bears",
    "Scorpions",
    "Bandits",
  ];
  return strings[GetRandomInt(0, strings.length - 1)];
}

function GetFactionType() {
  let strings = [
    "nobles",
    "wizards",
    "sorcerers",
    "warriors",
    "mercenaries",
    "peasants",
    "knights",
    "politicians",
    "fighters",
    "barbarians",
    "bards",
    "clerics",
    "druids",
    "fighters",
    "monks",
    "paladins",
    "rangers",
    "rogues",
    "sorcerers",
    "warlocks",
    "wizards",
    "bandits",
    "thieves",
    "an eclectic mix of professions",
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function GetRandomWildernessNPC() {
  let roll = GetRandomInt(0, 20);

  if (roll < 7) {
    return (
      " is a " +
      GetAlignmentOfDiscovery() +
      ", " +
      GetRandomRace() +
      " " +
      GetWildernessNPCType() +
      ".\n" +
      " They " +
      GetNPCTrait() +
      ". " +
      GetNPCPersonality() +
      " " +
      GetNPCQuirk() +
      " " +
      GetCompetence() +
      " " +
      NPCCurrentMood() +
      " " +
      PowerLevel() +
      "\n\n" +
      GetMainMotivation() +
      " " +
      CurrentDrivingForce()
    );
  } else if (roll > 7 && roll < 17) {
    return (
      " is a " +
      GetAlignmentOfDiscovery() +
      ", " +
      GetRandomRace() +
      " " +
      GetWildernessNPCType() +
      ".\n" +
      " They " +
      GetNPCTrait() +
      ". " +
      GetNPCPersonality() +
      " " +
      GetNPCQuirk() +
      " " +
      GetCompetence() +
      " " +
      NPCCurrentMood() +
      " " +
      PowerLevel()
    );
  } else {
    return (
      " is a " +
      GetAlignmentOfDiscovery() +
      ", " +
      GetRandomRace() +
      " " +
      GetWildernessNPCType() +
      ".\n" +
      " They " +
      GetNPCTrait() +
      ". " +
      GetNPCPersonality() +
      " " +
      GetNPCQuirk() +
      " " +
      GetCompetence() +
      " " +
      NPCCurrentMood() +
      " " +
      PowerLevel() +
      "\n\n" +
      GetMainMotivation() +
      " " +
      CurrentDrivingForce() +
      "\n\n" +
      GetQuest()
    );
  }
}

function GetWildernessNPCType() {
  let wildernessNPCType = [
    "criminal",
    "adventurer",
    "explorer",
    "hunter/gatherer",
    "commoner",
    "ranger",
    "scout",
    "noble",
    "bandit",
    "thug",
    "thief",
    "smuggler",
    "farmer",
    "herder",
    "servant",
    "laborer",
    "soldier",
    "clergy",
    "merchant trading in " + GetMerchantType() + ".",
    "occultist",
    "drifter",
    "statesman",
    "duelist",
    "preacher",
    "rogue",
    "artisan",
    "missionary",
    "outcast",
    "mercenary",
    "hermit",
    "pioneer",
    "outlaw",
    "sorcerer",
    "villager",
    "conscript",
    "highwayman",
    "fortune-hunter",
    "recluse",
    "polymath",
    "magician",
    "vagrant",
    "politician",
    "crook",
    "activist",
    "hero",
    "champion",
    "cleric",
    "clairvoyant",
    "craftsman",
    "scientist",
    "performer",
    "brute",
    "inquisitor",
    "paladin",
    "villain",
    "professor",
    "tradesman",
    "hitman",
    "beggar",
    "warrior",
    "beast-master",
    "mysterious wanderer",
    "scout rider",
    "rugged warrior",
    "beggar",
    "criminal",
    "commoner",
    "soldier",
    "guard",
    "undertaker",
    "sage",
    "scholar",
    "wizard",
    "writer",
    "perfumer",
    "architect",
    "locksmith",
    "navigator",
    "apothecary",
    "clockmaker",
    "alchemist",
    "astrologer",
    "spy",
    "diplomat",
    "cartographer",
    "inventor",
    "driver",
    "porter",
    "monk",
    "clergy",
    "cobbler",
    "tailor",
    "weaver",
    "basketmaker",
    "potter",
    "carpenter",
    "baker",
    "ropemaker",
    "smith",
    "herbalist",
    "jeweler",
    "innkeeper",
    "artist",
    "actor",
    "minstrel",
    "armorer",
    "weaponsmith",
    "occultist",
    "drifter",
    "statesman",
    "duelist",
    "preacher",
    "rogue",
    "artisan",
    "missionary",
    "outcast",
    "mercenary",
    "hermit",
    "pioneer",
    "outlaw",
    "sorcerer",
    "villager",
    "conscript",
    "highwayman",
    "fortune-hunter",
    "recluse",
    "polymath",
    "magician",
    "vagrant",
    "politician",
    "crook",
    "activist",
    "hero",
    "champion",
    "cleric",
    "clairvoyant",
    "craftsman",
    "scientist",
    "performer",
    "brute",
    "inquisitor",
    "paladin",
    "villain",
    "professor",
    "tradesman",
    "hitman",
    "beggar",
    "warrior",
    "merchant trading in " + GetMerchantType() + ".",
  ];
  return wildernessNPCType[GetRandomInt(0, wildernessNPCType.length - 1)];
}

function GetAlignmentOfDiscovery() {
  let alignments = [
    "lawful good",
    "neutral good",
    "chaotic good",
    "lawful neutral",
    "true neutral",
    "chaotic neutral",
    "lawful evil",
    "neutral evil",
    "chaotic evil",
  ];

  return alignments[GetRandomInt(0, alignments.length - 1)];
}

function GetUrbanNPCType() {
  let urbanNPCType = [
    "beggar",
    "criminal",
    "commoner",
    "soldier",
    "guard",
    "undertaker",
    "sage",
    "scholar",
    "wizard",
    "writer",
    "perfumer",
    "architect",
    "locksmith",
    "navigator",
    "apothecary",
    "clockmaker",
    "alchemist",
    "astrologer",
    "spy",
    "diplomat",
    "cartographer",
    "inventor",
    "driver",
    "porter",
    "monk",
    "clergy",
    "cobbler",
    "tailor",
    "weaver",
    "baskermaker",
    "potter",
    "carpenter",
    "baker",
    "ropemaker",
    "smith",
    "herbalist",
    "jeweler",
    "innkeeper",
    "artist",
    "actor",
    "minstrel",
    "armorer",
    "weaponsmith",
    "occultist",
    "drifter",
    "statesman",
    "duelist",
    "preacher",
    "rogue",
    "artisan",
    "missionary",
    "outcast",
    "mercenary",
    "hermit",
    "pioneer",
    "outlaw",
    "sorcerer",
    "villager",
    "consript",
    "highwayman",
    "fortune-hunter",
    "recluse",
    "polymath",
    "magician",
    "vagrant",
    "politician",
    "crook",
    "activist",
    "hero",
    "champion",
    "cleric",
    "clairvoyant",
    "craftsman",
    "scientist",
    "performer",
    "brute",
    "inquisitor",
    "paladin",
    "villain",
    "professor",
    "tradesman",
    "hitman",
    "begger",
    "warrior",
    "merchant trading in " + GetMerchantType() + ".",
  ];
  return urbanNPCType[GetRandomInt(0, urbanNPCType.length - 1)];
}

function GetNPCTrait() {
  let NPCtraits = [
    "are missing teeth",
    "are missing an eye",
    "are heavily tattooed",
    "have scarred features",
    "look shabby and unkempt",
    "are big, thick and brawny",
    "are small, scrawny even",
    "have wild, long hair",
    "have a shaved head",
    "have bright blue eyes",
    "have bloodshot eyes",
    "are clean and well-dressed",
    "are incredibly attractive",
  ];

  return NPCtraits[GetRandomInt(0, NPCtraits.length - 1)];
}

function GetMainMotivation() {
  let strings = [
    "They have had a calling to adventure which is wearing thin. Their goal is to return home.",
    "Their goal is to regain something which belongs to them but there are many obstacles standing in their way.",
    "Their goal is to fall in love, to fill the void inside them. A rival stands in their way.",
    "Their goal is to see the government fall for it has wronged their family.",
    "Their goal is to become powerful, to become the MOST powerful.",
    "Their goal is to free someone or something which has become trapped and is beyond their reach.",
    "Their goal is to be admired throughout the land.",
    "Their goal is to bring about peace throughout the land.",
    "Their goal is to escape the persecution which has been brought upon themselves.",
    "Their goal is to conquer. To wipe out their enemies.",
    "Their goal is to uncover the truth about a death of someone close to them.",
    "Their goal is to see their leader, the chosen one, ascend to the top.",
    "Their goal is to acquire riches, bounty and live a luxurious life.",
    "Their goal is to gain materialistic treasures by any means necessary.",
    "Their goal is to atone for something they did wrong, where they made a cruel choice in their past.",
    "Their goal is to gain freedom from the chains of their past.",
    "Their goal is to gain power, to gather followers and to be in charge.",
    "Their goal is to regain their lost honour.",
    "Their goal is to escape this place by any means necessary.",
    "Their goal is to cause political disorder and disruption by any means necessary.",
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function CurrentDrivingForce() {
  let strings = [
    "Their hatred of bloodshed, and corruption",
    "Noise, and peer pressure",
    "Death, and amnesia",
    "An old promise, and hostility",
    "Kleptomania, and lovesickness",
    "Tradition, and rejection",
    "A wrongful accusation, and vengeance",
    "Desire, and a fractured mind",
    "A grudge, and the effects of brainwashing",
    "Guilt, and inequality",
    "Lovesickness, and unrealistic expectations",
    "A grudge, and temper",
    "Resentment, and their hatred of humans",
    "Empathy, and emotion",
    "Order, and ambition",
    "Spite, and lies",
    "Irrational fear, and vengeance",
    "The desire for acceptance, and accusations",
    "Drug induced insanity, and high standards",
    "Jealousy, and an old promise",
    "A pressing need, and dishonour",
    "Patience, and the bad state of the world",
    "Shame, and corruption",
    "Lovesickness, and desire",
    "Impatience, and the desire for pleasure",
    "Materialism, and the need to make up for past sins",
    "Hatred, and grudges",
    "Ego, and money",
    "Arrogance, and anger",
    "Their sense of contentment, and corruption",
    "Mental illness, and a wrongful accusation",
    "Vanity, and accusations",
    "Noise, and social expectations",
    "The desire for fulfillment, and patriotism",
    "Patience, and ambition",
  ];

  return (
    "What drives them forward? " +
    strings[GetRandomInt(0, strings.length - 1)] +
    "."
  );
}

function GetNPCPersonality() {
  let NPCpersonality = [
    "They are a loner. An antisocial person even.",
    "They are cruel and belligerent.  A bully.",
    "They are anxious, fearful and cowardly.",
    "They are a greedy and envious person.",
    "They are arrogant and aloof.",
    "They are awkward and shy.",
    "They are very orderly and compulsive.",
    "They are over-confident, reckless even.",
    "They are kind, generous and compassionate.",
    "They are an easygoing, peaceful person.",
    "They are cheerful, happy and optimistic.",
  ];

  return NPCpersonality[GetRandomInt(0, NPCpersonality.length - 1)];
}

function NPCCurrentMood() {
  let NPCmood = [
    "withdrawn",
    "guarded",
    "cautious",
    "neutral",
    "sociable",
    "helpful",
    "forthcoming",
  ];

  return (
    "Their current mood is " +
    NPCmood[GetRandomInt(0, NPCmood.length - 1)] +
    "."
  );
}

function PowerLevel() {
  let NPClevel = [
    "much weaker than",
    "slightly weaker than",
    "comparable to",
    "slightly stronger than",
    "much stronger than",
  ];

  return (
    "Their power level is " +
    NPClevel[GetRandomInt(0, NPClevel.length - 1)] +
    " yourself."
  );
}

function GetNPCQuirk() {
  let NPCquirk = [
    "Beneath their exterior, they are a xenophobe.",
    "Deep down they are insecure.",
    "They have a secret addiction.",
    "They are scared of " + GetPhobia() + ".",
    "They have many allergies.",
    "They are chronically asthmatic",
    "They are chronically ill.",
    "There are extremely superstitious.",
    "They are a devout worshipper",
    "They are a pack-rat.",
    "They are kind of a miser.",
    "They are a know-it-all.",
    "They are slightly delusional.",
    "They seem to be naive and idealistic.",
    "They tend to keep their hand on their weapon.",
    "Their arm is in a sling.",
    "They have an out-of-town accent.",
    "They seem to be always eating.",
    "They seem to always be out of breath.",
    "They belch loud and often.",
    "They are a terrible cook.",
    "They tend to bite their lower lip.",
    "They carry a small mouse in their pocket.",
    "They carry a snake in their pocket.",
    "They carry a lizard in their pocket.",
    "They carry a satchel full of junk.",
    "They cough regularly.",
    "They are flatulant.",
    "They tend to gesture wildly with their hands.",
    "They are a little hard of hearing.",
    "They currently have a black eye.",
    "They tend to fidget all the time.",
    "They have a hacking cough.",
    "They seem intoxicated.",
    "They have a single gold tooth.",
    "Their pockets clink with coins.",
    "They have a lazy eye.",
    "They have a limp.",
    "They have a lisp.",
    "Their eyes are different colours.",
    "They are mute and speak soley through sign language.",
    "They never seem to look anyone in the eye.",
    "They pick their teeth with their knife.",
    "They have a chronic rash.",
    "They tend tor efer to themselves in theird-person.",
    "They have rings sewn onto their clothes.",
    "They have rope burns around their neck and wrists.",
    "Their hands are covered in calluses.",
    "They only seem to eat meat.",
    "They smell like dogs.",
    "They smell like lavender.",
    "They tend to sniff fairly often.",
    "They regular use foul language.",
    "They have a squint.",
    "They do not have any fingernails.",
    "They tap their foot incessantly",
  ];

  return NPCquirk[GetRandomInt(0, NPCquirk.length - 1)];
}

function GetCompetence() {
  let NPCCompetence = [
    "They seem fairly incompetent, a liability, almost.",
    "They seem to be a competent individual.",
    "They seem like a very capable individual.",
    "They seem to be an exceptional individual.",
  ];

  return NPCCompetence[GetRandomInt(0, NPCCompetence.length - 1)];
}

function GetRandomRace() {
  let races = ["dwarf", "elf", "gnome", "half-elf", "halfling", "half-orc"];

  let roll = GetRandomInt(0, 10);

  if (roll < 7) {
    return "human";
  } else {
    return races[GetRandomInt(0, races.length - 1)];
  }
}

function GetMerchantType() {
  let merchantTypes = [
    "general goods",
    "raw materials",
    "grain",
    "livestock",
    "ale",
    "wine",
    "clothing",
    "jewellery",
    "weapons",
    "armor",
    "spices",
    "tobacco",
    "slave",
    "books",
    "scrolls",
    "magical supplies and items",
  ];
  return merchantTypes[GetRandomInt(0, merchantTypes.length - 1)];
}

function GetImpulse() {
  let NPCimpulse = [
    "Their instict is to loot, pillage and burn their enemies.",
    "They hold grudges and tend to seek revenge.",
    "They have a tendency to question leadership or authority.",
    "They have a habit of lording over others.",
    "They tend to act impulsively.",
    "They are prone to giving in to tempation.",
    "They have a tendency to slack off and let others do the work.",
    "They will do their best to avoid danger or punishment.",
    "They are very loyal.",
    "They do not hold loyalty in high regard,",
  ];

  return NPCimpulse[GetRandomInt(0, NPCimpulse.length - 1)];
}

function GetPhobia() {
  let NPCquirk = [
    "the dark",
    "spiders",
    "rats",
    "being alone",
    "the opposite sex",
    "children",
    "the undead",
    "ghosts",
    "money",
    "being outside",
    "being inside",
    "authority",
    "livestock",
    "lizards",
    "birds",
    "insects",
    "scorpions",
    "pigs",
    "dogs",
    "bears",
    "wasps",
    "snakes",
    "fish",
    "goblins",
    "orcs",
    "gnolls",
    "trolls",
    "werewolves",
  ];

  return NPCquirk[GetRandomInt(0, NPCquirk.length - 1)];
}

function GetQuest() {
  return "They are " + CurrentQuestTheme();
}

function CurrentQuestTheme() {
  let strings = [
    "currently on the run from a local faction after " + GetCrime() + ".",
    "on a vendetta against a local faction, after being the victim of " +
      GetVictimCrime() +
      ".",
    "searching for information about a local, corrupt politician who has wronged them.",
    "looking for information about the whereabouts of a money lender who owes them coin.",

    /* ".Searching for Personal Information (A), Local Information(B), or Item Information(C).",
         ".Buying or Selling(K) at nearby location, then returning Home(L).",
         "On a local Minor Quest(M) for Treasure(U).",
         "On a Minor Quest(M) or a Major Quest(N) for a Minor Enemy(Q).",
         "On a Major Quest(N) for Treasure(U)."*/
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function GetCrime() {
  let strings = [
    "being accused of treasonous acts",
    "being accused of attempted assassination",
    "being accused rioting on political landmarks",
    "being accused of inciting violence against a political party",
    "escaping the authorities as a wanted person",
    "evading the authorities after being accused of committing fraudulent acts",
    "running from capture, accused of theft",
    "evading authorities, accused of murder",
    "running from the inquisition after being accused of crimes against religion",
    "escaping the law after being caught up in a dangerous cult",
    "being unjustly accused of a crime after being in the wrong place at the wrong time",
    "being accused of torturing and humiliating a rival",
    "being accused of extorting from local merchants",
    "being accused of defrauding local merchants",
    "being accused of stealing from friends and family",
    "being accused of badly beating a rival",
    "being accused of arson, destroying a local business",
    "being accused of stealing from a local business",
    "being accused of spreading lies and rumours about a rival group",
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function GetVictimCrime() {
  let strings = [
    "an attempted assassination",
    "theft of a family heirloom",
    "hate crime over their personal beliefs",
    "theft of gold.",
    "theft of a meaningful weapon",
    "being cursed by a religious cult",
    "being falsely accused for a crime they did not commit",
    "a violent beating",
    "arson against the home.",
    "lies and rumours being spread about them",
    "violence against their family. A family member was killed.",
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
