import gsap from "gsap";

export default function showErrorMessage(string) {
  const msg = document.getElementsByClassName("error-message")[0];

  msg.innerHTML = string;
  const tl = gsap.timeline();
  tl.to(msg, { opacity: 1, duration: 0.2 }).to(msg, {
    opacity: 0,
    duration: 0.2,
    delay: 5,
  });
}
