import { ref, set, get, getDatabase } from "firebase/database";
import * as THREE from "three";
import { getDB } from "./firebaseConfig";
import * as GAMESTATE from "../gamestate";
import { setInAMultiplayerSession } from "./status";
import { getGameID, setGameId } from "./roomData";
import { SetUpListener } from "./listenToData";
import copyTextToClipboard from "../../utils/copyTextToClipboard.js";
import { setUserData } from "./userData";
import { showChatMessage } from "./chatbox";
import { getSun } from "../lights";
import { signInAnon } from "./signIn";
import showErrorMessage from "./errorMessage";
import { updateDoc, serverTimestamp, doc } from "firebase/firestore";

export async function CreateRoom(username, avatarPath) {
  setInAMultiplayerSession(true);
  const gameId = (Math.random() + 1).toString(36).substring(2);
  setGameId(gameId);
  const randPlayerID = (Math.random() + 1).toString(36).substring(2);
  let color = Math.floor(Math.random() * 16777215).toString(16);

  const roomInfo = document.getElementsByClassName("multiplayer-room-info")[0];
  roomInfo.style.display = "block";
  roomInfo.children[0].innerHTML = username;
  roomInfo.children[1].innerHTML = "Room ID: " + gameId;

  roomInfo.onclick = () => {
    copyTextToClipboard(gameId);
  };

  showChatMessage(
    "Yuo have created a room! See room info below this message :)"
  );

  showErrorMessage(
    "Click the room info in the bottom-left to copy the room ID!"
  );

  // Update the timestamp field with the value from the server

  signInAnon(randPlayerID, username, avatarPath, gameId, color);
}
