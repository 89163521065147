let inAMultiplayerSession = false;
let allowUpdateDatabase = false;

export const InAMultiplayerSession = () => {
  return inAMultiplayerSession;
};

export const setInAMultiplayerSession = (value) => {
  inAMultiplayerSession = value;
};

export const AllowUpdateDatabase = () => {
  return allowUpdateDatabase;
};

export const setAllowUpdateDatabase = (value) => {
  allowUpdateDatabase = value;
};
