export function GetRandomFateWord() {
    let words = [

        "Undead", "Noise", "Loot", "Cloying", "Diseased", "Unseen", "Rocky", "Well", "Prodigy", "Energy", "Curse", "Secret", "Adventurers", "Hide", "Infectious", "Fragmented", "Dryrot", "Crypt", "History", "Road", "Ruined", "Silence", "Luminescent",
        "Smoke", "Boisterous", "Damaged", "Twilight", "Guard", "Map", "Location", "Boon", "Sneak", "Rusty", "Gaping", "Overexcited", "Incongruous", "Vacant", "Sarcophagus", "Information", "Success", "Escape", "Trapped", "Vibration", "Calm",
        "Erratic", "Hefty", "Glowing", "Burial", "Government", "Student", "Fugitive", "Knowledge", "Patrol", "Subliminal", "Decrepit", "Mammoth", "Twisted", "Rites", "Ability", "Possession", "Unsafe", "Accomplice", "Gears", "Hollow", "Delusional",
        "Curiosity", "Chill", "Family", "Love", "Performance", "Treacherous", "Henchman", "Animal droppings", "Whoosh", "Title", "Precious", "Angular", "Ruler", "Temperature", "Lake", "Clue", "Illusion", "Itch", "Arid", "Decayed", "Enthusiastic", "Scary",
        "Flee", "Area", "Sea", "Familiar", "Item", "Smell", "Premonition", "Representative", "Rundown", "Weather", "Protection", "City", "Message", "Rotten", "Persuade", "Diary", "Demon", "Suggestion", "Unsophisticated", "Oppress", "Vision",
        "Country", "Man", "Slave", "Toss", "Dripping", "Force", "Unthinking", "Natural", "Motive", "Wilderness", "Fire", "Woman", "Captive", "Disciple", "Escape", "Statue", "Spoiled", "Persistent", "Bridge", "Dungeon", "Language", "Payment", "Saviour",
        "Deteriorating", "Hairpin", "Refuse pit", "Obstinate", "Revealing", "Garbage", "Nobility", "Equipment", "Skill", "Food", "NPC", "Slippery", "Invite", "Rambling", "Prehistoric", "Foliage", "Orb", "Technology", "Wealth", "Uncharacteristic", "Rescue",
        "Severed hand", "Obsidian", "Search", "Awake", "Aftershock", "Sceptre", "Child", "Heart", "Rancid", "Diversion", "Misty", "Tunnel", "Rainbow", "River", "Sentimental", "Armies", "Runes", "Storage", "Fetid", "Explosion", "Floating", "Sundered",
        "Effigy", "Slime", "Sleep", "Apocalypse", "Medicine", "Alcohol", "Malodorous", "Turgid", "Laugh", "Fluttering", "Scuttling", "Bones", "Toilet", "Music", "Teacher", "Argument", "Noxious", "Blustering", "Blood", "Slide", "Woven", "Stale air",
        "Livestock", "Singing", "Disease", "Agreement", "Republic", "Courage", "Admission", "Pleasant", "Building", "Orbit", "Nothing", "Target", "Camp", "Priest", "Tradition", "King", "Explosion", "Manage", "Glitter", "Release", "Question", "Skin",
        "Champion", "Resident", "Employment", "Instrument", "Monopoly", "Thick", "Uninterested", "Endanger", "Birth", "Carry", "Crack", "Rope", "Device", "Sister", "Reflect", "Route", "Hole", "Temper", "Speed", "Mother", "Native", "Witness", "Guidance",
        "Brother", "Disastrous", "Swear", "Common", "Illuminate", "Craft", "Spirit", "Opening", "Many", "Foreknowledge", "Weakness", "Costume", "Downward", "Mouth", "Refuse", "Eye", "Dream", "Parent", "Check", "Candidate", "Ambition", "Few",
        "Awful", "Maximum", "Nimble", "Step", "Luck", "Pattern", "Guard", "Confusion", "Celebration", "Medium", "Important", "Memory", "Express", "Item", "Mouth", "Request", "Potential", "Guest", "Fortune", "Tidy", "Faith", "Tedious", "Communion",
        "Metal", "Storm", "Shame", "Dance", "Operation", "Negotiation", "Enchanted", "Change", "Unnatural", "Fair", "Structure", "Chain", "Shelter", "Fall", "Temple", "Midnight", "Percussive", "Stray", "Lounge", "Health", "Ball", "Door",
        "Shoe", "Talk", "Song", "Obligation", "Duty", "Contradict", "Gossip", "Fresh", "Gift", "Bother", "Silver", "Break", "Accident", "Indication", "Original", "Pilgrim", "Embrace", "Implicate", "Impact", "Code", "Gold", "Cover", "Girl", "Tale", "Chase",
        "Turned", "Ammunition", "Shape", "Dimension", "Bell", "Valuable", "Boy", "Water", "Nimble", "Direct", "Give", "Seat", "Symbol", "Gap", "Fear", "Sell", "Proposal", "Heat", "Express", "Advance", "Moan", "Wary", "Effect", "Noise", "Alarm", "Sleep",
        "Son", "Book", "Communion", "Mould", "Warrant", "Vessel", "Friend", "Beast", "Cloud", "Dark", "Daughter", "Goods", "Duty", "Punish", "Surprise", "Group", "Track", "Proof", "Performance", "Weird", "Village", "Guide", "Original", "March", "Temporary",
        "Web", "Foot", "Smoke", "Mirror", "Command", "Warning", "Prize", "Communion", "Banter", "Fancy", "Demand", "Damage", "Wine", "Slice", "Entice", "Ruined", "Boon", "Escape", "Fugitive", "Unsafe", "Treacherous", "Clue", "Familiar", "Rotten", "Slave",
        "Captive", "Saviour", "Food", "Uncharacteristic", "Rancid", "Fetid", "Malodorous", "Noxious", "Investigate", "Entertain", "Silence", "Sneak", "Trapped", "Knowledge", "Accomplice", "Henchman", "Illusion", "Item", "Controller", "Toss", "Disciple",
        "Secure", "NPC", "Rescue", "Diversion", "Explosion", "Turgid", "Blustering", "Hidden", "Adventurers", "Luminescent", "Rusty", "Vibration", "Clank", "Gears", "Animal droppings", "Itch", "Smell", "Diary", "Dripping", "Escape", "Hairpin", "Slippery",
        "Severed hand", "Misty", "Floating", "Laugh", "Blood", "Cloying", "Enshrine", "Smoke", "Gaping", "Calm", "Subliminal", "Hollow", "Whoosh", "Arid", "Premonition", "Demon", "Force", "Statue", "Refuse pit", "Invite", "Obsidian", "Tunnel", "Sundered",
        "Fluttering", "Slide", "Silence", "Enlarge", "Boisterous", "Overexcited", "Erratic", "Decrepit", "Delusional", "Title", "Decayed", "Representative", "Suggestion", "Unthinking", "Spoiled", "Obstinate", "Rambling", "Raid", "Rainbow", "Effigy",
        "Scuttling", "Woven", "Ensnare", "Dispute", "Damaged", "Incongruous", "Hefty", "Mammoth", "Curiosity", "Precious", "Enthusiastic", "Rundown", "Unsophisticated", "Natural", "Persistent", "Revealing", "Prehistoric", "Awake", "River",
        "Slime", "Bones", "Stale air", "Rocky", "Decomposed", "Twilight", "Vacant", "Enter", "Twisted", "Chill", "Angular", "Scary", "Weather", "Portal", "Motive", "Bridge", "Garbage", "Foliage", "Aftershock", "Sentimental", "Sleep", "Toilet",
        "Livestock", "Glare", "Crypt", "Guard", "Sarcophagus", "Burial", "Rites", "Family", "Ruler", "Flee", "Protection", "Vision", "Wilderness", "Dungeon", "Nobility", "Orb", "Sceptre", "Armies", "Apocalypse", "Music", "Singing", "Gamble",
        "History", "Map", "Information", "Government", "Ability", "Love", "Temperature", "Area", "City", "Country", "Fire", "Language", "Equipment", "Technology", "Child", "Runes", "Medicine", "Teacher", "Disease", "Enlighten", "Road", "Location",
        "Success", "Student", "Possession", "Performance", "Lake", "Sea", "Message", "Man", "Woman", "Payment", "Skill", "Wealth", "Heart", "Storage", "Alcohol", "Argument", "Agreement", "Republic", "Tradition", "Employment", "Device", "Guidance",
        "Foreknowledge", "Candidate", "Confusion", "Guest", "Operation", "Temple", "Song", "Accident", "Girl", "Boy", "Proposal", "Son", "Daughter", "Village", "Warning", "Courage", "King", "Instrument", "Sister", "Brother", "Weakness", "Ambition",
        "Celebration", "Fortune", "Negotiation", "Midnight", "Obligation", "Indication", "Tale", "Water", "Heat", "Book", "Goods", "Guide", "Prize", "Admission", "Explosion", "Monopoly", "Reflect", "Disastrous", "Costume", "Few", "Medium", "Tidy", "Enchanted",
        "Percussive", "Duty", "Original", "Disguise", "Nimble", "Express", "Communion", "Duty", "Original", "identity", "Pleasant", "Manage", "Thick", "Route", "Swear", "Downward", "Awful", "Important", "Faith", "Change", "Stray", "Contradict", "Pilgrim",
        "Chase", "Direct", "Advance", "Mould", "Punish", "March", "Banter", "Building", "Glitter", "Uninterested", "Hole", "Common", "Mouth", "Maximum", "Memory", "Tedious", "Unnatural", "Lounge", "Gossip", "Embrace", "Turned", "Give", "Moan", "Warrant",
        "Surprise", "Temporary", "Fancy", "Orbit", "Release", "Endanger", "Temper", "Illuminate", "Refuse", "Nimble", "Express", "Communion", "Fair", "Health", "Fresh", "Implicate", "Ammunition", "Seat", "Wary", "Vessel", "Group", "Web", "Demand", "Nothing",
        "Question", "Birth", "Speed", "Craft", "Eye", "Step", "Item", "Metal", "Structure", "Ball", "Gift", "Impact", "Shape", "Symbol", "Effect", "Friend", "Track", "Foot", "Damage", "Target", "Skin", "Carry", "Mother", "Spirit", "Dream", "Luck", "Mouth",
        "Storm", "Chain", "Door", "Bother", "Code", "Dimension", "Gap", "Persecute", "Beast", "Proof", "Rope", "Wine", "Camp", "Champion", "Crack", "Native", "Opening", "Parent", "Pattern", "Request", "Shame", "Shelter", "Shoe", "Silver", "Gold", "Bell",
        "Fear", "Alarm", "Cloud", "Performance", "Mirror", "Slice", "Priest", "Resident", "Rope", "Witness", "Many", "Check", "Guard", "Potential", "Dance", "Fall", "Talk", "Break", "Cover", "Valuable", "Sell", "Sleep", "Dark", "Weird", "Command", "Forbidden", "Magic Item"
    ]

    return "Fate words give you a general theme, and you may interpret them as you wish. Your words are..." + "\n\n" + words[GetRandomInt(0, words.length - 1)] + "\n\n&\n\n" + words[GetRandomInt(0, words.length - 1)]

}

function GetRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}