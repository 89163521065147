import gsap from "gsap";
import { GetTooltipsActive } from "./tooltips";
const hintText = document.getElementById("hintText");
let isShowing = false;

const hints = [
  "TIP: When placing objects hold + to increase size or - to decrease size. Press Q or E to rotate object. Hold shift and press left click to duplicate objects.",
  "Eraser is active. Left click to delete an object.<br><br>Right click to dismiss the tool.",
  "Dice roller active. Select a multiplier, then a dice type, then left click on the world to roll the dice.",
  "TIP: Place objects like normal or select the button with the paintbrush icon to hold left click and paint objects,",
  "TIP: Press + or - while placing lights to increase or decrease their intensity and range",
  "Welcome to the BattleMapp Beta<br><br>Check out the buttons below to connect with me and stay <br>connected with the project. <br><br>And have fun :) ",
  "Ruler is active! Left-click to start measuring. Right-click to dismiss.",
];
export const showHint = (index) => {
  if (!isShowing && GetTooltipsActive()) {
    hintText.innerHTML = "<br><br>" + hints[index];
    isShowing = true;
    let tl = gsap.timeline();

    tl.to(hintText, {
      opacity: 1,
      duration: 1,
    }).to(hintText, {
      opacity: 0,
      duration: 3,
      delay: 8,
      onComplete: () => {
        isShowing = false;
      },
    });
  }
};
