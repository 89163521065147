export function TestFunction() {
  //
}

export function GetRandomWildernessNPC() {
  return (
    " is a " +
    GetAlignmentOfDiscovery() +
    ", " +
    GetRandomRace() +
    " " +
    GetWildernessNPCType() +
    ".\n\n" +
    " They " +
    GetNPCTrait() +
    ", " +
    GetNPCPersonality() +
    " and " +
    GetNPCQuirk() +
    " " +
    GetCompetence() +
    " " +
    NPCCurrentMood() +
    " " +
    PowerLevel() +
    "\n\n" +
    GetMainMotivation() +
    " " +
    CurrentDrivingForce()
  );
}

export function GetAlignmentOfDiscovery() {
  let alignments = [
    "lawful good",
    "neutral good",
    "chaotic good",
    "lawful neutral",
    "true neutral",
    "chaotic neutral",
    "lawful evil",
    "neutral evil",
    "chaotic evil",
  ];

  let int = GetRandomInt(0, alignments.length - 1);
  return alignments[int];
}

function GetRandomRace() {
  let races = ["dwarf", "elf", "gnome", "half-elf", "halfling", "half-orc"];

  let roll = GetRandomInt(0, 10);

  if (roll < 7) {
    return "human";
  } else {
    let int = GetRandomInt(0, races.length - 1);

    return races[int];
  }
}

function GetWildernessNPCType() {
  let wildernessNPCType = [
    "criminal",
    "adventurer",
    "explorer",
    "hunter/gatherer",
    "commoner",
    "ranger",
    "scout",
    "noble",
    "bandit",
    "thug",
    "thief",
    "smuggler",
    "farmer",
    "herder",
    "servant",
    "laborer",
    "soldier",
    "clergy",
    "merchant trading in " + GetMerchantType() + ".",
    "occultist",
    "drifter",
    "statesman",
    "duelist",
    "preacher",
    "rogue",
    "artisan",
    "missionary",
    "outcast",
    "mercenary",
    "hermit",
    "pioneer",
    "outlaw",
    "sorcerer",
    "villager",
    "conscript",
    "highwayman",
    "fortune-hunter",
    "recluse",
    "polymath",
    "magician",
    "vagrant",
    "politician",
    "crook",
    "activist",
    "hero",
    "champion",
    "cleric",
    "clairvoyant",
    "craftsman",
    "scientist",
    "performer",
    "brute",
    "inquisitor",
    "paladin",
    "villain",
    "professor",
    "tradesman",
    "hitman",
    "beggar",
    "warrior",
    "beast-master",
    "mysterious wanderer",
    "scout rider",
    "rugged warrior",
    "beggar",
    "criminal",
    "commoner",
    "soldier",
    "guard",
    "undertaker",
    "sage",
    "scholar",
    "wizard",
    "writer",
    "perfumer",
    "architect",
    "locksmith",
    "navigator",
    "apothecary",
    "clockmaker",
    "alchemist",
    "astrologer",
    "spy",
    "diplomat",
    "cartographer",
    "inventor",
    "driver",
    "porter",
    "monk",
    "clergy",
    "cobbler",
    "tailor",
    "weaver",
    "basketmaker",
    "potter",
    "carpenter",
    "baker",
    "ropemaker",
    "smith",
    "herbalist",
    "jeweler",
    "innkeeper",
    "artist",
    "actor",
    "minstrel",
    "armorer",
    "weaponsmith",
    "occultist",
    "drifter",
    "statesman",
    "duelist",
    "preacher",
    "rogue",
    "artisan",
    "missionary",
    "outcast",
    "mercenary",
    "hermit",
    "pioneer",
    "outlaw",
    "sorcerer",
    "villager",
    "conscript",
    "highwayman",
    "fortune-hunter",
    "recluse",
    "polymath",
    "magician",
    "vagrant",
    "politician",
    "crook",
    "activist",
    "hero",
    "champion",
    "cleric",
    "clairvoyant",
    "craftsman",
    "scientist",
    "performer",
    "brute",
    "inquisitor",
    "paladin",
    "villain",
    "professor",
    "tradesman",
    "hitman",
    "beggar",
    "warrior",
    "merchant trading in " + GetMerchantType() + ".",
  ];
  let int = GetRandomInt(0, wildernessNPCType.length - 1);
  return wildernessNPCType[int];
}

function GetMerchantType() {
  let merchantTypes = [
    "general goods",
    "raw materials",
    "grain",
    "livestock",
    "ale",
    "wine",
    "clothing",
    "jewellery",
    "weapons",
    "armor",
    "spices",
    "tobacco",
    "slave",
    "books",
    "scrolls",
    "magical supplies and items",
  ];
  let int = GetRandomInt(0, merchantTypes.length - 1);
  return merchantTypes[int];
}

export function GetNPCTrait() {
  let NPCtraits = [
    "are missing teeth",
    "are missing an eye",
    "are heavily tattooed",
    "have scarred features",
    "look shabby and unkempt",
    "are big, thick and brawny",
    "are small, scrawny even",
    "have wild, long hair",
    "have a shaved head",
    "have bright blue eyes",
    "have bloodshot eyes",
    "are clean and well-dressed",
    "are incredibly attractive",
  ];
  let int = GetRandomInt(0, NPCtraits.length - 1);
  return NPCtraits[int];
}

export function GetNPCPersonality() {
  let NPCpersonality = [
    "they are a loner. An antisocial person even.",
    "they are cruel and belligerent. A bully",
    "they are anxious, fearful, cowardly",
    "they are a greedy and envious person",
    "they are arrogant, aloof",
    "they are awkward, shy",
    "they are very orderly and compulsive",
    "they are over-confident, reckless even",
    "they are kind, generous, compassionate",
    "they are an easygoing, peaceful person",
    "they are cheerful, happy, optimistic",
  ];
  let int = GetRandomInt(0, NPCpersonality.length - 1);
  return NPCpersonality[int];
}

export function GetNPCQuirk() {
  let NPCquirk = [
    "beneath their exterior, they are a xenophobe.",
    "deep down they are insecure.",
    "they have a secret addiction.",
    "they are scared of " + GetPhobia() + ".",
    "they have many allergies.",
    "they are chronically asthmatic",
    "they are chronically ill.",
    "there are extremely superstitious.",
    "they are a devout worshipper",
    "they are a pack-rat.",
    "they are kind of a miser.",
    "they are a know-it-all.",
    "they are slightly delusional.",
    "they seem to be naive and idealistic.",
    "they tend to keep their hand on their weapon.",
    "their arm is in a sling.",
    "they have an out-of-town accent.",
    "they seem to be always eating.",
    "they seem to always be out of breath.",
    "they belch loud and often.",
    "they are a terrible cook.",
    "they tend to bite their lower lip.",
    "they carry a small mouse in their pocket.",
    "they carry a snake in their pocket.",
    "they carry a lizard in their pocket.",
    "they carry a satchel full of junk.",
    "they cough regularly.",
    "they are flatulant.",
    "they tend to gesture wildly with their hands.",
    "they are a little hard of hearing.",
    "they currently have a black eye.",
    "they tend to fidget all the time.",
    "they have a hacking cough.",
    "they seem intoxicated.",
    "they have a single gold tooth.",
    "their pockets clink with coins.",
    "they have a lazy eye.",
    "they have a limp.",
    "they have a lisp.",
    "their eyes are different colours.",
    "They are mute and speak soley through sign language.",
    "They never seem to look anyone in the eye.",
    "they pick their teeth with their knife.",
    "they have a chronic rash.",
    "they tend tor efer to themselves in theird-person.",
    "they have rings sewn onto their clothes.",
    "they have rope burns around their neck and wrists.",
    "their hands are covered in calluses.",
    "they only seem to eat meat.",
    "they smell like dogs.",
    "they smell like lavender.",
    "they tend to sniff fairly often.",
    "they regular use foul language.",
    "they have a squint.",
    "they do not have any fingernails.",
    "they tap their foot incessantly.",
  ];
  let int = GetRandomInt(0, NPCquirk.length - 1);
  return NPCquirk[int];
}

function GetPhobia() {
  let NPCquirk = [
    "the dark",
    "spiders",
    "rats",
    "being alone",
    "the opposite sex",
    "children",
    "the undead",
    "ghosts",
    "money",
    "being outside",
    "being inside",
    "authority",
    "livestock",
    "lizards",
    "birds",
    "insects",
    "scorpions",
    "pigs",
    "dogs",
    "bears",
    "wasps",
    "snakes",
    "fish",
    "goblins",
    "orcs",
    "gnolls",
    "trolls",
    "werewolves",
  ];
  let int = GetRandomInt(0, NPCquirk.length - 1);
  return NPCquirk[int];
}

function GetCompetence() {
  let NPCCompetence = [
    "They seem fairly incompetent, a liability, almost.",
    "They seem to be a competent individual.",
    "They seem like a very capable individual.",
    "They seem to be an exceptional individual.",
  ];
  let int = GetRandomInt(0, NPCCompetence.length - 1);
  return NPCCompetence[int];
}

function NPCCurrentMood() {
  let NPCmood = [
    "withdrawn",
    "guarded",
    "cautious",
    "neutral",
    "sociable",
    "helpful",
    "forthcoming",
  ];
  let int = GetRandomInt(0, NPCmood.length - 1);
  return "Their current mood is " + NPCmood[int] + ".";
}

function PowerLevel() {
  let NPClevel = [
    "much weaker than",
    "slightly weaker than",
    "comparable to",
    "slightly stronger than",
    "much stronger than",
  ];
  let int = GetRandomInt(0, NPClevel.length - 1);
  return "Their power level is " + NPClevel[int] + " yourself.";
}

export function GetMainMotivation() {
  let strings = [
    "They have had a calling to adventure which is wearing thin. Their goal is to return home.",
    "Their goal is to regain something which belongs to them but there are many obstacles standing in their way.",
    "Their goal is to fall in love, to fill the void inside them. A rival stands in their way.",
    "Their goal is to see the government fall for it has wronged their family.",
    "Their goal is to become powerful, to become the MOST powerful.",
    "Their goal is to free someone or something which has become trapped and is beyond their reach.",
    "Their goal is to be admired throughout the land.",
    "Their goal is to bring about peace throughout the land.",
    "Their goal is to escape the persecution which has been brought upon themselves.",
    "Their goal is to conquer. To wipe out their enemies.",
    "Their goal is to uncover the truth about a death of someone close to them.",
    "Their goal is to see their leader, the chosen one, ascend to the top.",
    "Their goal is to acquire riches, bounty and live a luxurious life.",
    "Their goal is to gain materialistic treasures by any means necessary.",
    "Their goal is to atone for something they did wrong, where they made a cruel choice in their past.",
    "Their goal is to gain freedom from the chains of their past.",
    "Their goal is to gain power, to gather followers and to be in charge.",
    "Their goal is to regain their lost honour.",
    "Their goal is to escape this place by any means necessary.",
    "Their goal is to cause political disorder and disruption by any means necessary.",
    "Their goal is to find a lost object which is dear to them.",
    "Their goal is to create an alliance, bring two parties together as one.",
    "Their goal is to destroy a relationship they are opposed to.",
    "Their goal is to collect on a debt which is owed to them.",
    "Their goal is to refute false rumours that follow them like shadows.",
    "Their goal is to find their rival  and harm them.",
    "Their goal is to cure an illness that has ravaged a loved one.",
    "Their goal is to find a person that has been taken.",
    "Their goal is to seize power in the region, by any means necessary.",
    "Their goal is to create an item of power, by any means necessary.",
    "Their goal is to secure provisions for their home settlement.",
    "Their goal is to protect a secret they have been entrusted with.",
    "Their goal is to find a way to enrich themselves.",
    "Their goal is to advance their status and standing in the region.",
    "Their goal is to  avenge a wrongdoing which has occured.",
    "Their goal is to find redemption, in whatever form that takes.",
    "Their goal is to escape from a past life, and find a fresh start.",
  ];
  let int = GetRandomInt(0, strings.length - 1);
  return strings[int];
}

export function CurrentDrivingForce() {
  let strings = [
    "Their hatred of bloodshed, and corruption",
    "Death, and amnesia",
    "An old promise, and hostility",
    "Kleptomania, and lovesickness",
    "Tradition, and rejection",
    "A wrongful accusation, and vengeance",
    "Desire, and a fractured mind",
    "A grudge, and the effects of brainwashing",
    "Guilt, and inequality",
    "Lovesickness, and unrealistic expectations",
    "A grudge, and temper",
    "Resentment, and their hatred of humans",
    "Empathy, and emotion",
    "Order, and ambition",
    "Spite, and lies",
    "Irrational fear, and vengeance",
    "The desire for acceptance, and accusations",
    "Drug induced insanity, and high standards",
    "Jealousy, and an old promise",
    "A pressing need, and dishonour",
    "Patience, and the bad state of the world",
    "Shame, and corruption",
    "Lovesickness, and desire",
    "Impatience, and the desire for pleasure",
    "Materialism, and the need to make up for past sins",
    "Hatred, and grudges",
    "Ego, and money",
    "Arrogance, and anger",
    "Their sense of contentment, and corruption",
    "A fractured mind, and a wrongful accusation",
    "Vanity, and accusations",
    "Noise, and social expectations",
    "The desire for fulfillment, and patriotism",
    "Patience, and ambition",
  ];
  let int = GetRandomInt(0, strings.length - 1);
  return "What drives them forward? " + strings[int] + ".";
}

export function RandomMaleFirstName() {
  let firstNames = [
    "Wyliame",
    "Yvain",
    "Grendel",
    "Bertin",
    "Ancel",
    "Lydan",
    "Syrin",
    "Ptorik",
    "Joz",
    "Varog",
    "Gethrod",
    "Hezra",
    "Feron",
    "Ophni",
    "Colborn",
    "Fintis",
    "Gatlin",
    "Jinto",
    "Hagalbar",
    "Krinn",
    "Lenox",
    "Revvyn",
    "Hodus",
    "Dimian",
    "Paskel",
    "Kontas",
    "Weston",
    "Azamarr",
    "Jather",
    "Tekren",
    "Jareth",
    "don",
    "Zaden",
    "Eune",
    "Graff",
    "Tez",
    "Jessop",
    "Gunnar",
    "Pike",
    "Domnhar",
    "Baske",
    "Jerrick",
    "Mavrek",
    "Riordan",
    "Wulfe",
    "Straus",
    "Tyvrik",
    "Henndar",
    "Favroe",
    "Whit",
    "Jaris",
    "Renham",
    "Kagran",
    "Lassrin",
    "Vadim",
    "Arlo",
    "Quintis",
    "Vale",
    "Caelan",
    "Yorjan",
    "Khron",
    "Ishmael",
    "Jakrin",
    "Fangar",
    "Roux",
    "Baxar",
    "Hawke",
    "Gatlen",
    "Barak",
    "Nazim",
    "Kadric",
    "Paquin",
    "Kent",
    "Moki",
    "Rankar",
    "Lothe",
    "Ryven",
    "Clawsen",
    "Pakker",
    "Embre",
    "Cassian",
    "Verssek",
    "Dagfinn",
    "Ebraheim",
    "Nesso",
    "Eldermar",
    "Rivik",
    "Rourke",
    "Barton",
    "Hemm",
    "Sarkin",
    "Blaiz",
    "Talon",
    "Agro",
    "Zagaroth",
    "Turrek",
    "Esdel",
    "Lustros",
    "Zenner",
    "Baashar",
    "Dagrod",
    "Gentar",
    "Feston",
    "Remi",
    "Guibert",
    "Edun",
    "Aldo",
    "Ivon",
    "Ausout",
    "Bertrand",
    "Lucius",
    "Maddock",
    "Bevan",
    "Elwyn",
    "Ysberin",
    "Pryderi",
    "Anyon",
    "Llion",
    "Neifion",
    "Geraint",
    "Baddon",
    "Donald",
    "Flan",
    "Aldyn",
    "Doncan",
    "Paric",
    "Dooil",
    "Donachan",
    "Quisten",
    "Michel",
    "Andrew",
    "Lonan",
    "Parick",
    "Thormot",
    "Austeyn",
    "Inry",
    "Fergus",
    "Asketil",
    "Aldyn",
    "Ossian",
    "Colum",
    "Adam",
    "German",
    "Yuan",
    "Nel",
    "Filip",
    "Nele",
    "Oter",
    "Anghus",
    "Brice",
    "Albert",
    "Horace",
    "Lambert",
    "Gerard",
    "Gregory",
    "Alistair",
    "Abne",
    "Dalton",
    "Oliver",
    "Clark",

    "Sissott",
    "Isot",
    "Aelid",
    "Tissot",

    "Renny",

    "Aiden",
    "Marv",
    "Alfred",
    "Leo",
    "Remi",
    "Leonard",
    "Juan",
    "Jack",
    "Umar",
    "Dewey",
    "Bert",
    "Ali",
    "Alistair",
    "Luke",
    "James",
    "Jimmy",
    "Harry",
    "Ibrahim",
    "Kyle",
    "Abdul",
    "Laurence",
    "Larry",
    "Donald",
    "Liam",
    "Rocco",
    "Rafael",
    "Kelly",
    "Jackson",
    "Hasan",
    "Shawn",
    "Mohammed",
    "Francis",
    "Frank",
    "Patrick",
    "Pat",
    "Thomas",
    "Tom",
    "Fergus",
    "Hugo",
    "Justin",
    "Joel",
    "Callum",
    "Adam",
    "Ivan",
    "Jax",
    "Mitchell",
    "Philip",
    "Phil",
    "Eugene",
    "Anghus",
    "Brice",
    "Albert",
    "Fred",
    "Frederick",
    "Gene",
    "Gregory",
    "Niall",
    "Oliver",
    "Oli",
    "Mark",
    "Clark",
  ];
  let rand = GetRandomInt(0, firstNames.length - 1);
  let firstName = firstNames[rand];

  return firstName;
}

export function GetFemaleFirstName() {
  let firstNames = [
    "Karen",
    "Molly",
    "Eve",
    "Malane",
    "Aileen",
    "Ina",
    "Breesha",
    "Mawde",
    "Reina",
    "Francaig",
    "Grayse",
    "Breda",
    "Grendel",
    "Tina",
    "Christina",
    "Karen",
    "Cristen",
    "Essa",
    "Doona",
    "Finola",
    "Effrica",
    "Juliana",
    "Sissott",
    "Nan",
    "Jinn",
    "Eletha",
    "Naevys",
    "Sana",
    "Tanila",
    "Baerinda",
    "Meriel",
    "Kenia",
    "Kasula",
    "Elenaril",
    "Ialantha",
    "Osonia",
    "Saria",
    "Gaelira",
    "Farryn",
    "Aurae",
    "Chamylla",
    "Chrissa",
    "Lucy",
    "Tara",
    "Jessica",
    "Jess",
    "Kat",
    "Syrana",

    "Resha",
    "Varin",
    "Wren",
    "Yuni",
    "Talis",
    "Kessa",
    "Magaltie",
    "Aeris",
    "Desmina",
    "Krynna",
    "Asralyn",
    "Herra",
    "Pret",
    "Kory",
    "Afia",
    "Tessel",
    "Rhiannon",
    "Zara",
    "Jesi",
    "Belen",
    "Rei",
    "Ciscra",
    "Temy",
    "Renalee",
    "Estyn",
    "Maarika",
    "Lynorr",
    "Tiv",
    "Annihya",
    "Semet",
    "Tamrin",
    "Antia",
    "Reslyn",
    "Basak",
    "Vixra",
    "Pekka",
    "Xavia",
    "Beatha",
    "Yarri",
    "Liris",
    "Sonali",
    "Razra",
    "Soko",
    "Maeve",
    "Everen",
    "Yelina",
    "Morwena",
    "Hagar",
    "Palra",
    "Elysa",
    "Sage",
    "Ketra",
    "Lynx",
    "Agama",
    "Thesra",
    "Tezani",
    "Ralia",
    "Esmee",
    "Heron",
    "Naima",
    "Rydna",
    "Sparrow",
    "Baakshi",
    "Ibera",
    "Phlox",
    "Dessa",
    "Braithe",
    "Taewen",
    "Larke",
    "Silene",
    "Phressa",
    "Esther",
    "Anika",
    "Rasy",
    "Harper",
    "Indie",
    "Vita",
    "Drusila",
    "Minha",
    "Surane",
    "Lassona",
    "Merula",
    "Kye",
    "Jonna",
    "Lyla",
    "Zet",
    "Orett",
    "Naphtalia",
    "Turi",
    "Rhays",
    "Shike",
    "Hartie",
    "Beela",
    "Leska",
    "Vemery",
    "Lunex",
    "Fidess",
    "Tisette",
    "Partha",
    "Katrina",
    "Alice",
    "Frances",
    "Grace",
    "Penny",
    "Renny",
    "Elena",
    "Salma",
    "Susan",
    "Lidia",
    "Juliana",
    "Jules",
    "Julie",
    "Emmie",
    "Sal",
    "Rachael",
    "Gemma",
    "Rosa",
    "Polly",
    "Ella",
    "Caitlin",
    "Erica",
    "Morgan",
    "Maddie",
    "Amber",
    "Izzy",
    "Isobel",
    "Faye",
    "Carrie",
    "Maria",
  ];
  let rand = GetRandomInt(0, firstNames.length - 1);
  let firstName = firstNames[rand];
  return firstName;
}

function GetRanomdTavernNames() {
  let names = [
    "The Jolly Huntress",
    "The Wild Stallions",
    "The Mangy Owlbear",
    "The Hungry Kitten",
    "The Laughing Lass",
    "The Sixth Finger",
    "Three Balls in the Air",
    "Bull’s Dismay",
    "The Giant Ant’s Head",
    "Five Golden Rings",
    "The Black Dragon",
    "The Open Book",
    "The Harpy’s Talon",
    "The Sword and the Shield",
    "The Frozen Squid",
    "The Jolly Owlbear",
    "The Goblin’s Ear",
    "The Old Cleric’s WalkingStick",
    "The Four Swords",
    "Jack McRory’s",
    "The Thief’s Trousers",
    "The Wooden Eye",
    "The Rat’s Reckoning",
    "The Giant’s Femur",
    "The Svelte Owlbear",
    "Boar’s Head",
    "The Lazy Lich",
    "The Flying Owlbear",
    "One Hundred Kobolds",
    "The Sleeping Dragon",
    "The Soaring Dragon",
    "The Empty Grave",
    "The Cracked Bell",
    "The Broken Bow",
    "The Hungry Owlbear",
    "The Horned Helmet",
    "The Crossed Axes",
    "The Halfling’s Halberd",
    "The God’s Headache",
    "The Spirit’s Spirits",
    "The Cloven Hoof",
    "Seven Horseshoes and aHaversack",
    "Heward’s Public House",
    "The Two Headed Dog",
    "The Hydra’s Eyes",
    "The Batpub",
    "The Wailing Wench",
    "The Rolling Stone",
    "The Mossy Stone",
    "The Falling Rocks",
    "The Hero’s Legacy",
    "The Scarlet Cape",
    "The Floppy Hat",
    "The Orange Cat",
    "The Burning Tree",
    "The Sleeping Watchman",
    "The Fat Farmer",
    "The Pudgy Paladin",
    "Grandmother’s Granary",
    "The Sundered Tower",
    "The Laughing Loon",
    "The Lying Lobster",
    "The Shark’s Mouth",
    "The Glaring Owlbear",
    "The Serpent’s Head",
    "Five Arrows",
    "The Lovely Lady",
    "The Masked Lord",
    "The Courageous Fool",
    "The Dancing Dwarf",
    "The Golden Gnome",
    "The Crumbling Wall",
    "The Old House",
    "The Flooded Cellar",
    "The Crimson Owlbear",
    "The Clandestine Chariot",
    "The Worn Wagon",
    "The Sneaking Owlbear",
    "Wrapped in Vines",
    "The Embarrassed Elf",
    "Some Human’s Hut",
    "The Hooting Owlbear",
    "The Hooded Hawk",
    "The Sapling",
    "The Naked Nymph",
    "The Hunk of Meat",
    "The Mug of Beer",
    "The Rolling Barrel",
    "Four Bottles of Wine",
    "The Glowing Orb",
    "The Strange Symbol",
    "The Friendly Owlbear",
    "The Blank Sign",
    "The Open Book",
    "The Burning Book",
    "The Confused Owlbear",
    "The Bemused Bartender",
    "The Empty Throne",
    "The Forked Tongue",
  ];

  let int = GetRandomInt(0, names.length - 1);
  return names[int];
}

export function RandomLastName() {
  let lastNames = [
    "Symonet",
    "Willelmus",
    "Moreland",
    "Sanson",
    "Grendel",
    "Bartholomeus",
    "Curteis",
    "Courtois",
    "Curteis",
    "Hervoet",
    "Robion",
    "Charlot",
    "Sanson",
    "Azemar",
    "Garnot",
    "Vollon",
    "Pinesworn",
    "Windgleam",
    "Harddew",
    "Fusebow",
    "Whiteforest",
    "Sunwind",
    "Phoenixbinder",
    "Farrowwhisper",
    "Hillsword",
    "Bladescribe",
    "Rosewatcher",
    "Grizzlyhide",
    "Watersprinter",
    "Hellblower",
    "Rosecreek",
    "Tarrenbow",
    "Goldhair",
    "Rapidbraid",
    "Morningsinger",
    "Whitecreek",
    "Snowstride",
    "Swiftroot",
    "Distantgrain",
    "Redchewer",
    "Gorewood",
    "Embergust",
    "Doomwound",
    "Leafhelm",
    "Hillgrip",
    "Shieldsplitter",
    "the Resilient",
    "the Fair",
    "the Mountain",
    "the Daring",
    "the Honest",
    "the Lionheart",
    "the Shadow",
    "the Huge",
    "Fredericus",
    "Donaldus",
    "Onfroi",
    "Goscelin",
    "Gyffard",
    "Folke",
    "Gervas",
    "Yvon",
    "Terry",
    "Davi",
    "Dob",
    "Jaquemon",
    "Addy",
    "Alain",
    "Emericus",
    "Colinus",
    "Goffridus",
    "Hubertus",
    "Guilielm",
    "Reynaud",
    "Charles",
    "Berinon",
    "Geraint",
    "Gales",
    "Symonet",
    "Mason",
    "Ali",
    "Cooper",
    "Cobb",
    "Courtois",
    "Booth",
    "Ross",
    "Wise",
    "Charlot",
    "Sanson",
    "Azemar",
    "Mcdonald",
    "Fernandez",
    "Thompson",
    "Davidson",
    "Ramirez",
    "Burns",
    "Robinson",
    "Kirby",
    "Evans",
    "Rowe",
    "Ford",
    "Paul",
    "Turner",
    "Wang",
    "Davis",
    "Ferguson",
    "Andrews",
    "Johnston",
    "Chambers",
    "Hamilton",
    "Moore",
    "Lowe",
    "Reid",
    "Walters",
    "Morgan",
    "Adams",
    "Reese",
    "Daniels",
    "Torres",
    "Chapman",
    "Carter",
    "May",
    "Smith",
    "Baldwin",
    "Fuller",
    "Wells",
    "Watts",
    "Campos",
    "McCarthy",
    "Hunt",
    "Burke",
    "McBride",
    "Rivera",
    "Walton",
    "Fleming",
    "Folke",
    "Gervais",
    "Tate",
    "Terry",
    "Campbell",
    "Graves",
    "Vaughn",
    "Donnelly",
    "Carroll",
    "Marsh",
    "Gardner",
    "Ortiz",
    "Pacheco",
    "Sutton",
    "Reynaud",
    "Charles",
    "Watkins",
    "Edwards",
    "Gales",
  ];
  let rand = GetRandomInt(0, lastNames.length - 1);
  let lastName = lastNames[rand];

  return lastName;
}

function RandomNickName() {
  let likes = [
    "Son",
    "Foxy",
    "Foxtail",
    "Boxer",
    "Watchman",
    "Wasp",
    "Wolf",
    "Prince",
    "Wise",
    "Mamba",
    "Sanson",
    "Wizard",
    "Cobra",
    "Stalker",
    "Bird",
    "Agony",
    "Goose",
    "Apex",
    "Apollo",
    "Kirby",
    "Baldy",
    "Banana",
    "Blaze",
    "Caveman",
    "Cypher",
    "Ducky",
    "Elvis",
    "Giggles",
    "Gucci",
    "Ghost",
    "Hannibal",
    "Hurricane",
    "Hi-Ho",
    "Jugs",
    "Jaws",
    "Judge",
    "Killer",
    "Legend",
    "Omelet",
    "Pyro",
    "Razor",
    "Rebond",
    "Shrek",
    "Salad",
    "Salsa",
    "Siesta",
    "T-bone",
    "Trash",
    "Vapor",
    "Teflon",
    "Viper",
    "Vodka",
    "Yoda",
    "Zen",
    "Vermin",
    "Ratdog",
    "Sparkles",
    "Grip",
    "Smudge",
    "Squidbait",
    "Snoopy",
    "Bugs",
    "Hefty",
    "Lefty",
    "Lego",
    "Limey",
    "Dash",
    "Dawg",
    "Deadstick",
    "Digger",
    "Diggler",
    "Dizzy",
    "Saint",
    "Sugar",
    "Scooter",
    "Sandman",
  ];
  let rand = GetRandomInt(0, likes.length - 1);
  let like = likes[rand];

  return like;
}

export function getRandomFullName() {
  let first = "";

  let rand = GetRandomInt(0, 20);
  if (rand > 10) {
    first = GetFemaleFirstName();
  } else {
    first = RandomMaleFirstName();
  }

  return first + " " + RandomLastName();
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
