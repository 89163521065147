import { renderer, scene, camera } from "../script";

export function saveAsImage() {
  var a = document.createElement("a");
  // Without 'preserveDrawingBuffer' set to true, we must render now
  renderer.render(scene, camera);
  a.href = renderer.domElement
    .toDataURL()
    .replace("image/png", "image/octet-stream");
  a.download = "canvas.png";
  a.click();
}
