import * as THREE from "three";
import * as APP from "../script";
import gsap from "gsap";
import * as UI from "./ui";
import { Vector3 } from "three";
import { loadFile } from "../app/managers/loadingmanagers";
import { InAMultiplayerSession } from "./multiplayer/status";
import { getUserData } from "./multiplayer/userData";
import { ref, set, get, onDisconnect } from "firebase/database";
import { getDB } from "./multiplayer/firebaseConfig";
import { showChatMessage } from "./multiplayer/chatbox";

let diceModels = [];
let diceTextures = [];
let resultsArray = [];
let endPoints = [];
let endPointIndex = 0;
let diceToDelete = [];
let diceSpread = 2;
let rollID;
let rolledMultiplayerDice = [];
let multiplayerDiceObjects = [];
let currentDataObject;
export let liveDie;
export let diceRolledEnabled = false;
let currentMultiplier;
let diceToRoll;
export let allowDiceRoller = true;
export function SelectCurrentMultiplier(value) {
  currentMultiplier = value;
}

export function TempDisableDiceRoller() {
  allowDiceRoller = false;
  //
  setTimeout(() => {
    allowDiceRoller = true;
    //
  }, 200);
}

export function DisableDiceRoller() {
  diceRolledEnabled = false;
  UI.SetDiceButtonHighlight(1, true);
}
export function EnableDiceRoller() {
  diceRolledEnabled = true;
}

export function SelectDiceToRoll(value) {
  diceToRoll = value;
}
let diceInAction = [];
const timer = (ms) => new Promise((res) => setTimeout(res, ms));

export function DeleteActiveDice() {
  diceToDelete = diceInAction;
  DisableDiceRoller();
  if (diceInAction.length > 0) {
    for (let i = 0; i < diceToDelete.length; i++) {
      gsap.to(diceToDelete[i].scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.3,
      });

      setTimeout(() => {
        diceInAction;
        APP.scene.remove(diceToDelete[i]);
      }, 300);
    }
  }
  if (InAMultiplayerSession()) {
    set(ref(getDB(), getUserData().roomID + "/game/dice/" + rollID), null);
  }
}
export async function RollDice(location) {
  await timer(250);
  if (allowDiceRoller) {
    DeleteActiveDice();
    resultsArray = [];
    endPoints = [];
    endPointIndex = 0;
    diceInAction = [];

    GenerateEndPoints(location, currentMultiplier);

    for (let i = 0; i < currentMultiplier; i++) {
      GetDice(diceToRoll, location);

      await timer(50);
    }

    set(ref(getDB(), getUserData().roomID + "/game/dice/" + rollID), null);

    const result = GetTotalResult();
    rollID = (Math.random() + 1).toString(36).substring(2);

    if (InAMultiplayerSession()) {
      set(
        ref(getDB(), getUserData().roomID + "/game/dicechat"),
        getUserData().userName + " just rolled a " + result.toString()
      );

      set(ref(getDB(), getUserData().roomID + "/game/dice/" + rollID), {
        user: getUserData().playerID,
        diceToRoll: diceToRoll,
        results: resultsArray,
        color: getUserData().color,
        location: location,
        multiplier: currentMultiplier,
        rollID: rollID,
      });
      const presenceRef = ref(
        getDB(),
        getUserData().roomID + "/game/dice/" + rollID
      );
      onDisconnect(presenceRef).set(null);
    }
  }
}

export async function RollMultiplayerDice(data) {
  ////////////
  currentDataObject = data;
  let rollstoRoll = [];
  // Find out which rolls to roll from the database
  // if they're not ours, and we haven't rolled them before, add them to the array or rolls.
  for (let i = 0; i < data.length; i++) {
    if (multiplayerDiceObjects.length > 0) {
      for (let j = 0; j < multiplayerDiceObjects.length; j++) {
        if (
          multiplayerDiceObjects[j].rollID != data[i].rollID &&
          data[i].user != getUserData().playerID
        ) {
          rollstoRoll.push(data[i]);
        }
      }
    } else {
      if (data[i].user != getUserData().playerID) {
        rollstoRoll.push(data[i]);
      }
    }
  }

  for (let i = 0; i < rollstoRoll.length; i++) {
    let ourEndPoints = [];
    let ourResultsArray = [];
    let diceRolled = [];
    for (let j = 0; j < rollstoRoll[i].multiplier; j++) {
      // Create final end points for locations
      let newEndPoint = new Vector3(
        rollstoRoll[i].location.x +
          RandomNumber(-diceSpread, diceSpread) +
          GetRandomFloat(-1, 1),
        rollstoRoll[i].location.y,
        rollstoRoll[i].location.z +
          RandomNumber(-diceSpread, diceSpread) +
          GetRandomFloat(-1, 1)
      );
      ourEndPoints.push(newEndPoint);
    }

    ourEndPoints = spreadOutEndPoints(ourEndPoints);
    for (let j = 0; j < rollstoRoll[i].multiplier; j++) {
      let die;
      for (let p = 0; p < diceModels.length; p++) {
        if (diceModels[p].name.includes(rollstoRoll[i].diceToRoll)) {
          die = diceModels[p].clone();
        }
      }
      const diceParent = new THREE.Object3D();
      APP.scene.add(diceParent);
      diceParent.position.x = ourEndPoints[j].x;
      diceParent.position.y = ourEndPoints[j].y;
      diceParent.position.z = ourEndPoints[j].z;
      APP.scene.add(die);
      diceParent.add(die);
      die.position.set(0, 0, 0);
      diceParent.rotation.y += RandomNumber(0, 2);
      diceParent.scale.multiplyScalar(0);
      die.traverse(function (child) {
        if (child.isMesh) {
          const tex = child.material.map;
          child.material = new THREE.MeshStandardMaterial({
            map: tex,
            color: new THREE.Color("#" + rollstoRoll[i].color),
          });
        }
      });
      let result = rollstoRoll[i].results[j];
      let rotation = GetRotation(rollstoRoll[i].diceToRoll, result);

      ourResultsArray.push(result);
      diceRolled.push(diceParent);

      AnimateDie(die, rotation, diceParent.position, diceParent);
    }

    const diceCollectionObject = {
      diceRolled: diceRolled,
      playerID: rollstoRoll[i].user,
      rollID: rollstoRoll[i].rollID,
    };

    multiplayerDiceObjects.push(diceCollectionObject);
  }

  if (multiplayerDiceObjects.length > 0) {
    for (let i = 0; i < multiplayerDiceObjects.length; i++) {
      let found = false;
      for (let j = 0; j < data.length; j++) {
        if (multiplayerDiceObjects[i].rollID == data[j].rollID) {
          found = true;
        }
      }
      if (!found) {
        for (let d = 0; d < multiplayerDiceObjects[i].diceRolled.length; d++) {
          APP.scene.remove(multiplayerDiceObjects[i].diceRolled[d]);
        }
        multiplayerDiceObjects.splice(i, 1);
      }
    }
  }
}

export function DeleteAllMultiplayerDice() {
  if (multiplayerDiceObjects.length > 0) {
    for (let i = 0; i < multiplayerDiceObjects.length; i++) {
      for (let d = 0; d < multiplayerDiceObjects[i].diceRolled.length; d++) {
        APP.scene.remove(multiplayerDiceObjects[i].diceRolled[d]);
      }
      multiplayerDiceObjects.splice(i, 1);
    }
  }
}
function GetTotalResult() {
  let sum = 0;
  for (let i = 0; i < resultsArray.length; i++) {
    sum += resultsArray[i];
  }

  UI.SetResultText(sum);
  if (!InAMultiplayerSession()) {
    showChatMessage("You rolled a " + sum.toString());
  }

  return sum;
}

function ValidateEndPoint(newEndPoint, location, numberToCreate) {
  let newEndPointValid = true;

  for (let i = 0; i < endPoints.length; i++) {
    if (newEndPoint.distanceTo(endPoints[i]) < 1) {
      newEndPointValid = false;
    }
  }

  if (newEndPointValid) {
    endPoints.push(newEndPoint);
    GenerateEndPoints(location, numberToCreate);
  } else {
    GenerateEndPoints(location, numberToCreate);
  }
}

function GenerateEndPoints(location, numberToCreate) {
  if (endPoints.length < numberToCreate) {
    let newEndPoint = new Vector3(
      location.x +
        RandomNumber(-diceSpread, diceSpread) +
        GetRandomFloat(-1, 1),
      location.y,
      location.z + RandomNumber(-diceSpread, diceSpread) + GetRandomFloat(-1, 1)
    );

    ValidateEndPoint(newEndPoint, location, numberToCreate);
  }
}

export function AddDiceModels(model) {
  diceModels.push(model);
}
export function AddDiceTextures(tex) {
  diceTextures.push(tex);
  tex.flipY = false;
  tex.wrapS = THREE.RepeatWrapping;
  tex.wrapT = THREE.RepeatWrapping;
  tex.repeat.set(1, 1);
}
export function GetDice(diceName) {
  let die;

  for (let i = 0; i < diceModels.length; i++) {
    if (diceModels[i].name.includes(diceName)) {
      die = diceModels[i].clone();
    }
  }

  const diceParent = new THREE.Object3D();

  APP.scene.add(diceParent);
  diceParent.position.x = endPoints[endPointIndex].x;
  diceParent.position.y = endPoints[endPointIndex].y;
  diceParent.position.z = endPoints[endPointIndex].z;

  APP.scene.add(die);
  diceParent.add(die);
  die.position.set(0, 0, 0);

  diceParent.rotation.y += RandomNumber(0, 2);

  diceParent.scale.multiplyScalar(0);

  let col = GetRandomColor();

  if (InAMultiplayerSession()) {
    col = new THREE.Color("#" + getUserData().color);
  }
  die.traverse(function (child) {
    if (child.isMesh) {
      const tex = child.material.map;

      child.material = new THREE.MeshStandardMaterial({
        map: tex,
        color: col,
      });
    }
  });

  liveDie = die;
  let result = GetResult(diceName);
  let rotation = GetRotation(diceName, result);

  resultsArray.push(result);

  diceInAction.push(diceParent);
  AnimateDie(die, rotation, diceParent.position, diceParent);
  endPointIndex++;
}

function GetRotation(diceName, result) {
  if (diceName == "d4") {
    return d4Rolls[result - 1];
  } else if (diceName == "d6") {
    return d6Rolls[result - 1];
  } else if (diceName == "d8") {
    return d8Rolls[result - 1];
  } else if (diceName == "d10") {
    return d10Rolls[result - 1];
  } else if (diceName == "d12") {
    return d12Rolls[result - 1];
  } else if (diceName == "d20") {
    return d20Rolls[result - 1];
  }
}

function GetResult(diceName) {
  if (diceName == "d4") {
    return RandomNumber(1, 4);
  } else if (diceName == "d6") {
    return RandomNumber(1, 6);
  } else if (diceName == "d8") {
    return RandomNumber(1, 8);
  } else if (diceName == "d10") {
    return RandomNumber(1, 10);
  } else if (diceName == "d12") {
    return RandomNumber(1, 12);
  } else if (diceName == "d20") {
    return RandomNumber(1, 20);
  }
}

function AnimateDie(die, finalRotation, location, diceParent) {
  let rotTl = new gsap.timeline();
  let positionTl = new gsap.timeline();
  let scaleTl = new gsap.timeline();
  die.rotation.set(
    RandomNumber(-100, 100),
    RandomNumber(-100, 100),
    RandomNumber(-100, 100)
  );

  scaleTl.to(diceParent.scale, {
    x: 1,
    y: 1,
    z: 1,
    duration: 0.3,
  });
  rotTl.to(die.rotation, {
    x: finalRotation.x,
    y: finalRotation.y,
    z: finalRotation.z,
    duration: 1.5,
    ease: "power4.out",
  });

  let pos = diceParent.position.y + RandomNumber(2, 5) + GetRandomFloat(-1, 1);

  positionTl
    .to(die.position, {
      x: 0,
      y: pos,
      z: 0,
      duration: 0.8,
      ease: "power4.out",
    })
    .to(die.position, {
      y: 0.4,
      duration: 0.5,
      ease: "power4.in",
    });
}

function RandomNumber(min, max) {
  return Math.floor(Math.random() * (1 + max - min) + min);
}

function GetRandomFloat(min, max) {
  if (max == null) {
    max = min == null ? Number.MAX_VALUE : min;
    min = 0.0;
  }

  if (min >= max) {
    throw new Error("Incorrect arguments.");
  }

  return min + (max - min) * randomizeValue();
}

function GetRandomColor() {
  let diceColor = new THREE.Color(0xffffff);
  diceColor.setHex(Math.random() * 0xffffff);

  return diceColor;
}

function randomizeValue() {
  var value = (1 + 10e-16) * Math.random();

  if (value > 1.0) {
    return 1.0;
  }

  return value;
}

let d4Rolls = [
  new THREE.Vector3(0, 0, 0),
  new THREE.Vector3(-8.199999999999982, 14.150000000000066, 0),
  new THREE.Vector3(-1.900000000000001, 3.649999999999995, 0),
  new THREE.Vector3(-0.9500000000000003, 0, 2.2),
];

let d6Rolls = [
  new THREE.Vector3(-1.7500000000000009, -1.6000000000000008, -0.2),
  new THREE.Vector3(-4.699999999999991, 0, 0),
  new THREE.Vector3(-3.349999999999996, -1.6000000000000008, -0.2),
  new THREE.Vector3(0, 0, 0),
  new THREE.Vector3(-1.5500000000000007, 0.05, 0),
  new THREE.Vector3(-4.7, 4.699999999999991, 0),
];
let d8Rolls = [
  new THREE.Vector3(-1.5500000000000007, 5.1999999999999895, -0.35),
  new THREE.Vector3(
    -1.900000000000001,
    6.449999999999985,
    -0.44999999999999996
  ),
  new THREE.Vector3(-2.000000000000001, 7.349999999999982, 0.9000000000000001),
  new THREE.Vector3(0, 0, 0),
  new THREE.Vector3(-2.25, 7.89999999999998, 5.349999999999989),
  new THREE.Vector3(
    -3.7499999999999947,
    6.8999999999999835,
    11.800000000000033
  ),
  new THREE.Vector3(-4.99999999999999, 1.3877787807814457e-17, 0),
  new THREE.Vector3(-6.749999999999984, 3.049999999999997, 4.399999999999992),
];

let d10Rolls = [
  new THREE.Vector3(
    -3.2499999999999956,
    22.150000000000016,
    -81.70000000000002
  ),
  new THREE.Vector3(63.900000000000006, -82.40000000000015, 26.59999999999998),
  new THREE.Vector3(-18.200000000000045, -65.10000000000045, 99.45000000000003),
  new THREE.Vector3(-83.1, -11.499999999999908, -22.099999999999973),
  new THREE.Vector3(67.95, 14.64999999999998, 68.60000000000002),
  new THREE.Vector3(-30.549999999999994, -6, -34.39999999999998),
  new THREE.Vector3(54.449999999999974, 69.80000000000007, 5.949999999999993),
  new THREE.Vector3(-58.04999999999988, -55, -55),
  new THREE.Vector3(-85.64999999999979, -82, -56.349999999999696),
  new THREE.Vector3(-82.59999999999997, -82, -50.25000000000004),
];
let d12Rolls = [
  new THREE.Vector3(16.899999999999984, -17.900000000000013, -90.1),
  new THREE.Vector3(
    57.599999999999966,
    28.099999999999987,
    -15.349999999999977
  ),
  new THREE.Vector3(57.84999999999995, 27.249999999999975, -14.799999999999969),
  new THREE.Vector3(-15.749999999999996, -15, -84.80000000000013),
  new THREE.Vector3(
    -16.80000000000001,
    -14.149999999999988,
    -84.80000000000013
  ),
  new THREE.Vector3(
    -10.549999999999923,
    -15.450000000000006,
    -84.75000000000013
  ),
  new THREE.Vector3(
    -14.699999999999982,
    -15.450000000000006,
    -80.70000000000036
  ),
  new THREE.Vector3(
    -15.749999999999996,
    -15.450000000000006,
    -80.60000000000036
  ),
  new THREE.Vector3(
    -17.100000000000016,
    -17.250000000000032,
    -80.30000000000038
  ),
  new THREE.Vector3(1.6000000000000008, 99.55000000000003, 28.80000000000001),
  new THREE.Vector3(2.1000000000000005, 100.44999999999997, 28.80000000000001),
  new THREE.Vector3(2.5999999999999988, 100.44999999999997, 27.24999999999999),
];
let d20Rolls = [
  new THREE.Vector3(67, 38.50000000000003, 34.099999999999994),
  new THREE.Vector3(64.45000000000014, 38.85000000000001, 34.14999999999999),
  new THREE.Vector3(61.40000000000032, 39.64999999999996, 34),
  new THREE.Vector3(61.35000000000032, 38.65000000000002, 36.44999999999986),
  new THREE.Vector3(61.7000000000003, 38.550000000000026, 37.5499999999998),
  new THREE.Vector3(61.60000000000031, 39.29999999999998, 35.09999999999994),
  new THREE.Vector3(59.20000000000044, 39.29999999999998, 35.09999999999994),
  new THREE.Vector3(57.90000000000052, 37.350000000000094, 35.09999999999994),
  new THREE.Vector3(58.2000000000005, 35.5000000000002, 35.09999999999994),
  new THREE.Vector3(58.35000000000049, 31.100000000000385, 33.25000000000004),
  new THREE.Vector3(86.00000000000011, -18.950000000000028, -81.85000000000001),
  new THREE.Vector3(82.30000000000032, -18.950000000000028, -82.79999999999995),
  new THREE.Vector3(80.40000000000043, -19.00000000000003, -82.64999999999996),
  new THREE.Vector3(9.449999999999964, 62, 18.900000000000027),
  new THREE.Vector3(9.499999999999964, 62, 21.30000000000006),
  new THREE.Vector3(8.849999999999955, 62.39999999999998, 23.750000000000096),
  new THREE.Vector3(6.999999999999948, 62.39999999999998, 25.950000000000127),
  new THREE.Vector3(
    -59.34999999999981,
    -69.24999999999993,
    -23.849999999999998
  ),
  new THREE.Vector3(
    -59.249999999999815,
    -69.24999999999993,
    -27.050000000000043
  ),
  new THREE.Vector3(-79.35000000000004, 99.9, 56.49999999999997),
];

let diceLoaded = false;
export async function LoadDice() {
  if (diceLoaded) return;
  let dicePaths = [
    "./models/dice/d4.glb",
    "./models/dice/d6.glb",
    "./models/dice/d8.glb",
    "./models/dice/d10.glb",
    "./models/dice/d12.glb",
    "./models/dice/d20.glb",
  ];

  for (let i = 0; i < dicePaths.length; i++) {
    const die = await loadFile(".glb", dicePaths[i]);
    die.scene.name = dicePaths[i];
    die.scene.scale.multiplyScalar(0.5);
    die.scene.traverse(async function (child) {
      child.castShadow = true;
      child.receiveShadow = true;
    });
    AddDiceModels(die.scene);

    die.scene.traverse(function (child) {
      child.castShadow = true;
    });
  }
}

// Write me a function that spreads out an array of vector 3s so that they are not too close together, then returns the array
function spreadOutEndPoints(endPoints) {
  for (let i = 0; i < endPoints.length; i++) {
    for (let j = 0; j < endPoints.length; j++) {
      if (i != j) {
        if (endPoints[i].distanceTo(endPoints[j]) < 1) {
          endPoints[i].add(new THREE.Vector3(4, 0, 4));
        }
      }
    }
  }

  return endPoints;
}
