export function GetNatureLandscape() {
  let natureLandscapes = [
    "/textures/ui/cards/landscapes/naturea.webp",
    "/textures/ui/cards/landscapes/natureb.webp",
    "/textures/ui/cards/landscapes/naturec.webp",
    "/textures/ui/cards/landscapes/natured.webp",
    "/textures/ui/cards/landscapes/naturee.webp",
    "/textures/ui/cards/landscapes/naturef.webp",
    "/textures/ui/cards/landscapes/natureg.webp",
    "/textures/ui/cards/landscapes/natureh.webp",
    "/textures/ui/cards/landscapes/naturei.webp",
    "/textures/ui/cards/landscapes/naturej.webp",
    "/textures/ui/cards/landscapes/naturek.webp",
    "/textures/ui/cards/landscapes/naturel.webp",
    "/textures/ui/cards/landscapes/naturem.webp",
    "/textures/ui/cards/landscapes/naturen.webp",
    "/textures/ui/cards/landscapes/natureo.webp",
    "/textures/ui/cards/landscapes/naturep.webp",
    "/textures/ui/cards/landscapes/natureq.webp",
  ];

  return natureLandscapes[GetRandomInt(0, natureLandscapes.length - 1)];
}

export function GetTownLandscape() {
  let townLandscapes = [
    "/textures/ui/cards/landscapes/towna.webp",
    "/textures/ui/cards/landscapes/townb.webp",
    "/textures/ui/cards/landscapes/townc.webp",
    "/textures/ui/cards/landscapes/townd.webp",
    "/textures/ui/cards/landscapes/towne.webp",
  ];
  return townLandscapes[GetRandomInt(0, townLandscapes.length - 1)];
}
export function GetDiscoveryLandscape() {
  let discoveryLandscapes = [
    "/textures/ui/cards/landscapes/discoverya.webp",
    "/textures/ui/cards/landscapes/discoveryb.webp",
    "/textures/ui/cards/landscapes/discoveryc.webp",
    "/textures/ui/cards/landscapes/discoveryd.webp",
    "/textures/ui/cards/landscapes/discoverye.webp",
  ];
  return discoveryLandscapes[GetRandomInt(0, discoveryLandscapes.length - 1)];
}

export function GetDungeonLandscape() {
  let dungeonLandscapes = ["/textures/ui/cards/landscapes/dungeona.webp"];
  return dungeonLandscapes[GetRandomInt(0, dungeonLandscapes.length - 1)];
}
export function GetFactionLandscape() {
  let factionLandscapes = [
    "/textures/ui/cards/landscapes/factiona.webp",
    "/textures/ui/cards/landscapes/factionb.webp",
  ];
  return factionLandscapes[GetRandomInt(0, factionLandscapes.length - 1)];
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
