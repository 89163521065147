import * as APP from "../script";
import * as SAVEGAME from "./managers/savegame";
import * as SCENETABLES from "./assets/scenetables";
import { saveMap, loadCampaign } from "./campaign";
import {
  AllowUpdateDatabase,
  InAMultiplayerSession,
} from "./multiplayer/status";
import { ref, set } from "firebase/database";
import { getDB } from "./multiplayer/firebaseConfig";
import { getGameID } from "./multiplayer/roomData";
import { getRandomString } from "../utils/getRandomString";

export let activeCharacters = [];
export let activeLevelObjects = [];
export let activeCards = [];
export let activeTrees = [];
export let activeRocks = [];
export let activeTiles = [];
export let activeTerrain = [];
export let activeMagic = [];
export let activeLights = [];
export let activeSelectors = [];
let allActiveObjects = [];

export let currentSceneTableType;
export let currentSceneFeltType;

export function SetCurrentSceneObjects(tableType) {
  if (tableType) currentSceneTableType = tableType;
}

export function GetCurrentSceneObjects(dataToReturn) {
  if (dataToReturn == "tableType") {
    return currentSceneTableType;
  }
  if (dataToReturn == "feltType") {
    return currentSceneFeltType;
  }
}

function RemoveFromArray(model, array) {
  const index = array.indexOf(model);
  if (index > -1) {
    array.splice(index, 1);
  }
}

function AddToAllObjects(model) {
  if (!allActiveObjects.includes(model)) allActiveObjects.push(model);

  if (InAMultiplayerSession() && AllowUpdateDatabase()) {
    // remove model from database
    set(ref(getDB(), getGameID() + "/game/map/" + model.userData.id), {
      id: model.userData.id,
      name: model.name,
      posX: Math.round(model.position.x * 100) / 100,
      posY: Math.round(model.position.y * 100) / 100,
      posZ: Math.round(model.position.z * 100) / 100,
      rotY: Math.round(model.rotation.y * 100) / 100,
      scaleY: Math.round(model.scale.y * 100) / 100,
      cardTitle: model.userData.cardTitle,
      cardBody: model.userData.cardBody,
      cardImage: model.userData.cardImage,
      cardColor: model.userData.cardColor,
      cardType: model.userData.cardType,
    });
  }
}

export function GetAllActiveObjects() {
  return allActiveObjects;
}

export function RemoveObjectFromGame(model) {
  if (allActiveObjects.includes(model))
    RemoveFromArray(model, allActiveObjects);
  if (activeTrees.includes(model)) RemoveFromArray(model, activeTrees);

  if (activeRocks.includes(model)) RemoveFromArray(model, activeRocks);

  if (activeTerrain.includes(model)) RemoveFromArray(model, activeTerrain);

  if (activeTiles.includes(model)) RemoveFromArray(model, activeTiles);

  if (activeMagic.includes(model)) RemoveFromArray(model, activeMagic);

  if (activeLights.includes(model)) RemoveFromArray(model, activeLights);

  if (activeCharacters.includes(model))
    RemoveFromArray(model, activeCharacters);

  if (activeSelectors.includes(model)) RemoveFromArray(model, activeSelectors);

  if (activeCards.includes(model)) RemoveFromArray(model, activeCards);

  if (allActiveObjects.includes(model))
    RemoveFromArray(model, allActiveObjects);

  if (InAMultiplayerSession()) {
    // remove model from database
    if (model) {
      set(ref(getDB(), getGameID() + "/game/map/" + model.userData.id), null);
    } else {
    }
  }
}
export function AddSelectorToGame(model) {
  if (!activeSelectors.includes(model)) activeSelectors.push(model);
  AddToAllObjects(model);
}

export function AddLevelObjectToGame(model) {
  if (!activeLevelObjects.includes(model)) activeLevelObjects.push(model);
  AddToAllObjects(model);
}
export function AddTreeToGame(model) {
  if (!activeTrees.includes(model)) activeTrees.push(model);
  AddToAllObjects(model);
}
export function AddLightToGame(model) {
  if (!activeLights.includes(model)) activeLights.push(model);
  AddToAllObjects(model);
}
export function AddRockToGame(model) {
  if (!activeRocks.includes(model)) activeRocks.push(model);
  AddToAllObjects(model);
}
export function AddTerrainToGame(model) {
  if (!activeTerrain.includes(model)) activeTerrain.push(model);
  AddToAllObjects(model);
}

export function AddNatureTilesToGame(model) {
  if (!activeTiles.includes(model)) activeTiles.push(model);
  AddToAllObjects(model);
}
export function AddMagicToGame(model) {
  if (!activeMagic.includes(model)) activeMagic.push(model);
  AddToAllObjects(model);
}
export function AddCharacterToGame(character) {
  if (!activeCharacters.includes(character)) activeCharacters.push(character);
  AddToAllObjects(character);
}
export function AddCardToGame(card) {
  if (!activeCards.includes(card)) activeCards.push(card);
  AddToAllObjects(card);
}
export function CheckForCharacter(object) {
  if (activeCharacters.includes(object)) return true;
  else return false;
}

export function GetTreeRaycastMask() {
  let mask = [];

  //  mask.push(APP.scene.getObjectByName("grid"));
  mask.push(SCENETABLES.GetCurrentTable());
  for (let i = 0; i < activeTiles.length; i++) {
    mask.push(activeTiles[i]);
  }
  for (let i = 0; i < activeTerrain.length; i++) {
    mask.push(activeTerrain[i]);
  }

  return mask;
}

export function ClearGame(removeModels, clearViaMultiplayer) {
  if (removeModels) {
    for (let i = 0; i < allActiveObjects.length; i++) {
      APP.scene.remove(allActiveObjects[i]);
    }
  }

  ClearLists();
  saveMap("From the CleapMap function in Gamestate");
  loadCampaign();

  if (InAMultiplayerSession() & clearViaMultiplayer) {
    set(ref(getDB(), getGameID() + "/game/map/"), null);
  }
}

function ClearLists() {
  activeCharacters = [];
  activeLevelObjects = [];
  activeCards = [];
  activeTrees = [];
  activeRocks = [];
  activeTiles = [];
  activeTerrain = [];
  activeMagic = [];
  activeLights = [];
  allActiveObjects = [];
}
