import { loadFile } from "../managers/loadingmanagers";
import * as APP from "../../script";
import * as THREE from "three";
import { MeshStandardMaterial } from "three";

let currentTable, currentFelt;
let woodTable, stoneTable, oceanTable, wizardTable, islandTable;

export function SetCurrentTable(table) {
  currentTable = table;
}
export function SetCurrentPlaymat(tex) {
  currentFelt = tex;
}
export function RemoveCurrentTable() {
  currentTable.visible = false;
  APP.scene.remove(currentTable);
}

export function GetCurrentTable() {
  return currentTable;
}

export function GetCurrentPlayMat() {
  let playarea;

  currentTable.traverse(function (child) {
    if (child.isMesh) {
      if (child.name.includes("playarea")) {
        playarea = child;
      }
    }
  });

  return playarea;
}

export function AddTableTemplate(type, loadedObject) {
  if (type == "wood") {
    woodTable = loadedObject;
  }
  if (type == "stone") {
    stoneTable = loadedObject;
  }
  if (type == "ocean") {
    oceanTable = loadedObject;
    // let water = buildWater();
  }
  if (type == "wizard") {
    wizardTable = loadedObject;
    // let water = buildWater();
  }
  if (type == "island") {
    islandTable = loadedObject;
    // let water = buildWater();
  }
}

let woodTemplateLoaded = false;
let stoneTemplateLoaded = false;
let oceanTemplateLoaded = false;
let wizardTemplateLoaded = false;
let islandTemplateLoaded = false;
export async function LoadTable(type, position) {
  let newTable;
  if (type == "wood") {
    if (woodTemplateLoaded) {
      newTable = woodTable.scene.clone();
    } else {
      woodTable = await loadFile(".glb", "./models/tables/ff_wood_packed.glb");
      newTable = woodTable.scene.clone();

      woodTemplateLoaded = true;
    }
    newTable.userData = { tableType: "wood" };
  }
  if (type == "stone") {
    if (stoneTemplateLoaded) {
      newTable = stoneTable.scene.clone();
    } else {
      stoneTable = await loadFile(
        ".glb",
        "./models/tables/ff_stone_packed.glb"
      );
      newTable = stoneTable.scene.clone();

      stoneTemplateLoaded = true;
    }
    newTable.userData = { tableType: "stone" };
  }
  if (type == "ocean") {
    if (oceanTemplateLoaded) {
      newTable = oceanTable.scene.clone();
    } else {
      oceanTable = await loadFile(
        ".glb",
        "./models/tables/ff_ocean_packed.glb"
      );
      newTable = oceanTable.scene.clone();
      oceanTemplateLoaded = true;
    }
    newTable.userData = { tableType: "ocean" };
  }
  if (type == "wizard") {
    if (wizardTemplateLoaded) {
      newTable = wizardTable.scene.clone();
    } else {
      wizardTable = await loadFile(
        ".glb",
        "./models/tables/ff_wizard_packed.glb"
      );
      newTable = wizardTable.scene.clone();
      wizardTemplateLoaded = true;
    }
    newTable.userData = { tableType: "wizard" };
  }
  if (type == "island") {
    if (islandTemplateLoaded) {
      newTable = islandTable.scene.clone();
    } else {
      islandTable = await loadFile(
        ".glb",
        "./models/tables/ff_island_packed.glb"
      );
      newTable = islandTable.scene.clone();
      islandTemplateLoaded = true;
    }
    newTable.userData = { tableType: "island" };
  }
  newTable.name = "sceneTable";
  newTable.scale.multiplyScalar(50);
  APP.scene.add(newTable);
  SetCurrentTable(newTable);

  newTable.position.set(position.x, position.y, position.z);
  newTable.visible = true;
  newTable.traverse(async function (child) {
    child.frustumCulled = false;
    child.castShadow = true;
    child.receiveShadow = true;
    if (child.material && child.material.map && child.name != "playarea") {
      let map = child.material.map;
      map.flipY = false;
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.repeat.set(1, 1);
      child.material = new THREE.MeshBasicMaterial({ map: map });
    }
    if (child.name.includes("playarea")) {
      if (currentFelt) {
        child.material = new MeshStandardMaterial({ map: currentFelt });
      } else {
        let tex = child.material.map;
        child.material = new MeshStandardMaterial({ map: tex });
        currentFelt = tex;
      }
    }

    if (child.name === "ocean_floor") {
      oceanTex = child.material.map;
    }
  });
}

let oceanTex;
export function SwitchTable(tableName) {
  RemoveCurrentTable();

  let currentFelt = GetCurrentPlayMat().material.map;

  if (tableName == "wood") {
    LoadTable("wood", new THREE.Vector3(0, 0, 0));
    waterBool = false;
  } else if (tableName == "stone") {
    LoadTable("stone", new THREE.Vector3(0, 0, 0));
    waterBool = false;
  } else if (tableName == "ocean") {
    LoadTable("ocean", new THREE.Vector3(0, 0, 0));
    waterBool = true;
  } else if (tableName == "wizard") {
    LoadTable("wizard", new THREE.Vector3(0, 0, 0));
    waterBool = false;
  } else if (tableName == "island") {
    LoadTable("island", new THREE.Vector3(0, 0, 0));
    waterBool = false;
  }
}

export let water;
export let waterBool = false;

const tick = () => {
  if (waterBool) {
    if (oceanTex) oceanTex.offset.x += 0.01 / 60.0;
  }
  window.requestAnimationFrame(tick);
};

tick();
