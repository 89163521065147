import * as THREE from "three";
import { loadFile } from "../managers/loadingmanagers";

export function GetPaintingModel() {
  let assetPaths = [
    "./models/trees/stump_a.glb",
    "./models/trees/tree6.glb",
    "./models/trees/tree7.glb",
    "./models/trees/tree10.glb",
    "./models/trees/tree11.glb",
    "./models/trees/tree12.glb",
  ];

  return assetPaths[GetRandomInt(0, assetPaths.length - 1)];
}
export function GetModel(modelID) {
  let assetPaths = [
    "./models/trees/bush1.glb",
    "./models/trees/bush2.glb",
    "./models/trees/bush3.glb",
    "./models/trees/bush4.glb",
    "./models/trees/stump_a.glb",
    "./models/trees/tree1a.glb",
    "./models/trees/tree1b.glb",
    "./models/trees/tree1c.glb",
    "./models/trees/tree2a.glb",
    "./models/trees/tree2b.glb",
    "./models/trees/tree3a.glb",
    "./models/trees/tree3b.glb",
    "./models/trees/tree4a.glb",
    "./models/trees/tree4b.glb",
    "./models/trees/tree5a.glb",
    "./models/trees/tree5b.glb",
    "./models/trees/tree6.glb",
    "./models/trees/tree7.glb",
    "./models/trees/tree10.glb",
    "./models/trees/tree11.glb",
    "./models/trees/tree12.glb",
  ];

  for (let i = 0; i < assetPaths.length; i++) {
    if (assetPaths[i].includes(modelID)) {
      return assetPaths[i];
    }
  }
}

let barkTex;
let leafTex;
let leafAlpha;
async function CreateTextures() {
  barkTex = await loadFile(".jpg", "./models/trees/textures/Atlas_Nature1.jpg");

  barkTex.flipY = false;
  barkTex.wrapS = THREE.RepeatWrapping;
  barkTex.wrapT = THREE.RepeatWrapping;
  barkTex.repeat.set(1, 1);

  leafTex = await loadFile(".jpg", "./models/trees/textures/Atlas1.jpg");

  leafTex.flipY = false;
  leafTex.wrapS = THREE.RepeatWrapping;
  leafTex.wrapT = THREE.RepeatWrapping;
  leafTex.repeat.set(1, 1);
  leafAlpha = await loadFile(
    ".jpg",
    "./models/trees/textures/Atlas_Alpha1.jpg"
  );

  leafAlpha.flipY = false;
  leafAlpha.wrapS = THREE.RepeatWrapping;
  leafAlpha.wrapT = THREE.RepeatWrapping;
  leafAlpha.repeat.set(1, 1);
}

CreateTextures();
export function SetModelMaterial(model) {
  if (model.material) {
    if (model.name.includes("leaves")) {
      model.material.map = leafTex;
      model.material.alphaMap = leafAlpha;
      model.material.alphaTest = 0.01;
      model.material.metalness = 0.0;
      model.material.roughness = 1;
    } else {
      model.material.map = barkTex;
    }
  }
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
