import * as THREE from "three";
import * as ANIMATIONS from "./animations";
import * as APP from "../script";
import * as GAMESTATE from "./gamestate";
import * as NATURETILES from "./assets/naturetiles";
import * as TREES from "./assets/trees";
import * as ROCKS from "./assets/rocks";
import * as MAGIC from "./assets/magic";
import * as SELECTIONMANAGER from "./managers/selectionmanager";
import * as TOPDOWNINTERIORS from "./assets/topdowninteriors";
import * as MEDIEVALEXTERIORS from "./assets/medievalexteriors";
import * as TOPDOWNDUNGEONS from "./assets/topdowndungeons";
import * as TOPDOWNCITY from "./assets/topdowncity";
import * as MONSTERS from "./assets/monsters";
import * as FIGURINES from "./assets/figurines";
import * as BASICS from "./assets/basics";
import * as HEROES from "./assets/heroes";
import * as SHIPS from "./assets/ships";
import { loadFile } from "./managers/loadingmanagers";
import { DoubleSide, MeshBasicMaterial, MeshStandardMaterial } from "three";
import { GetModelFromCache, AddModelToCache } from "./cache";
import gsap from "gsap";
import { getRandomString } from "../utils/getRandomString";
export let loadedModelCache = [];

export async function LoadSelector(_color) {
  // Load a glTF resource

  const selector = await loadFile(".gltf", "./models/tools/selector.gltf");
  let color = new THREE.Color("#" + "27ecf1");
  if (_color) {
    color = new THREE.Color("#" + _color);
  }
  selector.userData.color = _color;
  selector.scene.traverse(async function (child) {
    if (child.isMesh) {
      if (child.name == "selector_base") {
        child.material = new THREE.MeshBasicMaterial({
          side: DoubleSide,
          transparent: true,
          depthWrite: false,
          alphaMap: await loadFile(
            ".webp",
            "textures/ui/selector_base_alpha.webp"
          ),
          //  map: await loadFile(".webp", "textures/ui/selector_base.webp"),
          color: color,
        });
        child.material.alphaMap.flipY = false;
      } else {
        child.material = new THREE.MeshBasicMaterial({
          side: DoubleSide,
          transparent: true,
          depthWrite: false,
          alphaMap: await loadFile(
            ".webp",
            "textures/ui/selector_edge_alpha.webp"
          ),
          //  map: await loadFile(".webp", "textures/ui/selector_edge.webp"),
          color: color,
        });
        child.material.alphaMap.flipY = false;
        if (child.material.map) {
          child.material.map.flipY = false;
        }
      }
    }
  });

  APP.scene.add(selector.scene);
  selector.scene.visible = false;
  selector.scene.name = "selector";

  return selector;
}

export async function LoadModel(
  modelID,
  modelType,
  pos,
  rot,
  scale,
  parent,
  doNotScale,
  preloadScale,
  id
) {
  let newModel;
  const modelFromCache = GetModelFromCache(modelID);
  if (!modelFromCache) {
    newModel = await loadFile(".gltf", ScanForModelID(modelID, modelType));
    AddModelToCache(newModel);
    newModel = newModel.scene;
  } else {
    newModel = modelFromCache.scene.clone();
  }

  newModel.traverse(async function (child) {
    if (child.isMesh) {
      if (
        modelType != "hero" &&
        modelType != "topDownInterior" &&
        modelType != "topdownDungeon" &&
        modelType != "medievalExterior" &&
        modelType != "plantTrees" &&
        modelType != "topdownCity" &&
        modelType != "rocks" &&
        modelType != "ships"
      ) {
        child.material = await GetModelMaterial(child, modelType, modelID);
      }
      if (modelType == "plantTrees") {
        TREES.SetModelMaterial(child);
      }

      if (child.name == "WindMill01rot") {
        child.position.y = 7;
        // gsap.set(child.position, { y: 0.48 });
        gsap.to(child.rotation, {
          y: "-360",
          repeat: -1,
          duration: 150,
          ease: "none",
          delay: 0,
        });
      }

      if (modelID.includes("old_tree_rock")) {
        let t = child.material.map;

        child.material = new MeshStandardMaterial({
          map: t,
          roughness: 1,
        });
      }

      child.castShadow = true;
      child.receiveShadow = true;
    }
  });

  if (id != null) {
    newModel.userData.id = id;
  } else {
    newModel.userData.id = (Math.random() + 1).toString(36).substring(2);
  }

  newModel.userData.cardTitle = "none";
  newModel.userData.cardBody = "none";
  newModel.userData.cardImage = "none";
  newModel.userData.cardColor = "none";
  newModel.userData.cardType = "none";

  newModel.name = modelID;
  APP.scene.add(newModel);
  if (parent != null) {
    parent.add(newModel);
  }

  if (pos == null) {
    newModel.position.set(-1000, -1000, -1000);
  }

  if (modelType == "hero") {
    newModel.name = modelID;
    // HEROES.GetModelMaterial(newModel.scene);
  }
  if (modelType == "terrain") GAMESTATE.AddTerrainToGame(newModel);
  else if (modelType == "naturetiles" || modelType == "naturelayout") {
    GAMESTATE.AddNatureTilesToGame(newModel);
  } else if (modelType == "plantTrees") {
    GAMESTATE.AddTreeToGame(newModel);
  } else if (modelType == "rocks") {
    GAMESTATE.AddRockToGame(newModel);
  } else if (modelType == "magic") {
    GAMESTATE.AddMagicToGame(newModel);
  } else if (
    modelType == "basic" ||
    modelType == "hero" ||
    modelType == "figurine"
  ) {
    GAMESTATE.AddCharacterToGame(newModel);
  } else {
    GAMESTATE.AddLevelObjectToGame(newModel);
  }

  if (!doNotScale) ANIMATIONS.GrowaAnimation(newModel, GetGLTFscale(modelType));
  else ANIMATIONS.GrowaAnimation(newModel, preloadScale);

  if (pos == null) {
    SELECTIONMANAGER.MoveSelectedObject(newModel, modelType);
    SELECTIONMANAGER.CreatingNewObject();
    newModel.rotation.y = SELECTIONMANAGER.currentrotation;
  } else {
    newModel.position.set(pos.x, pos.y, pos.z);
    newModel.scale.set(scale, scale, scale);
    newModel.rotation.y = rot;
  }

  return newModel;
}

function ScanForModelID(modelID, modelType) {
  let modelToReturn;

  if (modelType == "topDownInterior") {
    modelToReturn = TOPDOWNINTERIORS.GetModel(modelID);
  }

  if (modelType == "figurine") {
    modelToReturn = FIGURINES.GetModel(modelID);
  }

  if (modelType == "medievalExterior") {
    modelToReturn = MEDIEVALEXTERIORS.GetModel(modelID);
  }

  if (modelType == "topdownDungeon") {
    modelToReturn = TOPDOWNDUNGEONS.GetModel(modelID);
  }

  if (modelType == "topdownCity") {
    modelToReturn = TOPDOWNCITY.GetModel(modelID);
  }

  if (modelType == "naturetiles") {
    modelToReturn = NATURETILES.GetModel(modelID);
  }

  if (modelType == "plantTrees") {
    modelToReturn = TREES.GetModel(modelID);
  }
  if (modelType == "rocks") {
    modelToReturn = ROCKS.GetModel(modelID);
  }
  if (modelType == "magic") {
    modelToReturn = MAGIC.GetModel(modelID);
  }
  if (modelType == "hero") {
    modelToReturn = HEROES.GetModel(modelID);
  }
  if (modelType == "monster") {
    modelToReturn = MONSTERS.GetModel(modelID);
  }
  if (modelType == "basic") {
    modelToReturn = BASICS.GetModel(modelID);
  }
  if (modelType == "ships") {
    modelToReturn = SHIPS.GetModel(modelID);
  }
  return modelToReturn;
}

async function GetModelMaterial(model, modelType, modelID) {
  let mat = new THREE.MeshBasicMaterial({
    color: 0x960000,
  });

  if (modelType == "medievalExterior") {
    mat = MEDIEVALEXTERIORS.GetModelMaterial(model);
  }

  if (modelType == "naturetiles") {
    mat = await NATURETILES.GetModelMaterial(modelID);
    model.name = mat.map.name;
  }

  if (modelType == "magic") {
    mat = MAGIC.GetModelMaterial(model);
  }

  if (modelType == "monster") {
    mat = MONSTERS.GetModelMaterial(model);
  }
  if (modelType == "basic") {
    mat = BASICS.GetModelMaterial(model);
  }

  if (modelType === "figurine") {
    mat = new THREE.MeshPhongMaterial({
      color: 0xffffff,
    });
  }
  return mat;
}

function GetGLTFscale(modeltype) {
  let scale = 1;

  if (modeltype == "medievalExterior") scale = 0.8;
  if (modeltype == "terrain") scale = 25;
  if (modeltype == "naturetiles") scale = 3;

  if (modeltype == "magic") scale = 3;
  if (modeltype == "basic") scale = 0.5;
  if (modeltype == "hero") scale = 0.8;
  if (modeltype == "figurine") scale = 1.6;
  return scale;
}

export function GetModelType(modelID) {
  if (TOPDOWNINTERIORS.GetModel(modelID) != null) {
    return "topDownInterior";
  }
  if (TOPDOWNCITY.GetModel(modelID) != null) {
    return "topdownCity";
  }
  if (FIGURINES.GetModel(modelID) != null) {
    return "figurine";
  }
  if (MEDIEVALEXTERIORS.GetModel(modelID) != null) {
    return "medievalExterior";
  }
  if (TOPDOWNDUNGEONS.GetModel(modelID) != null) {
    return "topdownDungeon";
  }
  if (NATURETILES.GetModel(modelID) != null) {
    return "naturetiles";
  }

  if (TREES.GetModel(modelID) != null) {
    return "plantTrees";
  }
  if (ROCKS.GetModel(modelID) != null) {
    return "rocks";
  }
  if (MAGIC.GetModel(modelID) != null) {
    return "magic";
  }
  if (MONSTERS.GetModel(modelID) != null) {
    return "monster";
  }
  if (BASICS.GetModel(modelID) != null) {
    return "basic";
  }
  if (HEROES.GetModel(modelID) != null) {
    return "hero";
  }
  if (SHIPS.GetModel(modelID) != null) {
    return "ships";
  }
  if (modelID == "light_entity") return "light_entity";
}

LoadSelector();
