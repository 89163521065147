import { getDatabase, ref, onValue, set, get, child } from "firebase/database";
import { getDB } from "./firebaseConfig";
import {
  DeleteAllMultiplayerDice,
  RemoveDisconnectedDice,
  RollMultiplayerDice,
} from "../dice";
import { UpdateMap, UpdateSelectors } from "./applyData";

import { getGameID } from "./roomData";
import { getUserData } from "./userData";
import { showChatMessage } from "./chatbox";
import { updateUserAvatarBar } from "./uiMultiplayer";
import { setInAMultiplayerSession } from "./status";
import { setSun } from "../lights";
import { ClearGame } from "../gamestate";
import { SetplayMat } from "../settings";
import { destroyAllMultiplayerSelectors } from "../managers/selectionmanager";

export async function SetUpListener() {
  let dbMapA = [];

  // Set Up Map Listener
  const db = getDatabase();
  const mapRef = ref(db, getGameID() + "/game/map");
  onValue(mapRef, (snapshot) => {
    let dbMap = [];
    const data = snapshot.val();

    if (data) {
      Object.values(data).forEach((val) => dbMap.push(val));
      UpdateMap(dbMap);
    }
  });

  // Set Up Dice Listener
  const diceChat = ref(getDB(), getGameID() + "/game/dicechat");

  onValue(diceChat, (snapshot) => {
    const data = snapshot.val();

    if (data) {
      const diceData = [];
      Object.values(data).forEach((val) => diceData.push(val));
      showChatMessage(data);
    }
  });

  // Set Up Dice Listener
  const diceList = ref(getDB(), getGameID() + "/game/dice/");

  onValue(diceList, (snapshot) => {
    const data = snapshot.val();

    if (data) {
      const diceData = [];
      Object.values(data).forEach((val) => diceData.push(val));
      RollMultiplayerDice(diceData);
    }
  });

  const diceGame = ref(getDB(), getGameID() + "/game/");

  onValue(diceGame, (snapshot) => {
    const data = snapshot.val();

    if (data && data.dice) {
    } else {
      DeleteAllMultiplayerDice();
    }
  });

  // Set Up User Game Listener
  const userList = ref(db, getGameID() + "/game/users");

  onValue(userList, (snapshot) => {
    const data = snapshot.val();

    if (data) {
      const userData = [];
      Object.values(data).forEach((val) => userData.push(val));

      userData.forEach((element) => {
        if (element.connectionStatus == "I disconnected") {
          userData.splice(userData.indexOf(element), 1);
        }
      });

      updateUserAvatarBar(userData);
    } else {
      set(ref(db, getGameID()), {});
    }
  });

  // Set Up Clear Map Listener
  const game = ref(db, getGameID() + "/game");

  onValue(game, (snapshot) => {
    const data = snapshot.val();

    if (data && data.map) {
    } else {
      ClearGame(true, true);
    }
  });

  // Set Up Sun Listener
  const sunRef = ref(db, getGameID() + "/game/sun");

  onValue(sunRef, (snapshot) => {
    const data = snapshot.val();

    if (data) {
      setSun(true);
    } else {
      setSun(false);
    }
  });

  // Set Up Playmat Listener
  const playmatRef = ref(db, getGameID() + "/game/playmat");

  onValue(playmatRef, (snapshot) => {
    const data = snapshot.val();

    if (data) {
      SetplayMat(data);
    }
  });

  // Set Up Selector Listener
  const selectorRef = ref(db, getGameID() + "/game/selectors");

  onValue(selectorRef, (snapshot) => {
    const data = snapshot.val();

    if (data) {
      const selectors = [];
      Object.values(data).forEach((val) => selectors.push(val));
      UpdateSelectors(selectors);
    }
  });

  const selectorsRef = ref(db, getGameID() + "/game/");

  onValue(selectorsRef, (snapshot) => {
    const data = snapshot.val();

    if (data) {
      const selectors = [];
      Object.values(data).forEach((val) => selectors.push(val));
      if (!data.selectors) {
        destroyAllMultiplayerSelectors();
      }
    }
  });
}
