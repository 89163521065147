import * as THREE from "three";
import { loadFile } from "../managers/loadingmanagers";

export function GetModel(modelID) {
  let assetPaths = [
    "./models/medievalexteriors/exterior_blacksmith1.glb",
    "./models/medievalexteriors/exterior_blacksmith2.glb",
    "./models/medievalexteriors/exterior_castlecourt1.glb",
    "./models/medievalexteriors/exterior_castlecourt2.glb",
    "./models/medievalexteriors/exterior_bridge1.glb",
    "./models/medievalexteriors/exterior_castletower1.glb",
    "./models/medievalexteriors/exterior_castletower2.glb",
    "./models/medievalexteriors/exterior_cemetary1.glb",
    "./models/medievalexteriors/exterior_church1.glb",
    "./models/medievalexteriors/exterior_churchruins1.glb",
    "./models/medievalexteriors/exterior_churchruins2.glb",
    "./models/medievalexteriors/exterior_house1.glb",
    "./models/medievalexteriors/exterior_house2.glb",
    "./models/medievalexteriors/exterior_house3.glb",
    "./models/medievalexteriors/exterior_house4.glb",
    "./models/medievalexteriors/exterior_house5.glb",
    "./models/medievalexteriors/exterior_house6.glb",
    "./models/medievalexteriors/exterior_house7.glb",
    "./models/medievalexteriors/exterior_house8.glb",
    "./models/medievalexteriors/exterior_housetower1.glb",
    "./models/medievalexteriors/exterior_manufactory1.glb",
    "./models/medievalexteriors/exterior_ruin1.glb",
    "./models/medievalexteriors/exterior_ruin2.glb",
    "./models/medievalexteriors/exterior_ruin3.glb",
    "./models/medievalexteriors/exterior_tower1.glb",
    "./models/medievalexteriors/exterior_tower2.glb",
    "./models/medievalexteriors/exterior_tower3.glb",
    "./models/medievalexteriors/exterior_tower4.glb",
    "./models/medievalexteriors/exterior_tower5.glb",
    "./models/medievalexteriors/exterior_tower6.glb",
    "./models/medievalexteriors/exterior_tower7.glb",
    "./models/medievalexteriors/exterior_well1.glb",
    "./models/medievalexteriors/exterior_windmill1.glb",
    "./models/medievalexteriors/exterior_windmill2.glb",
  ];
  for (let i = 0; i < assetPaths.length; i++) {
    if (assetPaths[i].includes(modelID)) {
      return assetPaths[i];
    }
  }
}

export async function GetModelMaterial(model) {
  let texturePaths = [
    "./models/medievalexteriors/textures/JPG/T_Blacksmith01_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Blacksmith02_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_CastleCourt01_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_CastleCourt02_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Bridge_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_CastleTower01_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_CastleTower02_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Cemetery_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Church01_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_ChurchRuins01_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_ChurchRuins02_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_House01_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_House02_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_House03_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_House04_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_House05_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_House06_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_House07_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_House08_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_HouseTower_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Manufactory_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Ruin01_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Ruin02_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Ruin03_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Tower01_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Tower02_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Tower03_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Tower04_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Tower05_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Tower06_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Tower07_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_Well_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_WindMill01_BC.jpg",
    "./models/medievalexteriors/textures/JPG/T_WindMill02_BC.jpg",
  ];
  let mat;
  let newTex;

  for (let i = 0; i < texturePaths.length; i++) {
    if (texturePaths[i].includes(model.name)) {
      newTex = await loadFile(".jpg", texturePaths[i]);
    }
  }

  newTex.flipY = false;
  newTex.wrapS = THREE.RepeatWrapping;
  newTex.wrapT = THREE.RepeatWrapping;
  newTex.repeat.set(1, 1);

  mat = new THREE.MeshStandardMaterial({
    map: newTex,
  });
  return mat;
}
