import * as APP from "../script";
import * as THREE from "three";
import * as LIGHTS from "./lights";
import * as GAMESTATE from "./gamestate";
import { loadFile } from "./managers/loadingmanagers";
import * as SCENETABLES from "./assets/scenetables";
import { GetPaths } from "./assets/naturelayouts";
import { saveMap } from "./campaign";
import { ref, set } from "firebase/database";
import { getDB } from "./multiplayer/firebaseConfig";
import { InAMultiplayerSession } from "./multiplayer/status";
import { getGameID } from "./multiplayer/roomData";
const button_quality = document.getElementById("qualityDropdownButton");
const button_theme = document.getElementById("themeDropdownButton");
const button_grid = document.getElementById("gridDropdownButton");

const button_qualityA = document.getElementById("qualityLinkA");
const button_qualityB = document.getElementById("qualityLinkB");
const button_qualityC = document.getElementById("qualityLinkC");

const button_themeA = document.getElementById("themeLinkA");
const button_themeB = document.getElementById("themeLinkB");
const button_themeC = document.getElementById("themeLinkC");
const button_themeD = document.getElementById("themeLinkD");
const button_themeE = document.getElementById("themeLinkE");
const button_gridA = document.getElementById("gridLinkA");
const button_gridB = document.getElementById("gridLinkB");
const button_gridC = document.getElementById("gridLinkC");

const button_confirm = document.getElementById("settingsCloseButton");

button_quality.onclick = function () {
  document.getElementById("quality-dropdown-content").style.display = "block";
  document.getElementById("theme-dropdown-content").style.display = "none";
  document.getElementById("grid-dropdown-content").style.display = "none";
  button_confirm.style.pointerEvents = "none";
};

button_theme.onclick = function () {
  document.getElementById("theme-dropdown-content").style.display = "block";
  document.getElementById("quality-dropdown-content").style.display = "none";
  document.getElementById("grid-dropdown-content").style.display = "none";
  button_confirm.style.pointerEvents = "none";
};

button_grid.onclick = function () {
  document.getElementById("grid-dropdown-content").style.display = "block";
  document.getElementById("theme-dropdown-content").style.display = "none";
  document.getElementById("quality-dropdown-content").style.display = "none";
  button_confirm.style.pointerEvents = "none";
};

button_qualityA.onclick = function () {
  document.getElementById("quality-dropdown-content").style.display = "none";
  document.getElementById("qualityDropdownButton").innerHTML = "Fastest";
  SetQuality(1);
  button_confirm.style.pointerEvents = "all";
};

button_qualityB.onclick = function () {
  document.getElementById("quality-dropdown-content").style.display = "none";
  document.getElementById("qualityDropdownButton").innerHTML = "Medium";
  SetQuality(2);
  button_confirm.style.pointerEvents = "all";
};

button_qualityC.onclick = function () {
  document.getElementById("quality-dropdown-content").style.display = "none";
  document.getElementById("qualityDropdownButton").innerHTML = "Best";
  SetQuality(3);
  button_confirm.style.pointerEvents = "all";
};

button_themeA.onclick = function () {
  document.getElementById("theme-dropdown-content").style.display = "none";
  document.getElementById("themeDropdownButton").innerHTML = "Wood";
  SetTheme(1);
  button_confirm.style.pointerEvents = "all";
};

button_themeB.onclick = function () {
  document.getElementById("theme-dropdown-content").style.display = "none";
  document.getElementById("themeDropdownButton").innerHTML = "Stone";
  SetTheme(2);
  button_confirm.style.pointerEvents = "all";
};

button_themeC.onclick = function () {
  document.getElementById("theme-dropdown-content").style.display = "none";
  document.getElementById("themeDropdownButton").innerHTML = "Ocean";
  SetTheme(3);
  button_confirm.style.pointerEvents = "all";
};
button_themeD.onclick = function () {
  document.getElementById("theme-dropdown-content").style.display = "none";
  document.getElementById("themeDropdownButton").innerHTML = "Wizard's Desk";
  SetTheme(4);
  button_confirm.style.pointerEvents = "all";
};
button_themeE.onclick = function () {
  document.getElementById("theme-dropdown-content").style.display = "none";
  document.getElementById("themeDropdownButton").innerHTML = "Stone Island";
  SetTheme(5);
  button_confirm.style.pointerEvents = "all";
};
button_gridA.onclick = function () {
  document.getElementById("grid-dropdown-content").style.display = "none";
  document.getElementById("gridDropdownButton").innerHTML = "Off";
  SetGrid(1);
  button_confirm.style.pointerEvents = "all";
};

button_gridB.onclick = function () {
  document.getElementById("grid-dropdown-content").style.display = "none";
  document.getElementById("gridDropdownButton").innerHTML = "White";
  SetGrid(2);
  button_confirm.style.pointerEvents = "all";
};

button_gridC.onclick = function () {
  document.getElementById("grid-dropdown-content").style.display = "none";
  document.getElementById("gridDropdownButton").innerHTML = "Black";
  SetGrid(3);
  button_confirm.style.pointerEvents = "all";
};

function SetQuality(index) {
  if (index === 1) {
    APP.renderer.shadowMap.enabled = false;
    LIGHTS.sun.castShadow = false;
  } else if (index === 2) {
    APP.renderer.shadowMap.enabled = true;
    APP.renderer.shadowMap.type = THREE.BasicShadowMap;
    LIGHTS.sun.castShadow = true;
  } else if (index === 3) {
    APP.renderer.shadowMap.enabled = true;
    APP.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    LIGHTS.sun.castShadow = true;
  }

  APP.scene.traverse(function (child) {
    if (child.material) {
      child.material.needsUpdate = true;
    }
  });
}

function SetTheme(index) {
  if (index == 1) {
    SCENETABLES.SwitchTable("wood");
    currentTable = "wood";
  }
  if (index == 2) {
    SCENETABLES.SwitchTable("stone");
    currentTable = "stone";
  }
  if (index == 3) {
    SCENETABLES.SwitchTable("ocean");
    currentTable = "ocean";
  }
  if (index == 4) {
    SCENETABLES.SwitchTable("wizard");
    currentTable = "wizard";
  }
  if (index == 5) {
    SCENETABLES.SwitchTable("island");
    currentTable = "island";
  }
  let playarea = SCENETABLES.GetCurrentPlayMat();
  currentFelt = playarea.material.map;
  ApplyAllSettings();
}

let waterMatTex;
let waterFeltTex;
let oceanFelt;
let playTextureCache = [];

const GetTextureFromCache = (texturePath) => {
  for (let i = 0; i < playTextureCache.length; i++) {
    if (playTextureCache[i].name == texturePath) {
      return playTextureCache[i].clone();
    }
  }
};

export async function SetplayMat(texturePath) {
  texturePath = GetPaths(texturePath);

  let newTex = GetTextureFromCache(texturePath);

  if (!newTex) {
    newTex = await loadFile(".webp", texturePath);
    playTextureCache.push(newTex);

    newTex.name = texturePath;
  }
  newTex.needsUpdate = true;
  let playarea = SCENETABLES.GetCurrentPlayMat();
  playarea.material.map = newTex;

  waterFeltTex = false;
  if (newTex.name.includes("ocean")) {
    waterFeltTex = true;
    oceanFelt = newTex;
    oceanFelt.wrapS = THREE.RepeatWrapping;
    oceanFelt.wrapT = THREE.RepeatWrapping;
  }
  SCENETABLES.SetCurrentPlaymat(newTex);
  saveMap();

  if (InAMultiplayerSession()) {
    set(ref(getDB(), getGameID() + "/game/playmat"), texturePath);
  }
}

const tick = () => {
  if (waterMatTex) waterMatTex.offset.x += 0.01 / 60.0;

  if (waterFeltTex) oceanFelt.offset.x += 0.01 / 60.0;
  window.requestAnimationFrame(tick);
};

tick();

export const removeGrid = () => {
  let currentGrid;
  if (APP.scene.getObjectByName("grid") != null) {
    currentGrid = APP.scene.getObjectByName("grid");

    currentGrid.traverse(function (obj) {
      APP.scene.remove(obj);
    });
    APP.scene.remove(currentGrid);
  }
};
function SetGrid(index) {
  removeGrid();

  if (index == 2) APP.scene.add(createAGrid(new THREE.Color(0xdfdfdf)));
  if (index == 3) APP.scene.add(createAGrid(new THREE.Color(0x555555)));
}

function createAGrid(color) {
  let gridX = 30;
  let gridZ = 30;
  let gridXLineLength = 30;
  let gridZLineLength = 30;
  let gridSpacing = 1;
  let points = [];
  for (let xi = 1; xi < gridX; xi++) {
    for (let x = 0; x < gridXLineLength; x++) {
      points.push(new THREE.Vector3(x * gridSpacing, 0, xi * gridSpacing));
      points.push(new THREE.Vector3(x + 1 * gridSpacing, 0, xi * gridSpacing));
    }
  }

  for (let zi = 1; zi < gridZ; zi++) {
    for (let z = 0; z < gridZLineLength; z++) {
      points.push(new THREE.Vector3(zi * gridSpacing, 0, z * gridSpacing));
      points.push(new THREE.Vector3(zi * gridSpacing, 0, z + 1 * gridSpacing));
    }
  }

  var material = new THREE.LineBasicMaterial({
    color: color,
    linejoin: "round",
    linewidth: 1,
  });

  let gridGeo = new THREE.BufferGeometry().setFromPoints(points);
  var line = new THREE.LineSegments(gridGeo, material);
  var gridObject = new THREE.Object3D();
  gridObject.add(line);
  gridObject.name = "grid";
  line.name = "grid";
  let yPos = -0.12;

  if (GAMESTATE.activeTiles.length > 0) yPos = -0.02;
  gridObject.position.set(-14.5, yPos, -14.5);
  return gridObject;
}

let currentTable = "wood";
let currentFelt = "greenfelt";

export function ApplyAllSettings() {
  GAMESTATE.SetCurrentSceneObjects(currentTable);
}
