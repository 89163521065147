import * as GAMESTATE from "./gamestate";
import * as APP from "../script";
import gsap from "gsap";
import * as THREE from "three";
import * as SETTINGS from "./settings";
import * as SCENETABLES from "./assets/scenetables";
import * as SAVEGAME from "./managers/savegame";
import * as LOADGAME from "./managers/loadgame";
import * as SELECTIONMANAGER from "./managers/selectionmanager";
import { saveMap } from "./campaign";
import { removeGrid } from "./settings";
let sceneTableTypes = [];
let sceneObjects = [[], [], []];
let sceneFeltTypes = [];

let sceneIndex = 0;
let tempGroup;

function GetCurrentScene(sceneIndex) {
  sceneTableTypes[sceneIndex] = GAMESTATE.GetCurrentSceneObjects("tableType");
  sceneFeltTypes[sceneIndex] = GAMESTATE.GetCurrentSceneObjects("feltType");
}

function RemovePreviousScene() {
  GAMESTATE.ClearGame(0, false);

  setTimeout(() => {
    APP.scene.remove(tempGroup);
  }, 2000);
}
function AnimateSwitch(direction) {
  removeGrid();
  tempGroup = new THREE.Group();
  APP.scene.add(tempGroup);
  let allObjects = GAMESTATE.GetAllActiveObjects();

  let table = SCENETABLES.GetCurrentTable();

  tempGroup.add(table);
  for (let i = 0; i < allObjects.length; i++) {
    tempGroup.add(allObjects[i]);
  }

  if (direction == "right") {
    gsap.to(tempGroup.rotation, {
      y: Math.PI / 8,
      duration: 1,
    });

    gsap.to(tempGroup.position, {
      x: -500,
      z: 500,
      duration: 2,
    });
  } else {
    gsap.to(tempGroup.rotation, {
      y: -Math.PI / 8,
      duration: 1,
    });

    gsap.to(tempGroup.position, {
      x: 500,
      z: -500,
      duration: 2,
    });
  }
}

export async function SwitchScenes(mapData, direction, objectsToLoad) {
  SELECTIONMANAGER.DeselectObject();

  AnimateSwitch(direction);
  InitNextScene(mapData, direction, objectsToLoad);
}

async function InitNextScene(mapData, direction, objectsToLoad) {
  let newTablePos;
  RemovePreviousScene();
  if (direction == "right") {
    newTablePos = new THREE.Vector3(500, 0, -500);
  } else {
    newTablePos = new THREE.Vector3(-500, 0, 500);
  }

  let newGroup = new THREE.Group();
  APP.scene.add(newGroup);
  newGroup.position.set(newTablePos.x, newTablePos.y, newTablePos.z);

  ///Get table
  if (mapData.tableType == "wood") {
    await SCENETABLES.LoadTable("wood", new THREE.Vector3(0, 0, 0));
  } else if (mapData.tableType == "stone") {
    await SCENETABLES.LoadTable("stone", new THREE.Vector3(0, 0, 0));
  } else if (mapData.tableType == "ocean") {
    await SCENETABLES.LoadTable("ocean", new THREE.Vector3(0, 0, 0));
  } else if (mapData.tableType == "wizard") {
    await SCENETABLES.LoadTable("wizard", new THREE.Vector3(0, 0, 0));
  } else if (mapData.tableType == "island") {
    await SCENETABLES.LoadTable("island", new THREE.Vector3(0, 0, 0));
  }

  newGroup.add(SCENETABLES.GetCurrentTable());

  SETTINGS.SetplayMat(mapData.feltType); /////////makes the game lag

  //GetObjects
  if (objectsToLoad) {
    LOADGAME.LoadGameToTable(objectsToLoad, newGroup);
  }

  AnimateNewScene(newGroup, direction);
}

function AnimateNewScene(table, direction) {
  if (direction == "right") {
    gsap.to(table.rotation, {
      y: -Math.PI / 8,
      duration: 1,
    });

    gsap.to(table.position, {
      x: 0,
      z: 0,
      duration: 2,
    });
  } else {
    gsap.to(table.rotation, {
      y: Math.PI / 8,
      duration: 1,
    });

    gsap.to(table.position, {
      x: 0,
      z: 0,
      duration: 2,
    });
  }
}
