export function GetRandomRegionName() {
  return "The " + GetRegionAdjective() + " " + GetRegionNoun();
}

export function GetRandomRegionDescription() {
  return (
    "This place is a " +
    GetPlaceSize() +
    " " +
    GetPlaceType() +
    " " +
    GetPlacePlacement() +
    "a " +
    GetPlaceSize() +
    " " +
    GetRegionType() +
    ". " +
    GetPlaceDanger()
  );
}

function GetPlaceSize() {
  let regionType = [
    "small, secluded",
    "small",
    "modestly sized",
    "fairly small",
    "fairly large",
    "small, private",
    "huge",
    "large",
    "massive",
  ];

  return regionType[GetRandomInt(0, regionType.length - 1)];
}

function GetPlaceType() {
  let placeTypes = [
    "barrier",
    "beach",
    "bowl",
    "camp",
    "cave",
    "circle",
    "cliff",
    "crater",
    "crossing",
    "crypt",
    "den",
    "ditch",
    "falls",
    "fence",
    "field",
    "fort",
    "gate",
    "grove",
    "hill",
    "hole",
    "hut",
    "keep",
    "lake",
    "marsh",
    "meadow",
    "mountain",
    "pit",
    "post",
    "ridge",
    "ring",
    "rise",
    "road",
    "rock",
    "ruin",
    "shrine",
    "spire",
    "spring",
    "stone",
    "tangle",
    "temple",
    "throne",
    "tomb",
    "tower",
    "vale",
    "valley",
    "wall",
    "rocky outcrop",
    "waterway",
    "clear felled area",
    "large wooded gully",
    "foothills",
    "small wood",
    "thickets",
    "wasteland",
    "flatland",
    "wetland",
    "marsh",
    "swamp",
    "woodland",
    "forest",
    "plains",
    "highlands",
    "hills",
    "mountain",
  ];

  return placeTypes[GetRandomInt(0, placeTypes.length - 1)];
}

function GetRegionType() {
  let regionTypes = [
    "bay",
    "windy bluff",
    "natural bog",
    "series of cliff steppes",
    "desert",
    "wild expanse",
    "green fell",
    "natural fen",
    "area of salt flats",
    "area of rolling foothills",
    "evergreen forest",
    "plantation grove",
    "area of rocky hills",
    "deep jungle",
    "lake",
    "area of lowland",
    "area of marsh",
    "area of meadows",
    "area of mountains",
    "area of peaks",
    "plain",
    "prairie",
    "quagmire",
    "range",
    "savanna",
    "swamp",
    "wasteland",
    "wooded area",
  ];

  return regionTypes[GetRandomInt(0, regionTypes.length - 1)];
}

function GetPlacePlacement() {
  let regionType = [
    "in the middle of ",
    "at the " + GetPlaceDirection() + " of a",
  ];

  return "at the " + GetPlaceDirection() + " of ";
}

function GetPlaceDirection() {
  let regionType = [
    "north",
    "east",
    "south",
    "west",
    "northeast",
    "northwest",
    "southeast",
    "southwest",
  ];

  return regionType[GetRandomInt(0, regionType.length - 1)];
}

function GetPlaceDanger() {
  let regionType = [
    "The area is well-traveled and mostly safe if traveling in groups.",
    "The area is a common place to see other travelers on the road and fairly safe.",
    "This area is not well-traveled and although you may see other travelers on the road, it is wise to be wary.",
    "It is rare to see other travelers in this part of the world and you would do well to keep a dagger at your side.",
    "This area is fairly unknown to commoners and bandits (or worse!) may be present here.",
    "This area is fairly remote and unknown. Some rumours of dangerous creatures and dark magic linger in your mind.",
    "This place is known to be a dangerous area with wild creatures and dark magic present.",
    "This area is avoided by commoners due to bandits, wild animals and dark magic being present here.",
    "This area is forbidden to commoners due to the dangers of wild creatures and dark magic here.",
    "This is a wild area, known for its roving bandit groups and dangerous creatures of the wild..",
  ];

  return regionType[GetRandomInt(0, regionType.length - 1)];
}

function GetRegionAdjective() {
  let regionAdjective = [
    "Ageless",
    "Ashen",
    "Black",
    "Blessed",
    "Blighted",
    "Blue",
    "Broken",
    "Burning",
    "Cold",
    "Cursed",
    "Dark",
    "Dead",
    "Deadly",
    "Deep",
    "Desolate",
    "Diamond",
    "Dim",
    "Dismal",
    "Dun",
    "Eerie",
    "Endless",
    "Fallen",
    "Far",
    "Fell",
    "Flaming",
    "Forgotten",
    "Forsaken",
    "Frozen",
    "Glittering",
    "Golden",
    "Green",
    "Grim",
    "Holy",
    "Impassable",
    "Jagged",
    "Light",
    "Long",
    "Misty",
    "Perilous",
    "Purple",
    "Red",
    "Savage",
    "Shadowy",
    "Shattered",
    "Shifting",
    "Shining",
    "Silver",
    "White",
    "Wicked",
    "Yellow",
  ];
  let value = regionAdjective[GetRandomInt(0, regionAdjective.length - 1)];
  return value;
}

function GetRegionNoun() {
  let regionNouns = [
    "Ash",
    "Bone",
    "Darkness",
    "Dead",
    "Death",
    "Desolation",
    "Despair",
    "Devil",
    "Doom",
    "Dragon",
    "Fate",
    "Fear",
    "Fire",
    "Fury",
    "Ghost",
    "Giant",
    "God",
    "Gold",
    "Heaven",
    "Hell",
    "Honor",
    "Hope",
    "",
    "Horror",
    "King",
    "Life",
    "Light",
    "Lord",
    "Mist",
    "Peril",
    "Queen",
    "Rain",
    "Refuge",
    "Regret",
    "Savior",
    "Shadow",
    "Silver",
    "Skull",
    "Sky",
    "Smoke",
    "Snake",
    "Sorrow",
    "Storm",
    "Sun",
    "Thorn",
    "Thunder",
    "Traitor",
    "Troll",
    "Victory",
    "Witch",
  ];
  return regionNouns[GetRandomInt(0, regionNouns.length - 1)];
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
