import * as THREE from "three";
import * as APP from "../script";
import { loadFile } from "./managers/loadingmanagers";
import * as SELECTIONMANAGER from "./managers/selectionmanager";
import * as GAMESTATE from "./gamestate";
import * as RANDOMNPC from "./assets/tables/randomnpc";
import * as SETTLEMENTS from "./assets/tables/settlements";
import * as AVATARS from "./assets/avatars";
import * as LANDSCAPES from "./assets/landscapes";
import * as FACTIONS from "./assets/tables/factions";
import * as BUILDINGS from "./assets/tables/buildings";
import * as REGIONS from "./assets/tables/regions";
import * as FATEWORDS from "./assets/tables/fatewords.js";
import * as DISCOVERIES from "./assets/tables/discoveries";
import * as CHARACTERS from "./assets/tables/character";
import * as ORACLES from "./assets/tables/oracles";
import { Text } from "troika-three-text";
import gsap from "gsap";

let cardTemplate;
setCardTemplate();
async function setCardTemplate() {
  const card = await loadFile(".gltf", "./models/cards/playingcardc.gltf");
  cardTemplate = card.scene;
}

let backMaterials = [];
let faceMaterial, paperAlphaMaterial, textMaterial;
SetUpMaterials();

function SetUpMaterials() {
  SetUpBackMaterials();
  SetUpFaceMaterials();
  textMaterial = new THREE.MeshStandardMaterial();
}

async function SetUpBackMaterials() {
  let cardMatA = new THREE.MeshStandardMaterial({
    map: await loadFile(".webp", "/textures/ui/cards/Back_Card_Color_bw.webp"),
  });
  let cardMatB = new THREE.MeshStandardMaterial({
    map: await loadFile(
      ".webp",
      "/textures/ui/cards/Back_Card_Color_B_bw.webp"
    ),
  });
  let cardMatC = new THREE.MeshStandardMaterial({
    map: await loadFile(
      ".webp",
      "/textures/ui/cards/Back_Card_Color_C_bw.webp"
    ),
  });
  let cardMatD = new THREE.MeshStandardMaterial({
    map: await loadFile(
      ".webp",
      "/textures/ui/cards/Back_Card_Color_D_bw.webp"
    ),
  });
  let cardMatE = new THREE.MeshStandardMaterial({
    map: await loadFile(
      ".webp",
      "/textures/ui/cards/Back_Card_Color_E_bw.webp"
    ),
  });
  backMaterials = [cardMatA, cardMatB, cardMatC, cardMatD, cardMatE];
}

async function SetUpFaceMaterials() {
  faceMaterial = new THREE.MeshBasicMaterial({
    map: await loadFile(".webp", "/textures/ui/cards/Front_Card_Color_bw.webp"),
    alphaMap: await loadFile(
      ".webp",
      "/textures/ui/cards/Front_Card_Alpha.webp"
    ),
    transparent: true,
  });
  paperAlphaMaterial = new THREE.MeshBasicMaterial({
    map: await loadFile(".webp", "/textures/ui/cards/Card_Paper.webp"),
    alphaMap: await loadFile(
      ".webp",
      "/textures/ui/cards/Card_Paper_alpha.webp"
    ),
    transparent: true,
  });
}

async function GetAvatarImage(cardType, sex) {
  let textureUrl;
  let avatar;
  if (cardType === "Character") {
    if (sex === "male") {
      textureUrl = AVATARS.GetMaleAvatar();
      avatar = await loadFile(".webp", textureUrl);
    } else {
      textureUrl = AVATARS.GetFemaleAvatar();
      avatar = await loadFile(".webp", textureUrl);
    }
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
  if (cardType === "NPC") {
    let avatar;
    if (sex === "male") {
      textureUrl = AVATARS.GetMaleAvatar();
      avatar = await loadFile(".webp", textureUrl);
    } else {
      textureUrl = AVATARS.GetFemaleAvatar();
      avatar = await loadFile(".webp", textureUrl);
    }
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
  if (cardType === "Region") {
    textureUrl = LANDSCAPES.GetNatureLandscape();
    avatar = await loadFile(".webp", textureUrl);
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
  if (cardType === "Settlement") {
    textureUrl = LANDSCAPES.GetTownLandscape();
    avatar = await loadFile(".webp", textureUrl);
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
  if (cardType === "WildDiscovery") {
    textureUrl = LANDSCAPES.GetDiscoveryLandscape();
    avatar = await loadFile(".webp", textureUrl);
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
  if (cardType === "UrbanDiscovery") {
    textureUrl = LANDSCAPES.GetTownLandscape();
    avatar = await loadFile(".webp", textureUrl);
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
  if (cardType === "Faction") {
    textureUrl = LANDSCAPES.GetFactionLandscape();
    avatar = await loadFile(".webp", textureUrl);
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
  if (cardType === "Building") {
    textureUrl = LANDSCAPES.GetTownLandscape();
    avatar = await loadFile(".webp", textureUrl);
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
  if (cardType === "Tavern") {
    textureUrl = LANDSCAPES.GetTownLandscape();
    avatar = await loadFile(".webp", textureUrl);
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
  if (cardType === "Fate" || cardType === "Quest") {
    textureUrl = LANDSCAPES.GetDiscoveryLandscape();
    avatar = await loadFile(".webp", textureUrl);
    avatar.userData.textureUrl = textureUrl;
    return avatar;
  }
}

export async function Createcard(
  cardType,
  _cardTitle,
  _cardBody,
  _cardType,
  _cardImage,
  _cardColor,
  _position,
  _rotation,
  _id
) {
  if (_cardType) cardType = _cardType;
  let cardParent = new THREE.Object3D();
  APP.scene.add(cardParent);

  let newCard = cardTemplate.clone();
  APP.scene.add(newCard);
  newCard.name = "storycard";
  cardParent.add(newCard);
  newCard.position.set(0, 0.02, 0);
  cardParent.position.set(-1000, -1000, -1000);
  let cardColor = new THREE.Color(0xffffff);
  cardColor.setHex(Math.random() * 0xffffff);

  cardParent.rotation.y = SELECTIONMANAGER.currentrotation;

  if (_rotation) cardParent.rotation.y = _rotation;

  if (!_position) {
    SELECTIONMANAGER.MoveSelectedObject(cardParent, false, true);
    SELECTIONMANAGER.CreatingNewObject();
  } else {
    cardParent.position.set(_position.x, _position.y, _position.z);
  }

  cardParent.scale.multiplyScalar(2);

  let newTitletext = new Text({});
  APP.scene.add(newTitletext);
  newCard.add(newTitletext);
  newTitletext.text = "heelo worlds";
  newTitletext.position.set(0, -0.03, -0.8);
  newTitletext.rotation.x = Math.PI / 2;
  newTitletext.rotation.z = Math.PI;
  newTitletext.textAlign = "center";
  newTitletext.anchorX = "center";
  newTitletext.fontSize = 0.08;
  newTitletext.material = textMaterial;
  newTitletext.color = new THREE.Color(0xffffff);
  newTitletext.sync();

  let newBodytext = new Text({});
  APP.scene.add(newBodytext);
  newCard.add(newBodytext);
  newBodytext.text = "heelo worlds";
  newBodytext.position.set(0, -0.03, 0.3);
  newBodytext.rotation.x = Math.PI / 2;
  newBodytext.rotation.z = Math.PI;
  newBodytext.textAlign = "center";
  newBodytext.anchorX = "center";
  newBodytext.anchorY = "top";
  newBodytext.lineHeight = 1.2;
  newBodytext.material = textMaterial;
  newBodytext.color = new THREE.Color(0x000000);
  newBodytext.maxWidth = 0.9;
  if (cardType === "Character") newBodytext.fontSize = 0.05;
  if (cardType === "NPC") newBodytext.fontSize = 0.05;
  if (cardType === "Region") newBodytext.fontSize = 0.07;
  if (cardType === "Settlement") newBodytext.fontSize = 0.055;
  if (cardType === "WildDiscovery") newBodytext.fontSize = 0.07;
  if (cardType === "UrbanDiscovery") newBodytext.fontSize = 0.07;
  if (cardType === "Faction") newBodytext.fontSize = 0.06;
  if (cardType === "Building") newBodytext.fontSize = 0.065;
  if (cardType === "Tavern") newBodytext.fontSize = 0.065;
  if (cardType === "Fate") newBodytext.fontSize = 0.065;
  if (cardType === "Quest") newBodytext.fontSize = 0.065;
  newBodytext.sync();

  let sex, newName;

  if (cardType == "Character") {
    let roll = GetRandomInt(0, 20);
    if (roll > 10) {
      newName =
        RANDOMNPC.RandomMaleFirstName() + " " + RANDOMNPC.RandomLastName();
      sex = "male";
    } else {
      newName =
        RANDOMNPC.GetFemaleFirstName() + " " + RANDOMNPC.RandomLastName();
      sex = "female";
    }
    newTitletext.text = newName;
    newBodytext.text = newName + CHARACTERS.GetRandomCharacter();
  }
  let cardImage;

  cardImage = await GetAvatarImage(cardType, sex);

  if (cardType == "NPC") {
    let roll = GetRandomInt(0, 20);
    if (roll > 10) {
      newName =
        RANDOMNPC.RandomMaleFirstName() + " " + RANDOMNPC.RandomLastName();
      sex = "male";
    } else {
      newName =
        RANDOMNPC.GetFemaleFirstName() + " " + RANDOMNPC.RandomLastName();
      sex = "female";
    }
    newTitletext.text = newName;
    newBodytext.text = newName + RANDOMNPC.GetRandomWildernessNPC();
  }
  if (cardType == "Region") {
    let newName = REGIONS.GetRandomRegionName();
    newTitletext.text = newName;
    newBodytext.text = REGIONS.GetRandomRegionDescription();
  }
  if (cardType == "Settlement") {
    let newName = SETTLEMENTS.TownName();
    newTitletext.text = newName;
    newBodytext.text = SETTLEMENTS.GetRandomSettlement();
  }
  if (cardType == "WildDiscovery") {
    let newName = "Discovery!";
    newTitletext.text = newName;
    newBodytext.text = DISCOVERIES.GetWildnernessLocationDiscovery();
  }
  if (cardType == "UrbanDiscovery") {
    let newName = "Discovery!";
    newTitletext.text = newName;
    newBodytext.text = DISCOVERIES.GetUrbanLocationDiscovery();
  }
  if (cardType == "Faction") {
    let newName = FACTIONS.GetFactionName();
    newTitletext.text = newName;
    newBodytext.text = FACTIONS.GetRandomFaction();
  }
  if (cardType == "Building") {
    let newName = "What is this place?";
    newTitletext.text = newName;
    newBodytext.text = BUILDINGS.GetRandomBuilding();
  }
  if (cardType == "Tavern") {
    let newName = BUILDINGS.GetRandomTavernName();
    newTitletext.text = newName;
    newBodytext.text = BUILDINGS.GetRandomTavernDescription();
  }
  if (cardType == "Fate") {
    let newName = "Fate words";
    newTitletext.text = newName;
    newBodytext.text = FATEWORDS.GetRandomFateWord();
  }
  if (cardType == "Quest") {
    let newName = "Quest words";
    newTitletext.text = newName;
    newBodytext.text = ORACLES.GetQuestWords();
  }

  if (_cardTitle) newTitletext.text = _cardTitle;
  if (_cardBody) newBodytext.text = _cardBody;
  if (_cardImage) {
    cardImage = await loadFile(".webp", AVATARS.getSpecifcAvatar(_cardImage));

    cardImage.userData.textureUrl = _cardImage;
  }

  if (_cardColor) cardColor = _cardColor;

  cardParent.traverse(async function (child) {
    if (child.isMesh) {
      if (child.name == "CardFront") {
        child.material =
          backMaterials[GetRandomInt(0, backMaterials.length - 1)].clone();
        child.material.color = cardColor;
      }
      if (child.name == "CardBack") {
        child.material = faceMaterial.clone();
        child.material.color = cardColor;
      }
      if (child.name == "CardPaper") {
        child.material = paperAlphaMaterial.clone();
        //  child.material.color = cardColor;
      }
      if (child.name == "CardMiddle") {
        child.material = new THREE.MeshStandardMaterial({
          map: cardImage,
        });
        //  child.material.color = cardColor;
      }
      if (child.name == "PictureBackground") {
        child.material = new THREE.MeshStandardMaterial({
          color: 0x000000,
        });
        child.material.color = cardColor;
      }
    }
  });

  let id = (Math.random() + 1).toString(36).substring(2);

  if (_id) id = _id;

  cardParent.name = "storycard";
  cardParent.userData.id = id;
  cardParent.userData.cardTitle = newTitletext.text;
  cardParent.userData.cardBody = newBodytext.text;
  cardParent.userData.cardType = cardType;
  cardParent.userData.cardColor = cardColor;
  cardParent.userData.cardImage = cardImage.userData.textureUrl;
  GAMESTATE.AddCardToGame(cardParent);

  FlipCard(newCard);
}

function FlipCard(card) {
  gsap.to(card.rotation, {
    duration: 0.5,
    delay: 0.2,
    x: -Math.PI,
  });
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
