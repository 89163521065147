import * as THREE from "three";
import { loadFile } from "../managers/loadingmanagers";

export function GetModel(modelID) {
  let assetPaths = [
    "./models/topdowndungeons/Courner1A.glb",
    "./models/topdowndungeons/Courner1B.glb",
    "./models/topdowndungeons/Courner1C.glb",
    "./models/topdowndungeons/Courner2A.glb",
    "./models/topdowndungeons/Courner2B.glb",
    "./models/topdowndungeons/Courner2C.glb",
    "./models/topdowndungeons/Dungeons_Module1A.glb",
    "./models/topdowndungeons/Dungeons_Module1B.glb",
    "./models/topdowndungeons/Dungeons_Module1C.glb",
    "./models/topdowndungeons/Dungeons_Module1D.glb",
    "./models/topdowndungeons/Dungeons_Module2A.glb",
    "./models/topdowndungeons/Dungeons_Module2B.glb",
    "./models/topdowndungeons/Dungeons_Module3A.glb",
    "./models/topdowndungeons/Dungeons_Module3B.glb",
    "./models/topdowndungeons/Dungeons_Module3C.glb",
    "./models/topdowndungeons/Dungeons_Module3D.glb",
    "./models/topdowndungeons/Level_edge.glb",
    "./models/topdowndungeons/Level_Enter1.glb",
    "./models/topdowndungeons/Level_Enter2.glb",
    "./models/topdowndungeons/Room_down1.glb",
    "./models/topdowndungeons/Room_down2.glb",
    "./models/topdowndungeons/Room_down3.glb",
    "./models/topdowndungeons/Room_down4.glb",
    "./models/topdowndungeons/Room1.glb",
    "./models/topdowndungeons/Room2.glb",
    "./models/topdowndungeons/Room3A.glb",
    "./models/topdowndungeons/Room3B.glb",
    "./models/topdowndungeons/Room3C.glb",
    "./models/topdowndungeons/Room4.glb",
    "./models/topdowndungeons/Ground1.glb",
    "./models/topdowndungeons/Ground1b.glb",
    "./models/topdowndungeons/Ground1c.glb",
    "./models/topdowndungeons/Ground2a.glb",
    "./models/topdowndungeons/Ground3a.glb",
    "./models/topdowndungeons/Ground3b.glb",
    "./models/topdowndungeons/Ground3c.glb",
  ];

  for (let i = 0; i < assetPaths.length; i++) {
    if (assetPaths[i].includes(modelID)) {
      return assetPaths[i];
    }
  }
}
