import gsap from "gsap";
import * as TWEEN from "@tweenjs/tween.js";
import * as APP from "../script";
import { ref, set } from "firebase/database";
import { getDB } from "./multiplayer/firebaseConfig";
import { getGameID } from "./multiplayer/roomData";
import { InAMultiplayerSession } from "./multiplayer/status";
let currentlyRotating = false;
let modelToScale;
export function RotateHeldObject(clockwise) {
  //if (currentlyRotating == false) {
  if (APP.objectToFollowMousePoint != null) {
    let modelToRotate = APP.objectToFollowMousePoint;

    let rot = modelToRotate.y + 1;

    if (clockwise) modelToRotate.rotation.y += 0.1;
    else modelToRotate.rotation.y -= 0.1;
    // gsap.to(modelToRotate.rotation, {
    //   y: () => "+=" + 1,
    //   duration: 0.4,

    //   onComplete: function () {
    //     currentlyRotating = false;
    //   },
    // });

    currentlyRotating = true;

    if (InAMultiplayerSession()) {
      let model = modelToRotate;
      set(
        ref(getDB(), getGameID() + "/game/map/" + model.userData.id + "/rotY"),
        Math.round(model.rotation.y * 100) / 100
      );
    }
  }
  // }
}

export function ScaleHeldObject(larger) {
  if (APP.objectToFollowMousePoint != null) {
    modelToScale = APP.objectToFollowMousePoint;

    if (larger) {
      modelToScale.scale.x += modelToScale.scale.x * 0.03;
      modelToScale.scale.y += modelToScale.scale.y * 0.03;
      modelToScale.scale.z += modelToScale.scale.z * 0.03;
      // if (modelToScale.children.length > 1) {
      modelToScale.children[0].intensity++;

      //     }
    } else {
      modelToScale.scale.x -= modelToScale.scale.x * 0.03;
      modelToScale.scale.y -= modelToScale.scale.y * 0.03;
      modelToScale.scale.z -= modelToScale.scale.z * 0.03;

      modelToScale.children[0].intensity--;
    }
  }
}

document.addEventListener(
  "keyup",
  (event) => {
    const keyName = event.key;

    if (keyName === "=") {
      applyScaleToMultiplayer(modelToScale);
    }
    if (keyName === "-") {
      applyScaleToMultiplayer(modelToScale);
    }
  },
  false
);

function applyScaleToMultiplayer(modelToScale) {
  if (InAMultiplayerSession()) {
    let model = modelToScale;
    // set(
    //   ref(getDB(), getGameID() + "/game/map/" + model.userData.id + "/scale"),
    //   model.scale.y
    // );
  }
}
export function GrowaAnimation(object, scale) {
  object.scale.set(0, 0, 0);

  gsap.to(object.scale, {
    x: scale,
    y: scale,
    z: scale,
    duration: 0.3,
  });
}
