import * as APP from "../script";
import * as SELECTIONMANAGER from "../app/managers/selectionmanager";
import * as THREE from "three";
import * as GAMESTATE from "./gamestate";
import { Text } from "troika-three-text";
import dist from "webpack-merge";
let measuringStickActive = false;
let measuringObject;
let startingMeasuringObject;
let measuringStarted = false;
let distance;
let measureText;
let line;
let linePoints = [];
const lineMaterial = new THREE.LineDashedMaterial({
  color: 0xffffff,
  linewidth: 3,
  scale: 2,
  dashSize: 1,
  gapSize: 1,
});

export function getMeasuringStickActive() {
  return measuringStickActive;
}
export function getMeasuringStarted() {
  return measuringStarted;
}
export function activateMeasuringStick() {
  measuringStickActive = true;
  SELECTIONMANAGER.DeselectObject();
  var image = document.getElementById("measuringStick_icon");
  image.src = "./textures/ui/icon_ruler.webp";
}

export function deactivateMeasuringStick() {
  measuringStickActive = false;
  var image = document.getElementById("measuringStick_icon");
  image.src = "./textures/ui/icon_ruler_dark.webp";
  APP.scene.remove(measuringObject);
  APP.scene.remove(startingMeasuringObject);
  APP.scene.remove(measureText);
  APP.scene.remove(line);
  measuringObject = null;
  startingMeasuringObject = null;
  measuringStarted = false;
  line = null;
}

export function startMeasuring(loc) {
  if (startingMeasuringObject) {
    APP.scene.remove(measuringObject);
    APP.scene.remove(startingMeasuringObject);
    APP.scene.remove(measureText);
    APP.scene.remove(line);
    measuringObject = null;
    startingMeasuringObject = null;
    measureText = null;
    line = null;
  }
  measuringStarted = true;

  startingMeasuringObject = new THREE.Object3D();
  APP.scene.add(startingMeasuringObject);
  startingMeasuringObject.position.set(loc.x, loc.y, loc.z);

  const geometryb = new THREE.SphereGeometry(1, 3, 16);
  const materialb = new THREE.MeshBasicMaterial({ color: 0xffffff });
  measuringObject = new THREE.Mesh(geometryb, materialb);
  APP.scene.add(measuringObject);
  measuringObject.name = "measuringObject";

  const textMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff });

  measureText = new Text({});
  measureText.textAlign = "center";
  measureText.anchorX = "center";
  measureText.fontSize = 0.75;
  measureText.material = textMaterial;
  APP.scene.add(measureText);
}

export function resumeMeasuring(loc) {
  if (loc) {
    const middle = getMiddle(startingMeasuringObject.position, loc);
    distance = getDistance(startingMeasuringObject, loc);
    measureText.text = distance.toString() + "ft";
    measureText.position.set(middle.x, middle.y + 4, middle.z);
    measureText.lookAt(APP.camera.position);

    APP.scene.remove(line);

    const lineGeometry = new THREE.BufferGeometry().setFromPoints(linePoints);
    line = new THREE.Line(lineGeometry, lineMaterial);
    line.name = "measuringLine";
    linePoints = [];
    linePoints.push(new THREE.Vector3(loc.x, loc.y, loc.z));
    linePoints.push(
      new THREE.Vector3(
        startingMeasuringObject.position.x,
        startingMeasuringObject.position.y + 1,
        startingMeasuringObject.position.z
      )
    );
    APP.scene.add(line);

    line.computeLineDistances();
  }
}

//write me a function that gets the middle vector between two vectors in threejs
export function getMiddle(v1, v2) {
  let middle = new THREE.Vector3();
  middle.x = (v1.x + v2.x) / 2;
  middle.y = (v1.y + v2.y) / 2;
  middle.z = (v1.z + v2.z) / 2;
  return middle;
}

export function getDistance(object1, end) {
  let distance = Math.sqrt(
    Math.pow(object1.position.x - end.x, 2) +
      Math.pow(object1.position.y - end.y, 2) +
      Math.pow(object1.position.z - end.z, 2)
  );
  distance = Math.round(distance) * 5;

  return distance;
}
