// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { functions, exports } from "firebase/functions";
import { updateDoc, serverTimestamp } from "firebase/firestore";
//https://firebase.google.com/docs/functions/get-started

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7zCyeTQzsyoGmfDgK223rwnS2uOeRdLY",
  authDomain: "battlemapp-36278.firebaseapp.com",
  databaseURL:
    "https://battlemapp-36278-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "battlemapp-36278",
  storageBucket: "battlemapp-36278.appspot.com",
  messagingSenderId: "889268026346",
  appId: "1:889268026346:web:010e136d4b0c13d4f25093",
  measurementId: "G-5E8J90CFZE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getDatabase(app);

export const getDB = () => {
  return db;
};

export const _getAuth = () => {
  return auth;
};
