import * as RANDOMNPC from "./randomnpc";

export function GetRandomTavernDescription() {
    let type = [

        "Hostel", "tap house", "tavern", "brothel", "boarding house", "casino", "hotel", "guest house",
    ]

    let size = [

        " with only one room.", " with three guest rooms.", " with several guest rooms.", " with a large amount of guest rooms."
    ]

    let busyness = [

        "It is currently quiet.", "It is currently empty, seemingly abandoned.", "There are a few people milling around.", "There are a handful of people in the communal area.", "It is busy with guests and visitors.",
        "There are quite a number of people in the vicinity.", "The place is busy, with lots of people coming and going.", "The place is absolutely rammed with people."
    ]

    return "This establishment is a " + type[GetRandomInt(0, type.length - 1)] + size[GetRandomInt(0, size.length - 1)] + " " + GetRandomTavernComfortLevel() + " " + busyness[GetRandomInt(0, busyness.length - 1)];
}

function GetRandomTavernComfortLevel() {
    let strings = [

        "squalid. The rats seem to feel at home here and there’s a hole in the ceiling.",
        "poor, there are straw beds and shared rooms.",
        "modest, the blankets are rough but the rooms are worn and free from vermin.",
        "comfortable, with feather beds and soft pillows. Meals can be brought to your room.",
        "wealthy. You get your own private bathroom and the mattresses are comfortable.",
        "lavish, with goose down pillows and quick, professional service."

    ]

    return "The comfort level in this place is " + strings[GetRandomInt(0, strings.length - 1)];
}

export function GetRandomTavernName() {
    let strings = [

        "Amber", " Bellowing", " Bitter", "Black", "Blazing", "Bleak", "Blessed", "Bloody", "Blue", "Broken", "Broken", "Busy", "Cacophonous", "Copper", "Courageous", "Crimson", "Cunning", "Dancing", "Dead", "Disdainful", "Drunken", "Extravagant", "Fizzing",
        "Floating", "Flying", "Frosty", "GhastlyGhostly", "Glittering", "Golden", "Graceful", "Green", "Grotesque", "Harmless", "Heartless", "Helpful", "Hideous", "Honeyed", "Howling", "Hungry", "Jolly", "Kindly", "Last",
        "Lazy", "Loathsome", "Lovesick", "Loyal", "Melancholy", "Merry", "Moldy", "Musical", "Muttering", "Mysterious", "Nervous", "Nimble", "Oozing", "Petrified", "Prancing", "Purple", "Reckless", "Righteous",
        "Roaring", "Roasted", "Romantic", "RubySalty", "Scented", "Scorned", "Seven", "Shimmering", "Shivering", "Shrieking", "Sickly", "Silent", "Silver", "Singing", "Sleeping", "Smoking", "Squeaky", "Stubborn", "Tainted",
        "Terrible", "Thirsty", "Thirteen", "Thorny", "Three", "Tipsy", "Troublesome", "Two", "Vexing", "Violet", "Wailing", "Wandering", "Wanton", "Whispering", "Whistling", "White", "Wicked", "Wondrous", "Yellow"
    ]

    return "The " + strings[GetRandomInt(0, strings.length - 1)] + " " + GetRandomTavernNameB();
}

function GetRandomTavernNameB() {
    let strings = [

        "Adder", "Ant", "Axe", "Barrel", "Bear", "Beaver", "Beetle", "Bell", "Boar", "Bone", "Boot", "Bowl", "Bucket", "Bull", "Candle", "Cat", "ClawCloak", "Cock", "Coin", "Comb", "Cow", "Crow", "Crown", "Cup", "Door", "Dragon",
        "Eagle", "Egg", "Elephant", "Fang", "Fish", "Flea", "Giant", "Griffin", "Gristle", "Hare", "Hart", "Hawk", "Hen", "Hog", "Hole", "Horse", "Hound", "Jar", "Kettle", "Key", "Knife", "Lamb", "LampLantern", "Lion", "Lute",
        "Mackerel", "Maid", "Mermaid", "Mole", "Monk", "Moon", "Mule", "Needle", "Noose", "Orb", "Pearl", "Pig", "Pipe", "Plow", "Post", "Prince", "Queen", "Rat", "Rose", "Saint", "Serpent", "Shoe", "Shovel", "Skull", "Snout",
        "Sow", "Spoon", "Staff", "Star", "Stone", "Sun", "Sword", "Thistle", "Thorn", "Thread", "Throne", "Torch", "Tower", "Unicorn", "Vulture", "Weasel", "Whale", "Wheel", "Whistle", "Wife", "Worm"

    ]

    return strings[GetRandomInt(0, strings.length - 1)];
}

export function GetRandomBuilding() {
    let strings = [

        "Looking " + GetDirection() + " you see a " + GetSize() + " building made from " + GetMaterial() + " which seems to be " + GetBuildingType() + ".",

        "You pass a shopfront. It is a " + GetSize() + ", " + GetMaterial() + " building with a sign out front that reads \"" + RANDOMNPC.GetFemaleFirstName() + "'s " + GetShopType() + "\". It seems to be " + GetOpenOrClosed() + "."
    ]

    return strings[GetRandomInt(0, strings.length - 1)];
}

function GetOpenOrClosed() {
    let strings = [

        "closed for the day.", "currently closed.", "closed until further notice.", "closed due to fire damage.", "boarded up.", "currently open and quiet.", "currently open and busy."
    ]

    return strings[GetRandomInt(0, strings.length - 1)];
}

function GetDirection() {
    let strings = [

        "ahead", "to your left", "to your right", "to the east", "to the west", "to the north", "to the south"
    ]

    return strings[GetRandomInt(0, strings.length - 1)];
}

function GetSize() {
    let strings = [

        "small", "modestly-sized", "one-storey", "two-storey", "three-storey", "large", "very large"
    ]

    return strings[GetRandomInt(0, strings.length - 1)];
}

function GetMaterial() {
    let strings = [

        "wood", "stone", "stone block", "straw", "clay and brick", "rough brick", "wattle and daub", "clay cob", "log and timber", "slate", "lime mortar", "marble and granite"
    ]

    return strings[GetRandomInt(0, strings.length - 1)];
}

function GetBuildingType() {
    let strings = [

        "an abattoir", "an ale House", "a pub", "a tavern", "an archive of knowledge", "a fighting club", "a music venue", "a performance venue", "a sporting venue", "an asylum", "an auction house",
        "a bank", "a bakery", "a bathhouse", "an underground market", "a brewery", "a Brothel", "a House of Ill Repute", "a gambling hall", "a church", "a warehouse", "a chapel", "a coffeehouse",
        "a Convent", "a Nunnery", "a courthouse", "a crypt", "a mausoleum", "a tomb", "an opium den", "a gambling den", "a drug den", "a ruined hovel", "a construction site", "a sparring hall",
        "a militia training hall", "an arcane embassy", "an embassy", "a fighting pit", "a forge", "an executioner’s hall", "hanging gardens", "a garrison", "a granary", "a guard house",
        "a house of the " + GetGuildDesc() + " guild", "a town hall", "a meeting hall", "a music hall", "a hatchery", "a residential building", "a house", "a hostel", "a bed and breakfast", "a guest house",
        "a manor house", "a coach house", "a theater", "an inn", "a jail", "a prison", "an arcane library", "a royal library", "a general library", "a healing hall", "a feasting hall", "a holy house",
        "a monastery", "a museum or memorial hall", "an observatory", "an orphanage", "an outfitters", "a slum", "a sanctuary", "an arcane school", "a school for commoners", "a tool store", "a council office",
        "a politican’s office", "a tea house", "a trading post", "a guard’s watchtower", "a barrister’s office", "a constable’s office", "a courier’s office", "an information broker’s office", "a money lender’s office",
        "a money changer’s office", "a public records office", "a scribe’s office", "a warden’s office"
    ]

    return strings[GetRandomInt(0, strings.length - 1)];
}

function GetGuildDesc() {
    let strings = [

        "adventurers", "alchemist", "artisans", "assassins", "bankers", "bards", "bounty hunters", "caravan trade", "couriers", "courtesans", "craftsman", "demon hunters", "explorers", "fighters", "mage", "mage hunters",
        "masons", "merchants", "mercenary", "miners", "monster hunters", "monster trainers", "peace keepers", "relic hunters", "thieves", "thief hunters", "tinkerers", "trade", "treasure hunters", "warriors", "witch hunters", "wizards"

    ]

    return strings[GetRandomInt(0, strings.length - 1)];
}

function GetShopType() {
    let strings = [

        "alchemist's store", "animal trainer enterprise", "apothecary bazaar", "armor for sale", "artificer's tools", "barber shop", "blacksmith", "book store", "bowyer workshop", "butcher shop", "candle store", "candies!", "carpenter's workshop", "cartwright's workshop", "cheesemongers", "clothier",
        "cobblers", "curiosities", "drug store", "textile dyer workshop", "enchantment shop", "fishmonger", "fletcher", "fortune teller", "general store", "glass blower's workshop", "greengrocer", "hat store", "healer's shop", "herbalist", "incense store", "jewelers",
        "leather worker", "lock smiths", "magic shop", "mapmaker and cartographer", "mask store", "metal smith workshop", "oil merchant", "Oracle", "outfitters", "paper maker", "pawn shop", "perfumery",
        "pet shop", "pharmacist", "pottery", "rope maker", "rug shop", "sailwright", "Seer", "shipwright", "spice shop", "stone mason workshop", "tailor", "talismonger", "tanner", "tattoo parlor", "tea shop", "thatcher", "tinkerer's store",
        "travel office", "wainwright (cartwright)", "weaver", "ward maker", "weapon smith", "wig shop"


    ]

    return strings[GetRandomInt(0, strings.length - 1)];
}



function GetRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}