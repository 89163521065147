import gsap from "gsap";
import { getAllAvatars, GetRandomAvatar } from "../assets/avatars";
import { getRandomFullName } from "../assets/tables/randomnpc";
import { CloseBottomNav, CloseRightSideNav } from "../ui";
import { CreateRoom } from "./createRoom";
import showErrorMessage from "./errorMessage";
import { JoinRoom } from "./joinRoom";
import leaveRoom from "./leaveRoom";
import { getUserData, setUserData } from "./userData";
let avatar;
let username;
let playerID;
let avatarDivs = [];
export async function SetUpMultiplayerUI() {
  gsap.set(document.getElementById("multiplayerSelectWrapper"), {
    scale: 0,
  });
  gsap.set(document.getElementById("myCreateRoomWindow"), {
    scale: 0,
  });
  gsap.set(document.getElementById("myJoinRoomWindow"), {
    scale: 0,
  });
  gsap.set(document.getElementById("avatarWindow"), {
    scale: 0,
  });
  document.getElementsByClassName("multiplayer-room-info")[0].style.display =
    "none";

  avatar = GetRandomAvatar();

  document.getElementById("selectAvatarImage").src = avatar;

  document.getElementById("joinSelectAvatarImage").src = avatar;

  document.getElementById("createRoomUsernameInput").value =
    getRandomFullName();
  document.getElementById("joinRoomUsernameInput").value = getRandomFullName();

  switchMultiplayerButtonsOff();

  document.getElementById("multiplayerButton").onclick = function () {
    gsap.to(document.getElementById("multiplayerSelectWrapper"), {
      scale: 1,
    });
    CloseRightSideNav();
    CloseBottomNav();
    bounceButton(document.getElementById("multiplayerButton"));
    showErrorMessage(
      "Sorry, custom objects are not supported in multiplayer yet, but we are working on it!"
    );
  };

  document.getElementById("multiplayerSelectCloseButton").onclick =
    function () {
      gsap.to(document.getElementById("multiplayerSelectWrapper"), {
        scale: 0,
        duration: 0.2,
      });
    };

  document.getElementById("multiplayerCreateSelect").onclick = function () {
    gsap.to(document.getElementById("myCreateRoomWindow"), {
      scale: 1,
    });

    gsap.to(document.getElementById("multiplayerSelectWrapper"), {
      scale: 0,
      duration: 0.2,
    });
  };

  document.getElementById("multiplayerJoinSelect").onclick = function () {
    gsap.to(document.getElementById("myJoinRoomWindow"), {
      scale: 1,
    });

    gsap.to(document.getElementById("multiplayerSelectWrapper"), {
      scale: 0,
      duration: 0.2,
    });
  };

  document.getElementById("multiplayerCreateCloseButton").onclick =
    function () {
      gsap.to(document.getElementById("myCreateRoomWindow"), {
        scale: 0,
        duration: 0.2,
      });
    };

  document.getElementById("multiplayerJoinCloseButton").onclick = function () {
    gsap.to(document.getElementById("myJoinRoomWindow"), {
      scale: 0,
      duration: 0.2,
    });
  };

  document.getElementById("selectAvatarButton").onclick = function () {
    gsap.to(document.getElementById("avatarWindow"), {
      scale: 1,
      duration: 0,
    });

    populateAvatarList(getAllAvatars());
  };

  document.getElementById("joinSelectAvatarButton").onclick = function () {
    gsap.to(document.getElementById("avatarWindow"), {
      scale: 1,
    });

    populateAvatarList(getAllAvatars());
  };

  document.getElementById("multiplayerCreateConfirmButton").onclick =
    async function () {
      switchMultiplayerButtonsOn();
      let r = await CreateRoom(
        document.getElementById("createRoomUsernameInput").value,
        avatar
      );
      gsap.to(document.getElementById("myCreateRoomWindow"), {
        scale: 0,
        duration: 0.2,
      });
    };
  let leaveRoomButton = document.getElementById("leaveRoomButton");
  leaveRoomButton.onclick = function () {
    switchMultiplayerButtonsOff();
    leaveRoom();
  };

  document.getElementById("multiplayerJoinConfirmButton").onclick =
    async function () {
      JoinRoom(
        document.getElementById("joinRoomUsernameInput").value,
        document.getElementById("joinRoomIDInput").value,
        avatar
      );
    };
}

export const switchMultiplayerButtonsOn = () => {
  let roomInfoButton = document.getElementById("roomInfoButton");
  let multiplayerButton = document.getElementById("multiplayerButton");
  let leaveRoomButton = document.getElementById("leaveRoomButton");
  let customButton = document.getElementById("button_uploadModel");
  showButton(leaveRoomButton);
  hideButton(multiplayerButton);
  hideButton(customButton);
};

export const switchMultiplayerButtonsOff = () => {
  let roomInfoButton = document.getElementById("roomInfoButton");
  let multiplayerButton = document.getElementById("multiplayerButton");
  let leaveRoomButton = document.getElementById("leaveRoomButton");
  let customButton = document.getElementById("button_uploadModel");
  showButton(multiplayerButton);
  showButton(customButton);
  hideButton(leaveRoomButton);
};
export const handleJoinRoomUI = () => {};

const showButton = (button) => {
  button.style.display = "inline-block";
  button.style.position = "relative";
  button.style.display = "flex";
  button.style.justifyContent = "center";
  button.style.alignItems = "center";
};

const hideButton = (button) => {
  button.style.display = "none";
  button.style.position = "absolute";
};

export const bounceButton = (buttonToBounce) => {
  var tl = gsap.timeline();

  tl.to(buttonToBounce, {
    scale: 1.1,
    duration: 0.1,
    delay: 0,
  }).to(buttonToBounce, {
    scale: 1,
    duration: 0.1,
    delay: 0.1,
  });
};

let avatarsLoaded = false;
const populateAvatarList = (avatarList) => {
  if (!avatarsLoaded) {
    sortByKey(avatarList);
    for (let i = 0; i < avatarList.length; i++) {
      var original = document.getElementById("avatarWindowImage");

      var avatarDiv = original.cloneNode(true);
      avatarDiv.id = "avatarWindowImage" + i;
      avatarDiv.classList.add("avatarWindowImage");
      avatarDiv.style.backgroundImage = `url(${avatarList[i]})`;
      document.getElementById("avatarSelectGrid").appendChild(avatarDiv);
      avatarDiv.onclick = function () {
        setAvatar(avatarList[i]);
      };
    }
    original.style.display = "none";
    avatarsLoaded = true;
  }
};

function setAvatar(avatarPath) {
  document.getElementById("selectAvatarImage").src = avatarPath;
  document.getElementById("joinSelectAvatarImage").src = avatarPath;

  gsap.to(document.getElementById("avatarWindow"), {
    scale: 0,
    duration: 0,
  });
  avatar = avatarPath;
}

export const sortByKey = (array) => {
  return array.sort((a, b) => {
    var x = a;
    var y = b;

    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export function updateUserAvatarBar(users) {
  const avatarBar = document.getElementsByClassName("avatar-bar")[0];
  const itemTemplate = document.getElementsByClassName("avatar-item")[0];

  for (let i = 0; i < avatarDivs.length; i++) {
    avatarDivs[i].remove();
  }

  if (users) {
    for (let i = 0; i < users.length; i++) {
      const clone = itemTemplate.cloneNode(true);
      itemTemplate.parentNode.appendChild(clone);
      clone.style.display = "block";
      clone.style.backgroundImage = `url(${users[i].avatarPath})`;
      clone.style.borderColor = "#" + users[i].color;
      clone.classList.add(".multiplayer-user-item:hover");
      clone.firstChild.innerHTML = users[i].username;
      avatarDivs.push(clone);
    }
  }
}
