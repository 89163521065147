import * as THREE from "three";
import { ref, set } from "firebase/database";
import * as SELECTIONMANAGER from "./managers/selectionmanager";
import { Object3D, PointLight } from "three";
import * as APP from "../script";
import { AddLightToGame } from "./gamestate";
import { getDB } from "./multiplayer/firebaseConfig";
import { getGameID } from "./multiplayer/roomData";
import { InAMultiplayerSession } from "./multiplayer/status";
export let sun;

export function SetUpLights() {
  const light = new THREE.AmbientLight(0xffffff, 0.3); // soft white light
  APP.scene.add(light);

  const point = new THREE.PointLight(0xffffff, 0); // soft white light
  // APP.scene.add(point);
  point.position.set(0, 3, 0);

  sun = new THREE.DirectionalLight("#ffffff", 4);
  sun.position.set(12, 25, 12);
  sun.castShadow = true;
  sun.shadow.camera.near = 1;
  sun.shadow.camera.far = 250;
  sun.shadow.camera.top = 50;
  sun.shadow.camera.right = 50;
  sun.shadow.camera.bottom = -50;
  sun.shadow.camera.left = -50;
  sun.shadow.radius = 1;
  sun.shadow.mapSize.width = 1024;
  sun.shadow.mapSize.height = 1024;
  sun.shadow.bias = -0.0005;
  APP.scene.add(sun);

  const firstLightTarget = new THREE.Object3D();
  firstLightTarget.position.set(0, 0, 0);
  APP.scene.add(firstLightTarget);
  sun.target = firstLightTarget;

  //   const helper = new THREE.CameraHelper(sun.shadow.camera);
  //   APP.scene.add(helper);
  //   const lighthelper = new THREE.DirectionalLightHelper(sun, 5);
  //   APP.scene.add(lighthelper);

  // const geometry = new THREE.BoxGeometry(1, 1, 1);
  // const material = new THREE.MeshStandardMaterial({
  //   color: 0x00ff00,
  // });
  // const cube = new THREE.Mesh(geometry, material);
  // cube.position.set(0.5, 0, 0.5);
  // scene.add(cube);
}

export function CreateLight(colorToCreate, loc, id) {
  let parent = new THREE.Object3D();
  const col = new THREE.Color(colorToCreate);
  parent.name = "light_entity_" + colorToCreate.toString();
  let light = new THREE.PointLight(col, 10);
  parent.add(light);

  parent.position.set(-1000, -1000, -1000);
  if (!id) {
    parent.userData.id = (Math.random() + 1).toString(36).substring(2);
  } else {
    parent.userData.id = id;
  }
  parent.userData.cardTitle = "none";
  parent.userData.cardBody = "none";
  parent.userData.cardImage = "none";
  parent.userData.cardColor = "none";
  parent.userData.cardType = "none";
  light.position.set(0, 3, 0);
  light.castShadow = true;
  light.shadow.mapSize.width = 512; // default
  light.shadow.mapSize.height = 512; // default
  light.shadow.camera.near = 0.5; // default
  light.shadow.camera.far = 50; // default
  AddLightToGame(parent);

  APP.scene.add(parent);

  if (!loc) {
    SELECTIONMANAGER.MoveSelectedObject(parent, false, false);
    SELECTIONMANAGER.CreatingNewObject();
  } else {
    parent.position.set(loc.x, loc.y, loc.z);
  }
}
let sunIsOn = true;
export function ToggleSun() {
  if (sunIsOn) {
    sun.intensity = 0;
    sunIsOn = false;
  } else {
    sun.intensity = 4;
    sunIsOn = true;
  }

  if (InAMultiplayerSession()) {
    set(ref(getDB(), getGameID() + "/game/sun"), sunIsOn);
  }
}

function toHex(str) {
  var result = "";
  for (var i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
}

export function getSun() {
  return sunIsOn;
}

export function setSun(val) {
  if (val) {
    sun.intensity = 4;
    sunIsOn = true;
  } else {
    sun.intensity = 0;
    sunIsOn = false;
  }
}
