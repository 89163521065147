import * as THREE from "three";
import * as APP from "../../script";
import * as CONTROLS from "../controls";
import * as GAMESTATE from "../gamestate";
import * as ASSETLOADER from "../assetLoader";
import gsap from "gsap";
export let selectedObject;
export let selectedObjectChildren = [];
export let allUnselectedObjects = [];
export let isObjectSelected, isMovingSelectedObject;
export let selector;
export let snapToGrid = false;
export let currentrotation = 0;
export let brandNewObject = false;
import { InAMultiplayerSession } from "../multiplayer/status";
import { getGameID } from "../multiplayer/roomData";
import { getDB } from "../multiplayer/firebaseConfig";
import { ref, set, serverTimestamp } from "firebase/database";
import { getUserData } from "../multiplayer/userData";

let multiplayerSelectors = [];

export function CreatingNewObject() {
  // showHint(
  //
  // );
  brandNewObject = true;
}
export function DismissNewObject() {
  brandNewObject = false;
}
let currentModelType;
export function EnableSnapToGrid() {
  snapToGrid = true;
  var image = document.getElementById("snapToGridIcon");
  image.src = "./textures/ui/icon_snap_active.webp";
}
export function DisableSnapToGrid() {
  snapToGrid = false;
  var image = document.getElementById("snapToGridIcon");
  image.src = "./textures/ui/icon_snap.webp";
}
export function AddSelectedObject(object) {
  selectedObjectChildren = [];
  allUnselectedObjects = [];
  selectedObject = object;

  let size = 1;
  object.traverse(function (child) {
    selectedObjectChildren.push(child);
    if (child.name.includes("Circle0")) {
      size = child.scale.x;
    }
  });

  for (let i = 0; i < APP.scene.children.length; i++) {
    if (!selectedObjectChildren.includes(APP.scene.children[i])) {
      allUnselectedObjects.push(APP.scene.children[i]);
    }
  }

  InstantiateSelector(size * 2);
}

function InstantiateSelector(_size) {
  let col = "27ecf1";
  if (getUserData().color != null) {
    col = getUserData().color;
  }

  selector = APP.scene.getObjectByName("selector").clone();
  APP.scene.add(selector);
  selector.userData.id = (Math.random() + 1).toString(36).substring(2);
  selector.userData.name = "selector";
  selector.userData.cardTitle = "none";
  selector.userData.cardBody = "none";
  selector.userData.cardImage = "none";
  selector.userData.cardColor = "none";
  selector.userData.cardType = "none";
  selector.position.x = selectedObject.position.x;
  selector.position.y = selectedObject.position.y + 0.01;
  selector.position.z = selectedObject.position.z;
  selector.visible = true;
  selector.userData.color = col;
  let size = new THREE.Vector3(1, 1, 1);
  if (_size != null) {
    size = new THREE.Vector3(_size, _size, _size);
  }

  if (InAMultiplayerSession()) {
    selector.scale.set(size.x, size.y, size.z);
  } else {
    selector.scale.set(0, 0, 0);
    var tl = new gsap.timeline();

    tl.to(selector.scale, {
      x: size.x,
      y: size.y,
      z: size.z,
      duration: 0.2,
    });
  }

  addToMultiplayerSelectors(selector);

  selector.traverse(function (child) {
    selectedObjectChildren.push(child);
    if (child.isMesh) {
      if (InAMultiplayerSession()) {
        child.material.color = new THREE.Color("#" + col);
      }
    }
  });

  if (InAMultiplayerSession()) {
    set(ref(getDB(), getGameID() + "/game/selectors/" + selector.userData.id), {
      id: selector.userData.id,
      name: selector.userData.name,
      posX: selector.position.x,
      posY: selector.position.y,
      posZ: selector.position.z,
      scaleX: selector.scale.x,
      color: col,
    });
  }
}
export function DeselectObject() {
  selectedObject = null;
  isObjectSelected = false;
  isMovingSelectedObject = false;

  APP.scene.remove(selector);
  removeMultiplayerSelector(selector);
  if (InAMultiplayerSession() && selector) {
    set(
      ref(getDB(), getGameID() + "/game/selectors/" + selector.userData.id),

      null
    );
  }
}
export function MoveSelectedObject(object, modelType) {
  selectedObject = null;

  selectedObjectChildren = [];
  allUnselectedObjects = [];
  selectedObject = object;
  isMovingSelectedObject = true;

  if (modelType == null) {
    modelType = ASSETLOADER.GetModelType(object.name);
  }
  currentModelType = modelType;

  if (modelType == "hero" || modelType == "figurine") {
    AddSelectedObject(object);
  }

  APP.MakeObjectFollowMouse(selectedObject);

  var tl = new gsap.timeline();

  tl.to(object.position, {
    y: object.position.y + 1,

    duration: 0.2,
  });

  object.traverse(function (child) {
    selectedObjectChildren.push(child);
  });

  for (let i = 0; i < APP.scene.children.length; i++) {
    if (
      !selectedObjectChildren.includes(APP.scene.children[i]) &&
      !APP.scene.children[i].name.includes("selector") &&
      !APP.scene.children[i].name.includes("magic") &&
      !APP.scene.children[i].name.includes("grid")
    ) {
      allUnselectedObjects.push(APP.scene.children[i]);
    }
  }
}

export function PutdownMovingObject(loc) {
  if (selectedObject != null) {
    brandNewObject = false;

    let selected = selectedObject;
    isMovingSelectedObject = false;
    var tl = new gsap.timeline();

    tl.to(selected.position, {
      y: loc.y + GetRandomFloat(0, 0.01),
      x: Math.round(loc.x * 100) / 100,
      z: Math.round(loc.z * 100) / 100,
      duration: 0.2,
    });

    if (InAMultiplayerSession()) {
      let model = selectedObject;
      set(ref(getDB(), getGameID() + "/game/map/" + model.userData.id), {
        id: model.userData.id,
        name: model.name,
        posX: Math.round(loc.x * 100) / 100,
        posY: loc.y + GetRandomFloat(0, 0.01),
        posZ: Math.round(loc.z * 100) / 100,
        rotY: Math.round(model.rotation.y * 100) / 100,
        scaleY: Math.round(model.scale.y * 100) / 100,
        cardTitle: model.userData.cardTitle,
        cardBody: model.userData.cardBody,
        cardImage: model.userData.cardImage,
        cardColor: model.userData.cardColor,
        cardType: model.userData.cardType,
      });

      const gameRef = ref(getDB(), getGameID() + "/timestamp");
      const updateTimestamp = set(gameRef, serverTimestamp());
    }

    if (CONTROLS.CheckIfShiftKeyDown()) {
      let newClone = selected.clone();
      brandNewObject = true;
      setTimeout(() => {
        APP.scene.add(newClone);
        MoveSelectedObject(newClone, currentModelType);
        PutCloneIntoGamestate(newClone);
      }, 200);
    }

    setTimeout(() => {
      if (currentModelType == "naturetiles")
        selected.children[0].castShadow = false;
    }, 200);

    currentrotation = selectedObject.rotation.y;
  }
}

function PutCloneIntoGamestate(model) {
  if (currentModelType == "figurine" || currentModelType == "hero") {
    GAMESTATE.AddCharacterToGame(model);
  }
  if (currentModelType == "plantTrees") {
    GAMESTATE.AddTreeToGame(model);
  }

  if (currentModelType == "naturetiles") {
    GAMESTATE.AddNatureTilesToGame(model);
  }

  if (currentModelType == "light_entity") {
    GAMESTATE.AddLightToGame(model);
  }
  if (currentModelType == "magic") {
    GAMESTATE.AddMagicToGame(model);
  }
}

function randomizeValue() {
  var value = (1 + 10e-16) * Math.random();

  if (value > 1.0) {
    return 1.0;
  }

  return value;
}

function GetRandomFloat(min, max) {
  if (max == null) {
    max = min == null ? Number.MAX_VALUE : min;
    min = 0.0;
  }

  if (min >= max) {
    throw new Error("Incorrect arguments.");
  }

  return min + (max - min) * randomizeValue();
}

export function addToMultiplayerSelectors(selector) {
  multiplayerSelectors.push(selector);
}

export function getMultiplayerSelectors() {
  return multiplayerSelectors;
}

export function removeMultiplayerSelector(selector) {
  let index = multiplayerSelectors.indexOf(selector);
  if (index > -1) {
    multiplayerSelectors.splice(index, 1);
  }
}

export function destroyAllMultiplayerSelectors() {
  for (let i = 0; i < multiplayerSelectors.length; i++) {
    APP.scene.remove(multiplayerSelectors[i]);
  }
  multiplayerSelectors = [];
}
