import * as APP from "../script";
import * as SELECTIONMANAGER from "../app/managers/selectionmanager";
import * as THREE from "three";
import * as GAMESTATE from "./gamestate";
import { InAMultiplayerSession } from "./multiplayer/status";
import { getDB } from "./multiplayer/firebaseConfig";
import { getGameID } from "./multiplayer/roomData";
import { set, ref } from "firebase/database";
export let eraserActive = false;

export function ActivateEraser() {
  eraserActive = true;

  SELECTIONMANAGER.DeselectObject();
  var image = document.getElementById("eraser_icon");
  image.src = "./textures/ui/icon_eraser_active.webp";
}

export function DeactivateEraser() {
  eraserActive = false;

  var image = document.getElementById("eraser_icon");
  image.src = "./textures/ui/icon_eraser.webp";
}

export function DeleteObject(object) {
  let doNotDelete = [];
  doNotDelete.push(APP.scene.getObjectByName("sceneTable"));
  doNotDelete.push(APP.scene.getObjectByName("grid"));

  if (!doNotDelete.includes(object)) {
    if (!object.name.includes("magic") || !object.name.includes("cloud")) {
      object.traverse(function (obj) {
        if (obj instanceof THREE.Mesh) {
          obj.geometry.dispose();
          obj.material.dispose();
          APP.scene.remove(obj);
          GAMESTATE.RemoveObjectFromGame(object);
        }
      });
    }

    APP.scene.remove(object);
    GAMESTATE.RemoveObjectFromGame(object);
  }
  if (object.name == "storycard") {
    object.visible = false;
    object.position.set(-1000, -1000, -1000);

    if (InAMultiplayerSession()) {
      let db = getDB();
      let gameID = getGameID();
      set(
        ref(getDB(), gameID + "/game/map/" + object.parent.userData.id),
        null
      );
    }
  }
}
