export function GetModel(modelID) {
  let assetPaths = [
    "./models/topdowncity/market_a.glb",
    "./models/topdowncity/market_b.glb",
    "./models/topdowncity/shed_a.glb",
    "./models/topdowncity/well_a.glb",
    "./models/topdowncity/bridge_a.glb",
    "./models/topdowncity/cart_a.glb",
    "./models/topdowncity/cargo_a.glb",
    "./models/topdowncity/cargo_b.glb",
    "./models/ships/cogship_b.glb",
    "./models/trees/old_tree_rock.glb",
  ];

  for (let i = 0; i < assetPaths.length; i++) {
    if (assetPaths[i].includes(modelID)) {
      return assetPaths[i];
    }
  }
}
