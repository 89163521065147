import "./style.css";
import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module";
import * as LOADER from "./app/assetLoader";
import * as UI from "./app/ui";
import * as TOOLTIPS from "./app/tooltips";
import * as DRAG from "./utils/dragwindow";
import * as CONTROLS from "./app/controls";
import * as DICE from "./app/dice";
import * as LIGHTS from "./app/lights";
import * as HINTS from "./app/hints";
import * as GAMESTATE from "./app/gamestate";
import * as ERASER from "./app/eraser";
import * as SETTINGS from "./app/settings";
import * as TREES from "./app/treepainter";
import * as SELECTIONMANAGER from "./app/managers/selectionmanager";
import * as LOADINGMANAGERS from "./app/managers/loadingmanagers";
import * as SCENETABLES from "./app/assets/scenetables";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import RendererStats from "@xailabs/three-renderer-stats";
import uploadFile from "./app/dropzone";
import { InitializeUI } from "./app/ui";
import { SetUpMultiplayerUI } from "./app/multiplayer/uiMultiplayer";
import gsap from "gsap";
import { ref, set } from "firebase/database";
import { getDB } from "./app/multiplayer/firebaseConfig";
import { getGameID } from "./app/multiplayer/roomData";
import { LoadMap } from "./app/premades/premades";
import {
  InAMultiplayerSession,
  setAllowUpdateDatabase,
} from "./app/multiplayer/status";
import { setUpDonateUI } from "./app/donate";
import {
  deactivateMeasuringStick,
  endMeasuring,
  getMeasuringStarted,
  getMeasuringStickActive,
  resumeMeasuring,
  startMeasuring,
} from "./app/measuringStick";

let playIntroAnimation = false;
// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
export const scene = new THREE.Scene();
scene.name = "MainScene";
let sceneColor = new THREE.Color(0x000000);
let fogNear = 201;
let fogFar = 400;
scene.fog = new THREE.Fog(sceneColor, fogNear, fogFar);
LIGHTS.SetUpLights();
/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.preserveDrawingBuffer = true;
  composer.setSize(sizes.width, sizes.height);

  effectFXAA.uniforms["resolution"].value.set(
    1 / window.innerWidth,
    1 / window.innerHeight
  );
});

/**
 * Camera
 */
// Base camera
export const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.1,
  100
);

camera.name = "camera";
camera.far = 5000;
scene.add(camera);
camera.updateProjectionMatrix();
// Controls
const controls = new OrbitControls(camera, canvas);

const setupCamera = () => {
  controls.enableDamping = true;
  controls.minDistance = 0;
  controls.maxDistance = 160;
};
/**
 * Renderer
 */
export const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
// THREE.BasicShadowMap;
// THREE.PCFShadowMap
// THREE.PCFSoftShadowMap
// THREE.VSMShadowMap;
renderer.physicallyCorrectLights = true;
renderer.toneMappingExposure = 1;
//renderer.toneMapping = THREE.ACESFilmicToneMapping;

let composer, effectFXAA, outlinePass;
let selectedObjects = [];
composer = new EffectComposer(renderer);

const renderPass = new RenderPass(scene, camera);
composer.addPass(renderPass);

outlinePass = new OutlinePass(
  new THREE.Vector2(window.innerWidth, window.innerHeight),
  scene,
  camera
);
composer.addPass(outlinePass);

outlinePass.edgeStrength = 5;
outlinePass.edgeGlow = 0;
outlinePass.edgeThickness = 0.5;
outlinePass.pulsePeriod = 0;
outlinePass.rotate = false;
outlinePass.usePatternTexture = false;
outlinePass.hiddenEdgeColor = new THREE.Color(0xffffff);

effectFXAA = new ShaderPass(FXAAShader);
effectFXAA.uniforms["resolution"].value.set(
  1 / window.innerWidth,
  1 / window.innerHeight
);
composer.addPass(effectFXAA);

export function MakeObjectFollowMouse(object) {
  objectToFollowMousePoint = object;
}

export let objectToFollowMousePoint;
let multiplayerInterval = 0;

function ObjectToMousePoint(locVector) {
  if (objectToFollowMousePoint != null) {
    let yPos = 0.001;

    if (SELECTIONMANAGER.snapToGrid) {
      locVector.x = Math.round(locVector.x);
      locVector.y = 0.01;
      locVector.z = Math.round(locVector.z);
    }

    if (SELECTIONMANAGER.isMovingSelectedObject) yPos = 1.001;
    objectToFollowMousePoint.position.x = locVector.x;

    objectToFollowMousePoint.position.y = locVector.y + yPos;

    objectToFollowMousePoint.position.z = locVector.z;

    if (SELECTIONMANAGER.selector) {
      SELECTIONMANAGER.selector.position.set(
        locVector.x,
        locVector.y + 0.01,
        locVector.z
      );
    }
  }

  if (InAMultiplayerSession()) {
    if (objectToFollowMousePoint) {
      multiplayerInterval++;

      let model = objectToFollowMousePoint;
      const id = model.userData.id;

      if (multiplayerInterval > 2) {
        if (id) {
          set(ref(getDB(), getGameID() + "/game/map/" + id), {
            id: model.userData.id,
            name: model.name,
            posX: Math.round(model.position.x * 100) / 100,
            posY: Math.round(model.position.y * 100) / 100,
            posZ: Math.round(model.position.z * 100) / 100,
            rotY: Math.round(model.rotation.y * 100) / 100,
            scaleY: Math.round(model.scale.y * 100) / 100,
            cardTitle: model.userData.cardTitle,
            cardBody: model.userData.cardBody,
            cardImage: model.userData.cardImage,
            cardColor: model.userData.cardColor,
            cardType: model.userData.cardType,
          });
        }

        if (SELECTIONMANAGER.selector) {
          const selector = SELECTIONMANAGER.selector;
          set(
            ref(
              getDB(),
              getGameID() + "/game/selectors/" + selector.userData.id
            ),
            {
              id: selector.userData.id,
              name: selector.userData.name,
              posX: selector.position.x,
              posY: selector.position.y,
              posZ: selector.position.z,
              scaleX: selector.scale.x,
              color: selector.userData.color,
            }
          );
        }

        multiplayerInterval = 0;
      }
    }
  }
}

const tick = () => {
  controls.update();

  renderer.render(scene, camera);

  window.requestAnimationFrame(tick);
};

tick();

var raycaster = new THREE.Raycaster();
var mousePos = new THREE.Vector2();

function onMouseMoving(event) {
  event.preventDefault();
  mousePos.x = (event.clientX / canvas.clientWidth) * 2 - 1;
  mousePos.y = -(event.clientY / canvas.clientHeight) * 2 + 1;

  raycaster.setFromCamera(mousePos, camera);
  if (!TREES.paintTreesEnabled && !getMeasuringStickActive()) {
    var intersectOmitSelected = raycaster.intersectObjects(
      SELECTIONMANAGER.allUnselectedObjects
    );

    if (intersectOmitSelected.length > 0) {
      ObjectToMousePoint(intersectOmitSelected[0].point);
    }
  } else if (!TREES.paintTreesEnabled && getMeasuringStickActive()) {
    let arr = scene.children;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name == "measuringObject" || arr[i].name == "measuringLine") {
        arr.splice(i, 1);
      }
    }
    let intersect = raycaster.intersectObjects(arr);
    if (getMeasuringStarted()) {
      resumeMeasuring(intersect[0].point);
    }
  } else {
    var intersectTrees = raycaster.intersectObjects(
      GAMESTATE.GetTreeRaycastMask()
    );
    TREES.PaintTrees(intersectTrees[0].point);
  }
}

window.addEventListener("mousemove", onMouseMoving, false);

document.onpointerdown = function (event) {
  controls.autoRotate = false;
  currentlyAutoRoate = false;

  mousePos.x = (event.clientX / canvas.clientWidth) * 2 - 1;
  mousePos.y = -(event.clientY / canvas.clientHeight) * 2 + 1;

  raycaster.setFromCamera(mousePos, camera);

  var intersect = raycaster.intersectObjects(scene.children);

  if (!ERASER.eraserActive) {
    for (let i = 0; i < intersect.length; i++) {
      if (intersect[i].object.name.includes("magic")) {
        var index = intersect.indexOf(intersect[i]);
        intersect.splice(index, 1);
      }
    }
  }

  if (event.button == 2) {
    if (SELECTIONMANAGER.brandNewObject) {
      ERASER.DeleteObject(SELECTIONMANAGER.selectedObject);
      SELECTIONMANAGER.DeselectObject(SELECTIONMANAGER.selectedObject);
      SELECTIONMANAGER.DismissNewObject();
    }
  }

  var intersectOmitSelected = raycaster.intersectObjects(
    SELECTIONMANAGER.allUnselectedObjects
  );

  if (intersectOmitSelected.length > 0) {
    if (objectToFollowMousePoint != null) {
      objectToFollowMousePoint = null;
      SELECTIONMANAGER.PutdownMovingObject(intersectOmitSelected[0].point);
      UI.SetRightMenuActive();
    } else {
      SELECTIONMANAGER.DeselectObject();
    }
  }

  if (intersect.length > 0) {
    if (event.button == 0) {
      if (!TREES.paintTreesTriggered) {
        if (DICE.diceRolledEnabled) {
          DICE.RollDice(intersect[0].point);
        }

        if (!ERASER.eraserActive && !getMeasuringStickActive()) {
          intersect[0].object.traverseAncestors(function (parent) {
            if (
              GAMESTATE.activeCharacters.includes(parent) &&
              SELECTIONMANAGER.selectedObject != parent
            ) {
              SELECTIONMANAGER.AddSelectedObject(parent);
            }

            if (SELECTIONMANAGER.allUnselectedObjects.includes(parent)) {
              if (!SELECTIONMANAGER.isMovingSelectedObject)
                SELECTIONMANAGER.DeselectObject();
            }
          });
        } else if (!ERASER.eraserActive && getMeasuringStickActive()) {
          startMeasuring(intersect[0].point);
        } else {
          ///// ERASER

          let par = intersect[0].object.parent;

          while (par.type !== "Scene" && par.type !== "Group") {
            par = par.parent;
          }

          ERASER.DeleteObject(par);
        }
      } ////////////Tree painting
      else {
        TREES.EnablePainting();
      }
    } else if (event.button == 2) {
      // controls.target = intersect[0].point
      intersect[0].object.traverseAncestors(function (parent) {
        if (GAMESTATE.activeCharacters.includes(parent)) {
          SELECTIONMANAGER.MoveSelectedObject(parent, true);
        }
        if (GAMESTATE.activeCards.includes(parent)) {
          SELECTIONMANAGER.MoveSelectedObject(parent, false);
        }

        if (GAMESTATE.activeMagic.includes(parent)) {
          SELECTIONMANAGER.MoveSelectedObject(parent, false);
        }
      });

      if (ERASER.eraserActive) ERASER.DeactivateEraser();
      if (getMeasuringStickActive()) deactivateMeasuringStick();
      DICE.DisableDiceRoller();
    }
  }
};

document.onpointerup = function (event) {
  TREES.DisablePainting();
};

export function DisableControls() {
  controls.enableRotate = false;
}

export function EnableControls() {
  controls.enableRotate = true;
}

export function FocusTarget() {
  if (SELECTIONMANAGER.selectedObject != null) {
    let pos = SELECTIONMANAGER.selectedObject.position;
    controls.target.set(pos.x, pos.y, pos.z);
  }
}
export function GetCameraTargetPosition() {
  return controls.target;
}

let currentlyAutoRoate = false;
export function AutoRotateCamera() {
  if (!currentlyAutoRoate) {
    controls.autoRotate = true;
    currentlyAutoRoate = true;
  } else {
    controls.autoRotate = false;
    currentlyAutoRoate = false;
  }
}

uploadFile();

const startExperience = async () => {
  document.body.style.visibility = "visible";
  setUpDonateUI();
  if (playIntroAnimation) {
    gsap.set(camera.position, { x: 0, y: 550, z: 0 });
    gsap.set(camera.rotation, { z: "90" });

    const tl = gsap.timeline();
    tl.to(camera.position, {
      x: 76.0421493984077,
      y: 100,
      z: 90.10991170800827,
      duration: 12,
      delay: 2,
      onComplete: () => {
        setupCamera();
        InitializeUI();
      },
    });
  } else {
    InitializeUI();
    camera.position.set(76.0421493984077, 100, 90.10991170800827);
    setupCamera();
  }
  let m = await SetUpMultiplayerUI();
};
startExperience();
setTimeout(() => {
  fadeOutLoader();
}, 2000);

const fadeOutLoader = () => {
  const bg = document.getElementById("myLoadingScreen");

  gsap.to(bg, { opacity: 0, duration: 0.5, delay: 2 });

  bg.style.pointerEvents = "none";

  HINTS.showHint(5);
};

// window.addEventListener(
//   "mouseup",
//   (event) => {
//     setAllowUpdateDatabase(true);
//   },
//   false
// );

const qAdClose = document.getElementById("questforge-ad-close");
const qfAd = document.getElementById("questforge-ad");

qAdClose.addEventListener("click", () => {
  qfAd.style.display = "none";
});

const qAdButton = document.getElementById("questforge-ad-button");

qAdButton.addEventListener("click", () => {
  window.open("https://questforge.app", "_blank");
});
