export function GetQuestWords() {
    let actions = ["Clash",
        "Weaken",
        "Initiate ",
        "Create",
        "Swear",
        "Avenge",
        "Guard",
        "Defeat",
        "Control",
        "Break",
        "Risk",
        "Surrender",
        "Inspect",
        "Raid",
        "Evade",
        "Assault",
        "Deflect",
        "Threaten",
        "Attack",
        "Leave",
        "Preserve",
        "Manipulate",
        "Remove",
        "Eliminate",
        "Withdraw",
        "Abandon",
        "Investigate",
        "Hold",
        "Focus ",
        "Uncover ",
        "Breach ",
        "Aid ",
        "Uphold ",
        "Falter ",
        "Suppress ",
        "Hunt ",
        "Share ",
        "Destroy ",
        "Avoid ",
        "Reject ",
        "Demand ",
        "Explore ",
        "Bolster ",
        "Seize ",
        "Mourn ",
        "Reveal ",
        "Gather ",
        "Defy ",
        "Transform ",
        "Persevere ",
        "Serve ",
        "Begin ",
        "Move ",
        "Coordinate ",
        "Resist ",
        "Await ",
        "Impress ",
        "Take ",
        "Oppose ",
        "Capture ",
        "Overwhelm ",
        "Challenge ",
        "Acquire ",
        "Protect ",
        "Finish ",
        "Strengthen ",
        "Restore ",
        "Advance ",
        "Command ",
        "Refuse ",
        "Find ",
        "Deliver",
        "Hide",
        "Fortify",
        "Betray",
        "Secure",
        "Arrive",
        "Affect",
        "Change",
        "Defend",
        "Debate",
        "Support",
        "Follow",
        "Construct",
        "Locate",
        "Endure",
        "Release",
        "Lose",
        "Reduce",
        "Escalate",
        "Distract",
        "Journey",
        "Escort",
        "Learn",
        "Communicate",
        "Depart",
        "Search",
        "Charge",
        "Summon",
    ]

    let themes = ["Risk",
        "Ability",
        "Price",
        "Ally",
        "Battle",
        "Safety",
        "Survival",
        "Weapon",
        "Wound",
        "Shelter",
        "Leader",
        "Fear",
        "Time",
        "Duty",
        "Secret",
        "Innocence",
        "Renown",
        "Direction",
        "Death",
        "Honor",
        "Labor",
        "Solution",
        "Tool",
        "Balance",
        "Love",
        "Barrier",
        "Creation",
        "Decay",
        "Trade",
        "Bond",
        "Hope",
        "Superstition",
        "Peace",
        "Deception",
        "History",
        "World",
        "Vow",
        "Protection",
        "Nature",
        "Opinion",
        "Burden",
        "Vengeance",
        "Opportunity",
        "Faction",
        "Danger",
        "Corruption",
        "Freedom",
        "Debt",
        "Hate",
        "Possession",
        "Stranger",
        "Passage",
        "Land",
        "Creature",
        "Disease",
        "Advantage",
        "Blood",
        "Language",
        "Rumor",
        "Weakness",
        "Greed",
        "Family",
        "Resource",
        "Structure",
        "Dream",
        "Community",
        "War",
        "Portent",
        "Prize",
        "Destiny",
        "Momentum",
        "Power",
        "Memory",
        "Ruin",
        "Mysticism",
        "Rival",
        "Problem",
        "Idea",
        "Revenge",
        "Health",
        "Fellowship",
        "Enemy",
        "Religion",
        "Spirit",
        "Fame",
        "Desolation",
        "Strength",
        "Knowledge",
        "Truth",
        "Quest",
        "Pride",
        "Loss",
        "Law",
        "Path",
        "Warning",
        "Relationship",
        "Wealth",
        "Home",
        "Strategy",
        "Supply"
    ]

    return "Quest words give you a starting theme for quests, and you may interpret them as you wish. Your words are..." + "\n\n" + actions[GetRandomInt(0, actions.length - 1)] + "\n\n&\n\n" + themes[GetRandomInt(0, themes.length - 1)]
}

function GetRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}