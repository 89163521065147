import * as ANiMATIONS from "./animations";
import * as SCENETABLES from "./assets/scenetables";
import * as PREMADES from "./premades/premades";
import * as APP from "../script";
import * as CHARACTERS from "./assets/characters";
import * as SWITCHSCENES from "./sceneswitcher";
import { saveMap } from "./campaign";
import { loadCloud, removeClouds } from "./effects/cloud";
import { loadray, removeRays } from "./effects/godrays";
import { Raycaster } from "three";
import { createGeometryFromMap } from "./heightmap";
import { GetAllActiveObjects } from "./gamestate";
let shiftKeyPressed;
let tableVisible = true;
document.addEventListener(
  "keyup",
  (event) => {
    const keyName = event.key;
    if (keyName === "Shift") {
      shiftKeyPressed = false;
    }
  },
  false
);

export function CheckIfShiftKeyDown() {
  return shiftKeyPressed;
}

document.addEventListener(
  "keydown",
  (event) => {
    const keyName = event.key;
    if (keyName === "Shift") {
      shiftKeyPressed = true;
    }
    if (keyName === "q") {
      ANiMATIONS.RotateHeldObject(false);
    }
    if (keyName === "e") {
      ANiMATIONS.RotateHeldObject(true);
    }
    if (keyName === "o") {
      APP.AutoRotateCamera();
    }
    if (keyName === "p") {
      PREMADES.PrintCurrentMap();
    }
    if (keyName === "l") {
      //  PREMADES.LoadMap(1);
    }
    if (keyName === "f") {
      APP.FocusTarget();
    }
    if (keyName === "=") {
      ANiMATIONS.ScaleHeldObject(true);
    }
    if (keyName === "-") {
      ANiMATIONS.ScaleHeldObject(false);
    }
    if (keyName === "ArrowRight") {
      //   SWITCHSCENES.SwitchScenes("right");
    }
    if (keyName === "ArrowLeft") {
      //  SWITCHSCENES.SwitchScenes("left");
    }
    if (keyName === "o") {
    }
    if (keyName === "u") {
    }
    if (keyName === "c") {
      if (!cloudActive) {
        loadCloud();
        cloudActive = true;
      } else {
        removeClouds();
        cloudActive = false;
      }
    }
    if (keyName === "h") {
      createGeometryFromMap();
    }
    if (keyName === "r") {
      if (!rayActive) {
        loadray();
        rayActive = true;
      } else {
        removeRays();
        rayActive = false;
      }
    }
    if (keyName === "s") {
      saveMap();
    }
    if (keyName === "t") {
      const table = SCENETABLES.GetCurrentTable();
      if (tableVisible) {
        table.visible = false;
        tableVisible = false;
      } else {
        table.visible = true;
        tableVisible = true;
      }
    }
  },
  false
);

let cloudActive = false;
let rayActive = false;
