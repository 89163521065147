import { GetAllActiveObjects } from "./gamestate";
import { GetCurrentTable, GetCurrentPlayMat } from "./assets/scenetables";
import { SwitchScenes } from "./sceneswitcher";
import { GetNatureLandscape, GetTownLandscape } from "./assets/landscapes";
import { GetRandomRegionName } from "./assets/tables/regions";
let mapLimit = 3;
let mapIndex = 0;
let campaignName = "";
let currentMapName = GetRandomRegionName();
let currentMapThumb = GetNatureLandscape();
let maps = [];
let campaignData = [];

export const loadPreviousMap = async (dir) => {
  currentMapName = campaignData[mapIndex].mapName;
  currentMapThumb = campaignData[mapIndex].mapThumb;

  SwitchScenes(campaignData[mapIndex], dir, maps[mapIndex]);
};

export const newMap = () => {
  let currentMap = maps[mapIndex];
  mapIndex++;

  const newMap = {
    mapName: GetRandomRegionName(),
    tableType: GetCurrentTable().userData.tableType,
    feltType: GetCurrentPlayMat().material.map.name,
    mapThumb: GetNatureLandscape(),
  };
  currentMapName = newMap.mapName;
  currentMapThumb = newMap.mapThumb;
  SwitchScenes(newMap, "right", null, currentMap);
};

export const saveMap = (fromScript) => {
  let map = [];

  const allObjects = GetAllActiveObjects();
  for (let i = 0; i < allObjects.length; i++) {
    const newObj = {
      id: allObjects[i].name,
      x: Math.round(allObjects[i].position.x * 100) / 100,
      y: Math.round(allObjects[i].position.y * 100) / 100,
      z: Math.round(allObjects[i].position.z * 100) / 100,
      rot: Math.round(allObjects[i].rotation.y * 100) / 100,
      scale: Math.round(allObjects[i].scale.y * 100) / 100,
    };

    map.push(newObj);
  }

  const savedMapData = {
    mapName: currentMapName,
    tableType: GetCurrentTable().userData.tableType,
    feltType: GetCurrentPlayMat().material.map.name,
    mapThumb: currentMapThumb,
  };

  campaignData.splice(mapIndex, 1, savedMapData);
  maps.splice(mapIndex, 1, map);
};

export const SetCampaignName = (cn) => {
  campaignName = cn;
};

const campaignItem = document.getElementById("campaignItem");
const newCampaignButton = document.getElementById("newCampaignItem");
let currentButtons = [];

export const loadCampaign = () => {
  currentMapName = campaignData[mapIndex].mapName;
  currentMapThumb = campaignData[mapIndex].mapThumb;

  for (let i = 0; i < currentButtons.length; i++) {
    currentButtons[i].remove();
  }
  for (let i = 0; i < maps.length; i++) {
    campaignItem.style.display = "flex";
    const htmlItem = campaignItem.cloneNode(true);
    htmlItem.id = campaignItem.id + i;
    htmlItem.onclick = () => {
      assignMapToButton(i);
    }; // event handlers are not cloned
    campaignItem.parentNode.appendChild(htmlItem);

    htmlItem.textContent = campaignData[i].mapName;
    htmlItem.style.backgroundImage = "url('" + campaignData[i].mapThumb + "')";

    currentButtons.push(htmlItem);
  }

  campaignItem.parentNode.appendChild(newCampaignButton);

  campaignItem.style.display = "none";

  saveMap("Load campaign function in campaign.js");

  if (maps.length == mapLimit) {
    newCampaignButton.style.display = "none";
  }
};

const assignMapToButton = (index) => {
  if (mapIndex != index) {
    let dir = "right";

    if (index < mapIndex) dir = "left";

    mapIndex = index;

    loadPreviousMap(dir);
  }
};
