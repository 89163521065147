let modelCache = [];

export const AddModelToCache = (model) => {
  if (modelCache.includes(model.name)) {
    return;
  }

  modelCache.push(model);
};

export let GetModelFromCache = (modelName) => {
  for (let i = 0; i < modelCache.length; i++) {
    if (modelCache[i].scene.name.includes(modelName)) {
      return modelCache[i];
    }
  }
};
