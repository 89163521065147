import { ref, set } from "firebase/database";
import { getDB } from "./firebaseConfig";
import { setAllowUpdateDatabase, setInAMultiplayerSession } from "./status";
import * as GAMESTATE from "../gamestate";
import { getGameID } from "./roomData";
import { getUserData } from "./userData";
import { showChatMessage } from "./chatbox";
import { updateUserAvatarBar } from "./uiMultiplayer";

export default function leaveRoom() {
  setInAMultiplayerSession(false);
  setAllowUpdateDatabase(false);
  set(ref(getDB(), getGameID() + "/game/users/" + getUserData().userName), {});
  const roomInfo = document.getElementsByClassName("multiplayer-room-info")[0];
  roomInfo.style.display = "none";
  GAMESTATE.ClearGame(true, false);
  showChatMessage("You have left the room!");
  updateUserAvatarBar(null);
}
