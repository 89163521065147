export function GetModel(modelID) {
  let assetPaths = [
    "./models/figurines/bandit_a.glb",
    "./models/figurines/bandit_b.glb",
    "./models/figurines/dragonborndruid.glb",
    "./models/figurines/dwarfbarbarian.glb",
    "./models/figurines/dwarfdruid.glb",
    "./models/figurines/dwarffighter_a.glb",
    "./models/figurines/dwarffighter_b.glb",
    "./models/figurines/eldritchknight.glb",
    "./models/figurines/elfbarbarian.glb",
    "./models/figurines/elfbard.glb",
    "./models/figurines/elfdruid_a.glb",
    "./models/figurines/elfdruid_b.glb",
    "./models/figurines/elfpaladin.glb",
    "./models/figurines/femalefighter.glb",
    "./models/figurines/femalemonk.glb",
    "./models/figurines/femaleranger.glb",
    "./models/figurines/giantbat.glb",
    "./models/figurines/gnollarcher.glb",
    "./models/figurines/gnollskirmisher.glb",
    "./models/figurines/gnomebard.glb",
    "./models/figurines/goblinarcher.glb",
    "./models/figurines/goblinchief.glb",
    "./models/figurines/goblinking.glb",
    "./models/figurines/goblinsoldier.glb",
    "./models/figurines/halflingranger.glb",
    "./models/figurines/hillgiant.glb",
    "./models/figurines/humanfighter.glb",
    "./models/figurines/malebard.glb",
    "./models/figurines/malecleric.glb",
    "./models/figurines/maleranger_a.glb",
    "./models/figurines/maleranger_b.glb",
    "./models/figurines/ogre_a.glb",
    "./models/figurines/ogre_b.glb",
    "./models/figurines/orc_a_fig.glb",
    "./models/figurines/orc_b_fig.glb",
    "./models/figurines/orc_c_fig.glb",
    "./models/figurines/orcbabarian_a.glb",
    "./models/figurines/orcbarbarian_b.glb",
    "./models/figurines/orcfighter.glb",
    "./models/figurines/owlbeer_a.glb",
    "./models/figurines/skeleton_a.glb",
    "./models/figurines/troll_a.glb",
  ];
  for (let i = 0; i < assetPaths.length; i++) {
    if (assetPaths[i].includes(modelID)) {
      return assetPaths[i];
    }
  }
}
