const button_tooltips = document.getElementById("button_tooltips");

let toolTipsActive = true;

export const GetTooltipsActive = () => {
  return toolTipsActive;
};
button_tooltips.onclick = function () {
  const collection = document.getElementsByClassName("tooltipImage");
  const collectionB = document.getElementsByClassName("tooltipImageBottom");
  if (toolTipsActive) {
    toolTipsActive = false;
    document.getElementById("tooltipsIcon").src =
      "./textures/ui/icon_tooltips.webp";

    for (let i = 0; i < collection.length; i++) {
      collection[i].style.visibility = "hidden";
    }
    for (let i = 0; i < collectionB.length; i++) {
      collectionB[i].style.visibility = "hidden";
    }
  } else {
    toolTipsActive = true;
    document.getElementById("tooltipsIcon").src =
      "./textures/ui/icon_tooltips_active.webp";
    for (let i = 0; i < collection.length; i++) {
      collection[i].style.visibility = "visible";
    }

    for (let i = 0; i < collectionB.length; i++) {
      collectionB[i].style.visibility = "visible";
    }
  }
};
