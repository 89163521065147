export function GetRandomSettlement() {
  return (
    TownSize() +
    " \n\n" +
    TownDescription() +
    " " +
    TownDangerLevel() +
    " " +
    TownAlignment() +
    "\n\n" +
    TownRumours()
  );
}

function TownSize() {
  let strings = [
    "a small hamlet.",
    "a large hamlet.",
    "a small village.",
    "a large village.",
    "a small town.",
    "a castle.",
    "a keep.",
    "a large town.",
    "a small city.",
    "a large city.",
    "a metropolis.",
  ];

  return "The settlement is " + strings[GetRandomInt(0, strings.length - 1)];
}

export function TownName() {
  let strings = [
    "Aerilon",
    "Aquarin",
    "Aramoor",
    "Azmar",
    "Begger’s Hole",
    "Black Hollow",
    "Blue Field",
    "Briar Glen",
    "Brickelwhyte",
    "Broken Shield",
    "Boatwright",
    "Bullmar",
    "Carran",
    "City of Fire",
    "Coalfell",
    "Cullfield",
    "Darkwell",
    "Deathfall",
    "Doonatel",
    "Dry Gulch",
    "Easthaven",
    "EcrinErast",
    "Far Water",
    "Firebend",
    "Fool’s March",
    "Frostford",
    "Goldcrest",
    "Goldenleaf",
    "Greenflower",
    "Garen’s Well",
    "Haran",
    "Hillfar",
    "Hogsfeet",
    "Hollyhead",
    "Hull",
    "Hwen",
    "Icemeet",
    "Ironforge",
    "Irragin",
    "Jarren’s Outpost",
    "Jongvale",
    "Kara’s Vale",
    "Knife’s Edge",
    "Lakeshore",
    "Leeside",
    "Lullin",
    "Marren’s Eve",
    "Millstone",
    "Moonbright",
    "Mountmend",
    "Nearon",
    "New Cresthill",
    "Northpass",
    "Nuxvar",
    "Oakheart",
    "Oar’s Rest",
    "Old Ashton",
    "Orrinshire",
    "Ozryn",
    "Pavv",
    "Pella’s Wish",
    "Pinnella Pass",
    "Pran",
    "Quan Ma",
    "Queenstown",
    "Ramshorn",
    "Red Hawk",
    "Rivermouth",
    "Saker Keep",
    "Seameet",
    "Ship’s Haven",
    "Silverkeep",
    "South Warren",
    "Snake’s Canyon",
    "Snowmelt",
    "Squall’s End",
    "Swordbreak",
    "Tarrin",
    "Three Streams",
    "Trudid",
    "Ubbin Falls",
    "Ula’ree",
    "Veritas",
    "Violl’s Garden",
    "Wavemeet",
    "Whiteridge",
    "Willowdale",
    "Windrip",
    "Wintervale",
    "Wellspring",
    "Westwend",
    "Wolfden",
    "Xan’s Bequest",
    "Xynnar",
    "Yarrin",
    "Yellowseed",
    "Zao Ying",
    "Zeffari",
    "Zumka",
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function TownDangerLevel() {
  let strings = [
    "one of a lawful community, a civilised place.",
    "of a dangerous, lawless place where you need to watch your back.",
    "of a fairly safe place, where you should be comfortable if you travel in numbers.",
  ];

  return (
    "The town's reputation is " + strings[GetRandomInt(0, strings.length - 1)]
  );
}

function TownAlignment() {
  let strings = [
    "lawful good",
    "neutral good",
    "chaotic good",
    "lawful neutral",
    "true neutral",
    "chaotic neutral",
    "lawful evil",
    "neutral evil",
    "chaotic evil",
  ];

  return (
    "The factions and politics of the settlement lean towards a " +
    strings[GetRandomInt(0, strings.length - 1)] +
    " alignment."
  );
}

function TownDescription() {
  let strings = [
    "The settlement is fairly barren and lack in fresh water or fertile lands.",
    "The settlement is blighted by famine and the residents here are struggling to feed themselves.",
    "Recently the settlement has been blighted by a series of poisonings. Whether by accident or intent, authorities are not certain.",
    "There is currently a disease running rampant in the streets. People are being taken ill be some unknown force.",
    "There are structures of cultural significance in this settlement which have stood the test of time.",
    "There are trade routes running through this tow leading to ample opportunity for merchants and the like.",
    "The land here is contested by two opposing factions who want to gain control over the settlement for their own ends.",
    "Several factions have been contesting ownership of land in this settlement.",
    "The settlement is in a highly defensible position, with fortifications and natural defenses keeping out invaders.",
    "The terrain here is hard to traverse as recent floods have turned the streets into a muddy bog.",
    "The settlement is said to be blessed by some Divine entity. It is deemed to be a holy place.",
    "The settlement is known for being an unholy place - worshippers are not welcome and a feeling of unease hands in the air.",
  ];

  return strings[GetRandomInt(0, strings.length - 1)];
}

function TownRumours() {
  let strings = [
    "Be cautious, outsiders are not welcome here.",
    "The locals have recently made a dangerous discovery.",
    "A mystic sits at the settlement gates, wailing about bad omens.",
    "A rift has opened up in the earth at the center of town.",
    "An object vital to the town's council has gone missing.",
    "A notirous figure has been captured. They are strung up in the town square.",
    "The townsfolk are attempting to revolt against their leaders!",
    "An exiled criminal has been spotted lurking in the town.",
    "A rival settlement has declared war on this place.",
    "A large fire has burned half the town to the ground.",
    "A prominent figure in the settlement has gone missing",
    "All production has halted in the settlement as workers strike.",
    "There have been a series of mysterious murders recently.",
    "The settlement has a debt to another settelment that is due.",
    "The leadership are corrupt, greedy and in league with villains.",
    "There's been a disastrous accident in the center of town!",
    "There are rumours that the settlement's leaders are in league wit the enemy.",
    "Raiders have attacked regularly, preying on the weak.",
    "A supposedly innocent civilian is about the be executed.",
    "This whole place seems corrupted by dark magic.",
    "This settlement has been isolated by brutal weather as of late.",
    "Supplies are scarce here since the supply lines have been cut off.",
    "There is a sickness running amok in this settlement.",
    "Tehre are rumours that an attack is imminent.",
    "The locals speak of a lost caravan that never returned.",
    "The council calls for an immediate expedition.",
    "The leader has fallen, leaving the council in disarray.",
    "There are several rival families in conflict here.",
    "The leadership has been proven incompetent.",
    "The council are reckless warmongerers.",
    "There is a beast ont he hunt here. Best watch your back.",
    "A truce has been broken and bad blood is being spoken.",
    "There is a vengful spirit haunting the shadows here.",
    "A trade route has been blocked causing supplies to diminish.",
    "An important event is about to take place here.",
    "This place has a dangerous tradition."
  ]
  return strings[GetRandomInt(0, strings.length - 1)];

}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}