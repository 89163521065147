import { ClearGame, GetAllActiveObjects } from "../gamestate";
import * as ASSETLOADER from "../assetLoader";
import * as THREE from "three";
import * as APP from "../../script";
import { DeleteObject } from "../eraser";
import { CreateLight } from "../lights";
import { Createcard } from "../cards";
import { getDB } from "../multiplayer/firebaseConfig";
import { ref, set } from "firebase/database";
import { getGameID } from "./roomData";
import {
  addToMultiplayerSelectors,
  getMultiplayerSelectors,
  removeMultiplayerSelector,
} from "../managers/selectionmanager";

/// this works one model at a time, but not several objects at once, ie joining a game
let modelLoaded = true;
let selectorLoaded = true;

export const UpdateMap = async (data) => {
  const allObjects = GetAllActiveObjects();

  for (let i = 0; i < data.length; i++) {
    let id = data[i].id;
    let name = data[i].name;
    let x = data[i].posX;
    let y = data[i].posY;
    let z = data[i].posZ;
    let rot = data[i].rotY;
    let scale = data[i].scaleY;

    let found = false;

    for (let j = 0; j < allObjects.length; j++) {
      if (allObjects[j].userData.id === id) {
        found = true;
        allObjects[j].position.set(x, y, z);
        allObjects[j].rotation.y = rot;
        allObjects[j].scale.set(scale, scale, scale);
      }
    }

    //if not, load it
    if (!found) {
      if (modelLoaded) {
        modelLoaded = false;

        let modelType = ASSETLOADER.GetModelType(name);
        let position = new THREE.Vector3(x, y, z);

        if (data[i].name.includes("light_entity")) {
          const color = data[i].name.substr(id.length - 17);

          CreateLight(data[i].name.substr(id.length - 17), position, id);
        }
        if (data[i].name.includes("storycard")) {
          const card = await Createcard(
            "",
            data[i].cardTitle,
            data[i].cardBody,
            data[i].cardType,
            data[i].cardImage,
            data[i].cardColor,

            position,
            rot,
            id
          );
        }

        if (data[i].name.includes("selector")) {
        }
        if (
          !data[i].name.includes("light_entity") &&
          !data[i].name.includes("storycard") &&
          !data[i].name.includes("selector")
        ) {
          let model = await ASSETLOADER.LoadModel(
            name,
            modelType,
            position,
            rot,
            scale,
            null,
            true,
            scale,
            id
          );
        }

        modelLoaded = true;
      }
    }
  }

  //THEN check our all objects and see if it is in the data array, if not, remove it

  for (let i = 0; i < allObjects.length; i++) {
    let foundit = false;
    for (let j = 0; j < data.length; j++) {
      if (allObjects[i].userData.id == data[j].id) {
        foundit = true;
      }
    }
    if (!foundit) {
      DeleteObject(allObjects[i]);
    }
  }
};

export const UpdateSelectors = async (data) => {
  const multiplayerSelectors = getMultiplayerSelectors();

  for (let i = 0; i < data.length; i++) {
    let id = data[i].id;
    let x = data[i].posX;
    let y = data[i].posY;
    let z = data[i].posZ;
    let scale = data[i].scaleX;
    let color = data[i].color;
    let found = false;

    if (multiplayerSelectors.length > 0) {
      for (let j = 0; j < multiplayerSelectors.length; j++) {
        if (multiplayerSelectors[j].userData.id === id) {
          found = true;

          if (multiplayerSelectors[j].position) {
            multiplayerSelectors[j].position.set(x, y, z);
            multiplayerSelectors[j].scale.set(scale, scale, scale);
          } else {
            multiplayerSelectors[j].scene.position.set(x, y, z);
            multiplayerSelectors[j].scene.scale.set(scale, scale, scale);
          }
        }
      }
    }
    if (!found) {
      if (selectorLoaded) {
        selectorLoaded = false;
        const newSelector = await ASSETLOADER.LoadSelector(color);
        newSelector.visible = true;
        newSelector.scene.userData.id = id;
        newSelector.userData.id = id;

        addToMultiplayerSelectors(newSelector.scene);
        newSelector.scene.scale.set(scale, scale, scale);
        newSelector.scene.position.set(x, y, z);
        newSelector.scene.traverse(function (child) {
          child.visible = true;
          if (child.isMesh) {
            child.material.name = color;
            child.material.color = new THREE.Color("#" + color);
          }
        });
        selectorLoaded = true;
      }
    }
  }

  for (let i = 0; i < multiplayerSelectors.length; i++) {
    let foundit = false;
    for (let j = 0; j < data.length; j++) {
      if (multiplayerSelectors[i].userData.id == data[j].id) {
        foundit = true;
      }
    }
    if (!foundit) {
      APP.scene.remove(multiplayerSelectors[i]);
      removeMultiplayerSelector(multiplayerSelectors[i]);
    }
  }

  for (let I = 0; I < data.length; I++) {
    let foundit = false;
    for (let J = 0; J < multiplayerSelectors.length; J++) {
      if (multiplayerSelectors[J].userData.id == data[I].id) {
        foundit = true;
      }
    }
    if (!foundit) {
      APP.scene.remove(multiplayerSelectors[I]);
      removeMultiplayerSelector(multiplayerSelectors[I]);
    }
  }

  for (let I = 0; I < data.length; I++) {
    if (I > 6) {
      set(ref(getDB(), getGameID() + "/game/selectors"), null);
    }
  }
};
