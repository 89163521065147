import * as THREE from "three";
import gsap from "gsap";
import { scene, camera } from "../../script";

let loader = new THREE.TextureLoader();
let cloudParticles = [];
const cloudTextures = [
  "./textures/effects/cloud.webp",
  "./textures/effects/cloud-c.webp",
  "./textures/effects/cloud-b.webp",
];
const sizeLimit = 30;
let colours = [
  new THREE.Color(0xe9fbff),
  new THREE.Color(0xe9fbff),
  new THREE.Color(0xe9fbff),
];
export const loadCloud = () => {
  let cloudGeo = new THREE.PlaneBufferGeometry(25, 25);
  let cloudMaterial = new THREE.MeshLambertMaterial({
    alphaTest: 0.001,
    transparent: true,
    color: 0xffffff,
  });

  for (let p = 0; p < 25; p++) {
    let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
    cloud.name = "cloud";
    cloud.position.set(
      GetRandomInt(-20, 20),
      40,
      GetRandomInt(-20, 20)
      // Math.random() * 20,
      // Math.random() * 5,
      // Math.random() * 20
    );

    cloud.rotation.x = Math.random() * 5;
    cloud.rotation.y = Math.random();
    cloud.rotation.z = Math.random() * 360;
    cloud.material.opacity = 0.6;
    scene.add(cloud);
    cloudParticles.push(cloud);
    GoRandomPlace(cloud);
    const s = GetRandomInt(0.5, 2);
    cloud.scale.x = s;
    cloud.scale.y = s;
    cloud.scale.z = s;
    let randMap = cloudTextures[GetRandomInt(0, 0)];

    cloud.material.map = loader.load(randMap);
  }
};
const tick = () => {
  if (cloudParticles.length > 0) {
    cloudParticles.forEach((p) => {
      p.rotation.z -= 0.002;
      p.lookAt(camera.position);
    });
  }

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const GoRandomPlace = (gobject) => {
  let randX = gobject.position.x + GetRandomInt(-5, 5);
  let randY = gobject.position.y; //+ GetRandomInt(-5, 5);
  let randZ = gobject.position.z + GetRandomInt(-5, 5);

  if (gobject.position.x > sizeLimit || gobject.position.x < sizeLimit)
    randX = GetRandomInt(-sizeLimit, sizeLimit);
  if (gobject.position.z > sizeLimit || gobject.position.z < sizeLimit)
    randZ = GetRandomInt(-sizeLimit, sizeLimit);
  const duration = GetRandomInt(sizeLimit, sizeLimit * 2);
  gsap.to(gobject.position, {
    x: randX,
    y: randY,
    z: randZ,
    duration: duration,
    ease: "none",
    onComplete: () => {
      GoRandomPlace(gobject);
    },
  });
};

export const removeClouds = () => {
  for (let i = 0; i < cloudParticles.length; i++) {
    scene.remove(cloudParticles[i]);
  }
};
