export function GetModel(modelID) {
  let assetPaths = [
    "./models/rocks/RockB_1_00.glb",
    "./models/rocks/RockB_1_01.glb",
    "./models/rocks/RockB_1_02.glb",
    "./models/rocks/RockB_1_03.glb",
    "./models/rocks/RockB_1_04.glb",
    "./models/rocks/RockB_1_05.glb",
    "./models/rocks/RockB_1_06.glb",
    "./models/rocks/RockB_1_07.glb",
    "./models/rocks/RockB_1_08.glb",
    "./models/rocks/realistic/rock_1_grey.glb",
    "./models/rocks/realistic/rock_2_grey.glb",
    "./models/rocks/realistic/rock_3_grey.glb",
    "./models/rocks/realistic/rock_4_grey.glb",
    "./models/rocks/realistic/rock_5_grey.glb",
    "./models/rocks/realistic/rock_6_grey.glb",
    "./models/rocks/realistic/rock_1_snow.glb",
    "./models/rocks/realistic/rock_2_snow.glb",
    "./models/rocks/realistic/rock_3_snow.glb",
    "./models/rocks/realistic/rock_4_snow.glb",
    "./models/rocks/realistic/rock_5_snow.glb",
    "./models/rocks/realistic/rock_6_snow.glb",
    "./models/rocks/realistic/vjr_rock_1_grey.glb",
    "./models/rocks/realistic/vjr_rock_2_grey.glb",
    "./models/rocks/realistic/vjr_rock_3_grey.glb",
    "./models/rocks/realistic/vjr_rock_4_grey.glb",
    "./models/rocks/realistic/vjr_rock_5_grey.glb",
    "./models/rocks/realistic/vjr_rock_6_grey.glb",
    "./models/rocks/realistic/vjr_rock_7_grey.glb",
    "./models/rocks/realistic/vjr_rock_1_snow.glb",
    "./models/rocks/realistic/vjr_rock_2_snow.glb",
    "./models/rocks/realistic/vjr_rock_3_snow.glb",
    "./models/rocks/realistic/vjr_rock_4_snow.glb",
    "./models/rocks/realistic/vjr_rock_5_snow.glb",
    "./models/rocks/realistic/vjr_rock_6_snow.glb",
    "./models/rocks/realistic/vjr_rock_7_snow.glb",
  ];

  if (modelID != "random") {
    for (let i = 0; i < assetPaths.length; i++) {
      if (assetPaths[i].includes(modelID)) {
        return assetPaths[i];
      }
    }
  } else {
    return assetPaths[GetRandomInt(0, assetPaths.length - 1)];
  }
}

function GetRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
